import axios, { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { AdminEndpointEnum } from "../../enums/endpoints";
import { ADMIN_API_URL } from "../../utils/env";
import { IContextProviderProps } from "../interface";

interface IAuthContextType {
  tokenId?: string;
  setTokenId: (tokenId?: string) => void;
  authenticate: (response: any) => Promise<void>;
  queryConfig: (tokenId?: string) => AxiosRequestConfig<any>;
  isAuthenticating: boolean;
  redirectURL?: string;
  setRedirectURL: (redirectURL: string) => void;
}

export const AuthContext = React.createContext<IAuthContextType>({
  tokenId: undefined,
  setTokenId: () => undefined,
  authenticate: async () => undefined,
  queryConfig: () => ({
    headers: {},
  }),
  isAuthenticating: true,
  redirectURL: undefined,
  setRedirectURL: () => "",
});

export function AuthContextProvider({ children }: IContextProviderProps) {
  const [tokenId, setTokenId] = useState<string>();
  const [redirectURL, setRedirectURL] = useState<string>();
  const [isAuthenticating, setAuthenticating] = useState<boolean>(true);

  const queryConfig = useCallback(
    (token?: string) => ({
      headers: {
        Authorization: `Bearer ${token ?? tokenId}`,
      },
    } as AxiosRequestConfig),
    [tokenId]
  );

  const authenticate = useCallback(
    async (token: string) => {
      try {
        const oAuthResponse = await axios.get(`${ADMIN_API_URL(AdminEndpointEnum.VALIDATE)}`, queryConfig(token));

        if (oAuthResponse.status === 200) {
          // If oAuth validation returns a status 200, it is validated
          setTokenId(token);
          window.localStorage.setItem("tokenId", token);
        }
      } catch (error) {
        setTokenId(undefined);
        window.localStorage.removeItem("tokenId");
      } finally {
        setAuthenticating(false);
      }
    },
    [queryConfig]
  );

  // Initialized gapi client
  useEffect(() => {
    const existingTokenId = window.localStorage.getItem("tokenId");
    if (existingTokenId) {
      setTokenId(existingTokenId);
      authenticate(existingTokenId);
    } else {
      setAuthenticating(false);
    }
  }, [authenticate]);

  // Check that tokenId is still valid on a fixed interval
  useEffect(() => {
    const validate = setInterval(() => {
      if (tokenId) authenticate(tokenId);
    }, 60000);

    return () => clearInterval(validate);
  }, [authenticate, tokenId]);

  return (
    <AuthContext.Provider
      value={{
        tokenId,
        setTokenId,
        authenticate,
        queryConfig,
        isAuthenticating,
        redirectURL,
        setRedirectURL,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
