import { CSSProperties, useMemo } from "react";
import { BaseModal, IBaseModalProps } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { ModalErrorMessage, ModalWarningMessage } from "../style";
import Tag from "./Tag";
import { ButtonsContainer, StatContainer } from "./style";
import { Spinner } from "../Spinner";

export interface IConfirmationModalStat {
  title: string | number | JSX.Element;
  data: string | number | JSX.Element;
}

function Stat({ title, data }: IConfirmationModalStat) {
  return (
    <StatContainer>
      <span>{title}</span>
      <span>{data}</span>
    </StatContainer>
  );
}

interface IConfirmationModalProps extends IBaseModalProps {
  primaryColor: string;
  minWidth?: string;
  width?: string;
  // Optional error message to be displayed at the bottom
  error?: string;
  warning?: string;
  tag?: {
    texts: string[];
    color: string;
    backgroundColor: string;
  };
  stats?: IConfirmationModalStat[];
  extras?: JSX.Element;
  // icon: string;
  isLoading?: boolean;
  confirmationButton?: {
    title: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
  };
  confirmationButtonExtras?: JSX.Element;
  confirmationWrapperStyle?: CSSProperties;
}

function ConfirmationModal({
  primaryColor,
  confirmationButton,
  confirmationButtonExtras,
  confirmationWrapperStyle,
  minWidth,
  width,
  tag,
  stats,
  extras,
  onHide,
  isLoading,
  error,
  warning,
  ...modalProps
}: IConfirmationModalProps) {
  const confirmationButtons = useMemo(() => {
    if (!confirmationButton) {
      return null;
    }
    return (
      <ButtonsContainer style={confirmationWrapperStyle}>
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => confirmationButton.onClick()}
          disabled={confirmationButton.disabled || isLoading}
        >
          {isLoading ? <Spinner color={primaryColor} /> : confirmationButton.title}
        </Button>
        {confirmationButtonExtras}
      </ButtonsContainer>
    );
  }, [confirmationButton, confirmationButtonExtras, confirmationWrapperStyle, isLoading, primaryColor]);

  return (
    <BaseModal
      {...modalProps}
      style={{
        ...(modalProps.style || {}),
        minWidth: minWidth || "311px",
        width: width || "311px",
      }}
      onHide={onHide}
    >
      {tag && <Tag texts={tag.texts} color={tag.color} backgroundColor={tag.backgroundColor} />}
      {stats && (
        <div>
          {stats.map((stat) => (
            <Stat key={String(stat.title)} title={stat.title} data={stat.data} />
          ))}
        </div>
      )}
      {extras}
      {confirmationButtons}
      {error && <ModalErrorMessage>{error}</ModalErrorMessage>}
      {warning && <ModalWarningMessage>{warning}</ModalWarningMessage>}
    </BaseModal>
  );
}

export default ConfirmationModal;
