import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IMaintenance } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useMaintenance = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.MAINTENANCE);

  const swr = useSWR<IMaintenance, AxiosError>(
    tokenId ? [endpoint, tokenId] : undefined,
    async () => {
      const maintenance = await axios.get<IMaintenance>(
        endpoint,
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        }
      );

      return maintenance.data;
    }
  );

  const toggleMaintenanceMode = useCallback(
    async (current: IMaintenance) => {
      try {
        if (tokenId) {
          await await axios
            .post(
              endpoint,
              {
                maintenance: !current.maintenance,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(current));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: !current.maintenance ? "Maintenance Mode On" : "Maintenance Mode Off",
            subheader: "Maintenance mode toggled",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Maintenance Mode Toggle Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId, endpoint]
  );

  const togglePriorityMode = useCallback(
    async (current: IMaintenance) => {
      try {
        if (tokenId) {
          await await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.PRIORITY_TRANSACTIONS),
              {
                enabled: !current.priority,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(current));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: !current.priority ? "Priority Mode On" : "Priority Mode Off",
            subheader: "Priority mode toggled",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Priority Mode Toggle Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId, endpoint]
  );

  return {
    ...swr,
    toggleMaintenanceMode,
    togglePriorityMode,
  };
};
