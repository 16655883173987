import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAevoOrderHistory } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

export const useOrderHistory = (overrideAccount?: string, stop?: boolean) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const account = overrideAccount || address;

  let endpoint = ADMIN_API_URL(AdminEndpointEnum.ORDER_HISTORY);
  if (stop) {
      endpoint = ADMIN_API_URL(AdminEndpointEnum.STOP_ORDER_HISTORY);
  }

  const swr = useSWR<IAevoOrderHistory, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountDetails = await axios.get<IAevoOrderHistory>(endpoint, {
        ...queryConfig(),
        params: {
          account,
          start_time: 0,
          limit: 25,
          sort: "created_timestamp",
          sort_order: "DESC"
        },
      });

      return accountDetails.data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT],
    }
  );

  return {
    ...swr,
  };
};
