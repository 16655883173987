import styled from "styled-components";
import {
  COLORS,
  TEXT_COLORS,
  BORDER_COLORS,
} from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";

export const ToggleContainer = styled.div<{ isOn?: boolean }>`
  border-radius: 10px;
  background-color: ${({ isOn }) => (isOn ? COLORS.blue.six : "#17171D")};
  border: 1px solid ${({ isOn }) => (isOn ? COLORS.blue.one : "transparent")};
  color: ${({ isOn }) => (isOn ? COLORS.blue.one : TEXT_COLORS.three)};
  height: 40px;
  width: 64px;
  padding: ${SPACING.twoHalf}px;

  transition: all 0.2s ease-in-out;
  overflow: hidden;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const SwitchLine = styled.div<{ isOn?: boolean }>`
  transition: all 0.2s ease-in-out;
  position: absolute;
  transform: ${({ isOn }) => (isOn ? "translateX(0)" : "translateX(100%)")};
  height: 8px;
  width: 100%;
  border-left: 1px solid
    ${({ isOn }) => (isOn ? COLORS.blue.one : BORDER_COLORS.three)};
`;

export const SwitchLabel = styled.span<{ isOn?: boolean }>`
  transition: transform 0.2s ease-in-out;
  position: absolute;
  transform: ${({ isOn }) => (isOn ? "translateX(100%)" : "translateX(0)")};
`;
