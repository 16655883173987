import React, { useState } from "react";
import { IToast } from "../../hooks/toast";
import { IContextProviderProps } from "../interface";

interface IToastContextType {
  toasts: IToast[];
  setToasts: (toasts: IToast[]) => void;
}

export const ToastContext = React.createContext<IToastContextType>({
  toasts: [],
  setToasts: () => {},
});

export function ToastContextProvider({ children }: IContextProviderProps) {
  const [toasts, setToasts] = useState<IToast[]>([]);

  return (
    <ToastContext.Provider value={{ toasts, setToasts }}>
      {children}
    </ToastContext.Provider>
  );
}
