import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { MMTradesEndpointEnum } from "../../../enums/endpoints";
import { MMTRADES_API_URL } from "../../../utils/env";

export interface IFormattedMarkHistory {
  timestamp: string;
  price: string;
}

export const useMarkHistory = (start_time: string, asset?: string) => {
  const instrument_name = `${asset}-PERP`;
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IFormattedMarkHistory[] | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData(undefined); // Reset data to undefined when instrument_name changes
    setIsLoading(true);

    const fetchMarkHistory = async () => {
      const params: { [key: string]: any } = { start_time };
      if (instrument_name) params.instrument_name = instrument_name;

      try {
        const response = await axios.get<any>(
          MMTRADES_API_URL(MMTradesEndpointEnum.MARK_PRICE),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
            params,
          }
        );

        const markHistory = response.data.data.data;
        const formattedRows = markHistory.map((item: any) => ({
          timestamp: String(Number(item.Timestamp) * 1000),
          price: String(item.Price),
        }));

        setData(formattedRows as IFormattedMarkHistory[]);
      } catch (error) {
        // Handle error (optional)
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId && asset) {
      fetchMarkHistory();
    }
  }, [tokenId, start_time, instrument_name, asset]);

  return { data, isLoading };
};
