import { IDeribitPosition } from "../../interfaces/deribit";
import { IAggregatedPosition } from "../../interfaces/positions";
import { retrieveAssetByInstrumentName } from "../strings";

export const standardizeDeribitPositions = (
  positions: IDeribitPosition[]
): IAggregatedPosition[] => {
  const standardizedPositions: Record<string, IAggregatedPosition> = {};
  positions.forEach((pos) => {
    if (pos.instrument_name) {
      standardizedPositions[pos.instrument_name] = {
        instrument_name: pos.instrument_name,
        asset: retrieveAssetByInstrumentName(pos.instrument_name),
        side: Number(pos.size || 0) > 0 ? "buy" : "sell",
        delta: Number(pos.delta || 0),
        gamma: Number(pos.gamma || 0),
        vega: Number(pos.vega || 0),
        theta: Number(pos.theta || 0),
        contracts: Number(pos.size || 0),
        pnl:
          pos.kind === "future" ?
            (Number(pos.average_price) - Number(pos.mark_price)) *
              Math.abs(Number(pos.size)) :
            Number(pos.floating_profit_loss_usd),
        isPerpetual: false,
        source: "Deribit",
      };
    }
  });

  return Object.values(standardizedPositions);
};
