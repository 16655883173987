import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { prettyPrintJson } from "pretty-print-json";
import { AuthContext } from "../../context/AuthContext";
import { TxColumnWrapper, TxQueueWrapper } from "./style";
import { parseTxJSON } from "../../utils/transactions";
import { useTxQueue } from "../../hooks/api/admin/useTxQueue";
import { LinkButton } from "../Account/style";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { Select } from "../shared/Select";
import { useMaintenance } from "../../hooks/api/admin/useMaintenance";
import { useCheckState } from "../../hooks/api/admin/useCheck";
import { useSyncState } from "../../hooks/api/admin/useSync";
import { IKeyValue, KeyValue } from "../Account/stats";
import { KeyValueContainer } from "../Account/stats/style";
import { iterate } from ".";
import { MobileDirectory } from "../shared/MobileDirectory";
import { FormInput } from "../Settings/style";

export function MobileTxViewer() {
  const { tokenId } = useContext(AuthContext);
  const [response, setResponse] = useState<any>(null);
  const [seq, setSeq] = useState<string>("");
  const [isParseLoading, setIsParseLoading] = useState<boolean>(false);
  const [activeTab, setTab] = useState<"Check" | "Sync">("Check");
  const { data, isLoading, isValidating, mutate, clearOnchainQueue, skipTransaction } = useTxQueue();
  const {
    data: mode,
    toggleMaintenanceMode,
    togglePriorityMode,
    isLoading: isMaintenanceLoading,
    isValidating: isMaintenanceValidating,
  } = useMaintenance();
  const {
    data: checkStatus,
    requestCheck,
    mutate: mutateCheck,
    isLoading: isCheckLoading,
    isValidating: isCheckValidating,
  } = useCheckState();
  const {
    data: syncStatus,
    requestSync,
    mutate: mutateSync,
    isLoading: isSyncLoading,
    isValidating: isSyncValidating,
  } = useSyncState();
  const txViewerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      if (!isLoading && !isValidating && data) {
        setIsParseLoading(true);
        try {
          const parsedData = await parseTxJSON(tokenId || "", data);
          const html = prettyPrintJson.toHtml(parsedData, {
            lineNumbers: true,
            indent: 4,
          });
          setResponse(html);
        } catch (e) {
          console.error(e);
        }
        setIsParseLoading(false);
      }
    })();
  }, [tokenId, data, isLoading, isValidating, response]);

  const tabContent = useMemo(() => {
    const status = activeTab === "Check" ? checkStatus : syncStatus;
    const statusMutate = activeTab === "Sync" ? mutateCheck : mutateSync;
    const isStatusLoading =
      activeTab === "Check"
        ? isCheckLoading || isCheckValidating || checkStatus?.progress === "running"
        : isSyncLoading || isSyncValidating || syncStatus?.progress === "running";
    const requestStatus = activeTab === "Check" ? requestCheck : requestSync;

    const stats: IKeyValue[] = [
      {
        title: "Status",
        value: status?.progress.toUpperCase(),
      },
      {
        title: "Last Requested",
        value: moment(Number(status?.last_requested) / 1e6)
          .format("DD MMM YYYY HH:mm:ss")
          .toString(),
      },
      {
        title: "Last Updated",
        value: moment(Number(status?.last_updated) / 1e6)
          .format("DD MMM YYYY HH:mm:ss")
          .toString(),
      },
    ];

    return (
      <>
        <LinkButton onClick={() => requestStatus(status!)} disabled={isStatusLoading}>
          Run
        </LinkButton>
        <LinkButton
          style={{
            paddingLeft: SPACING.three,
          }}
          onClick={() => statusMutate(status)}
          disabled={activeTab === "Check" ? isCheckLoading || isCheckValidating : isSyncLoading || isSyncValidating}
        >
          Refresh
        </LinkButton>
        <KeyValueContainer
          style={{
            marginTop: SPACING.three,
          }}
        >
          {stats.map((stat, i) => (
            <KeyValue key={i} title={stat.title} value={stat.value} />
          ))}
        </KeyValueContainer>
        <KeyValueContainer
          style={{
            paddingBottom: SPACING.three,
            marginTop: SPACING.three,
          }}
        >
          <KeyValue key={0} title={"Report"} />
          {iterate(status?.report!)}
        </KeyValueContainer>
      </>
    );
  }, [
    activeTab,
    syncStatus,
    checkStatus,
    isCheckLoading,
    isCheckValidating,
    isSyncLoading,
    isSyncValidating,
    requestCheck,
    requestSync,
    mutateCheck,
    mutateSync,
  ]);

  return (
    <>
      <div ref={txViewerRef}>
        <MobileDirectory />
      </div>
      <div
        style={{
          height: `calc(100dvh - ${COMPONENTS.header.mobile + (txViewerRef.current?.clientHeight || 0)}px)`,
          display: "grid",
          gridTemplateRows: "1fr 1fr",
        }}
      >
        <TxQueueWrapper isMobile>
          <div>
            <LinkButton
              style={{
                paddingLeft: SPACING.three,
              }}
              onClick={() => mutate(data)}
              disabled={isLoading || isValidating || isParseLoading}
            >
              Refresh
            </LinkButton>
            <LinkButton
              style={{
                paddingLeft: SPACING.three,
              }}
              onClick={() => toggleMaintenanceMode(mode!)}
              disabled={isMaintenanceLoading || isMaintenanceValidating}
            >
              {mode?.maintenance ? "Turn Off Maintenance Mode" : "Turn On Maintenance Mode"}
            </LinkButton>
            <LinkButton
              style={{
                paddingLeft: SPACING.three,
              }}
              onClick={() => togglePriorityMode(mode!)}
              disabled={isMaintenanceLoading || isMaintenanceValidating}
            >
              {mode?.priority ? "Turn Off Priority Mode" : "Turn On Priority Mode"}
            </LinkButton>
            <LinkButton
              style={{
                paddingLeft: SPACING.three,
              }}
              onClick={() => clearOnchainQueue(data!)}
              disabled={isLoading || isValidating || isParseLoading || !mode?.maintenance}
            >
              Clear Events Queue
            </LinkButton>
          </div>
          {response ? (
            <div
              className="json-container"
              style={{
                wordBreak: "break-all",
              }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: response }}
            />
          ) : (
            <h3
              style={{
                padding: SPACING.three,
                paddingBottom: 0,
              }}
            >
              Loading...
            </h3>
          )}
        </TxQueueWrapper>

        <TxColumnWrapper>
          <h3
            style={{
              padding: SPACING.three,
              paddingBottom: 0,
            }}
          >
            Skip Event{" "}
            <LinkButton
              style={{
                paddingLeft: SPACING.three,
              }}
              onClick={() => skipTransaction(data!, seq)}
              disabled={isLoading || isValidating || isParseLoading}
            >
              Submit
            </LinkButton>
          </h3>
          <FormInput
            type="text"
            onChange={(e) => setSeq(e.target.value)}
          />
          <div
            style={{
              padding: SPACING.two,
              paddingLeft: SPACING.three,
            }}
          >
            <Select
              isRound
              options={[
                {
                  label: "Check",
                  isActive: activeTab === "Check",
                  onClick: () => setTab("Check"),
                },
                {
                  label: "Sync",
                  isActive: activeTab === "Sync",
                  onClick: () => setTab("Sync"),
                },
              ]}
            />
          </div>
          <div
            style={{
              padding: SPACING.two,
              paddingLeft: SPACING.three,
            }}
          >
            {activeTab === "Check" ? tabContent : null}
            {activeTab === "Sync" ? tabContent : null}
          </div>
        </TxColumnWrapper>
      </div>
    </>
  );
}
