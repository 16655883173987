import { useCallback, useMemo, useState } from "react";
import currency from "currency.js";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { GlobalFilterWrapper } from "../Table/style";
import {
  DetailsWrapper,
  FilterContainer,
  Label,
  MarginStat,
  MarginWrapper,
} from "./style";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import {
  IAccountLiquidationPlans,
  useAccountLiquidationPlans,
} from "../../hooks/api/admin/useAccountLiquidationPlans";
import { PlanTable } from "../Account/tables/PlanTable";
import { useMarkets } from "../../hooks/api/aevo/useMarkets";

export type BidAsk = {
  price: string;
  amount: string;
  isBid: boolean;
};

export function LiquidationPlans() {
  const { data: marketsData } = useMarkets();
  const { getAccountLiquidationPlans } = useAccountLiquidationPlans();
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<string>();
  const [plans, setPlans] = useState<IAccountLiquidationPlans[]>();
  const onChangeAccount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAccount = event.target.value;
      setAccount(newAccount);
    },
    []
  );

  const sortedPlans = useMemo(() => {
    if (!plans) {
      return [];
    }
    return plans.sort((a, b) => b.created_timestamp - a.created_timestamp);
    // return plans.liquidation_plans.sort(
    //   (a, b) => b.created_timestamp - a.created_timestamp
    // );
  }, [plans]);

  const onGetAccountLiquidationPlans = useCallback(async () => {
    if (!account) {
      return;
    }
    setLoading(true);
    try {
      const response = await getAccountLiquidationPlans(account);
      if (response) {
        setPlans(response);
      }
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }, [account, getAccountLiquidationPlans]);

  return (
    <DetailsWrapper>
      <FilterContainer>
        <GlobalFilterWrapper>
          <input
            value={account}
            onChange={onChangeAccount}
            placeholder="Account"
          />
        </GlobalFilterWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => onGetAccountLiquidationPlans()}
          style={{
            minHeight: `${COMPONENTS.selectHeight}px`,
            maxHeight: `${COMPONENTS.selectHeight}px`,
          }}
          disabled={!account}
        >
          {loading ? <Spinner /> : "View Liquidation Plans"}
        </Button>
      </FilterContainer>
      {sortedPlans && (
        <>
          {sortedPlans.map((plan) => {
            // Convert nanoseconds to milliseconds and create Date object
            const date = new Date(plan.created_timestamp / 1_000_000);

            // Format date like "January 10th at 09:13 AM UTC"
            const formattedDate = date.toLocaleString("en-US", {
              month: "long",
              day: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "UTC",
              // Add these options to prevent number bolding
              numberingSystem: "latn",
            });

            // Clean up the format and add ordinal suffix
            const dateToUse = `${
              formattedDate
                .replace(/,/g, "") // Remove commas
                .replace(/(\d+)(?=\s)/, (d) => {
                  const suffix = ["th", "st", "nd", "rd"];
                  const v = parseInt(d, 10) % 100;
                  return d + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
                })
                .replace(/(\d+:\d+)/, "at $1") // Add "at" before time
            } UTC`;

            return (
              <div key={plan.created_timestamp}>
                <h3>{dateToUse}</h3>
                <Label>MARGIN BREAKDOWN</Label>
                <MarginWrapper>
                  <MarginStat>
                    <div>Margin</div>
                    <div>{currency(plan.margin || "0").format()}</div>{" "}
                  </MarginStat>
                  <MarginStat>
                    <div>Initial Margin</div>
                    <div>{currency(plan.initial_margin || "0").format()}</div>
                  </MarginStat>
                  <MarginStat>
                    <div>Maintenance Margin</div>
                    <div>
                      {currency(plan.maintenance_margin || "0").format()}
                    </div>
                  </MarginStat>
                  <MarginStat>
                    <div>Available Margin</div>
                    <div>
                      {currency(
                        plan.margin
                          ? Number(plan.margin) -
                              Number(plan.maintenance_margin)
                          : "0"
                      ).format()}
                    </div>
                  </MarginStat>
                </MarginWrapper>
                <Label>PLANS</Label>
                <PlanTable plans={plan.plans} marketsData={marketsData} />
              </div>
            );
          })}
        </>
      )}
    </DetailsWrapper>
  );
}
