/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { MMTradesEndpointEnum } from "../../../enums/endpoints";
import { MMTRADES_API_URL } from "../../../utils/env";

export interface IMMTrade {
  _DateTime: string;
  Exchange: "AEVO" | "BINANCE" | "BYBIT";
  InstrumentName: string;
  Side: string;
  Price: string;
  Amount: string;
  IsTaker: number;
  TradeId: string;
  OrderId: string;
  Fees: string;
  _Timestamp: string;
}

interface IMMTradesResponse {
  message: string;
  success: boolean;
  data: IMMTrade[];
}

export interface IFormattedMMTrade {
  date_time: string;
  exchange: "AEVO" | "BINANCE" | "BYBIT";
  instrument_name: string;
  side: string;
  price: string;
  amount: string;
  is_taker: number;
  trade_id: string;
  order_id: string;
  fees: string;
  timestamp: string;
}

export const useMMTrades = (
  start_time: string,
  instrument_name?: string,
  exchange?: "AEVO" | "BINANCE" | "BYBIT",
  showAllTrades?: boolean,
  account?: string
) => {
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IFormattedMMTrade[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset data to undefined when instrument_name changes
    setData(undefined);
    setIsLoading(true);
    // dont query if querying a specific aevo account
    if (account && exchange !== "AEVO") {
      setData([]);
      return;
    }
    const fetchTrades = async () => {
      const params: { [key: string]: any } = { start_time };
      if (instrument_name) params.instrument_name = instrument_name;
      if (exchange) params.exchange = exchange;
      if (showAllTrades) params.all_trades = true;

      try {
        let response: any;

        if (account) {
          params.account = account;
          response = await axios.get<IMMTradesResponse>(
            MMTRADES_API_URL(MMTradesEndpointEnum.TRADES),
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${tokenId}`,
                "Access-Control-Allow-Origin": "*",
              },
              params,
            }
          );
        } else {
          response = await axios.get<IMMTradesResponse>(
            MMTRADES_API_URL(MMTradesEndpointEnum.MM_TRADES),
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${tokenId}`,
                "Access-Control-Allow-Origin": "*",
              },
              params,
            }
          );
        }

        const positions = response.data.data;
        const formattedRows = positions.map((item: any) => ({
          date_time: item._DateTime ?? "",
          exchange: item.Exchange,
          instrument_name: item.InstrumentName,
          side: item.Side,
          price: item.Price,
          amount: item.Amount,
          is_taker: item.IsTaker,
          trade_id: item.TradeId,
          order_id: item.OrderId,
          fees: item.Fees,
          // timestamp for trade_history in nanoseconds
          // timestamp for mm_trade_history in milliseconds
          timestamp: account
            ? (Number(item._Timestamp) / 1e6).toFixed(0)
            : item._Timestamp,
        }));
        setData(formattedRows);
      } catch (error) {
        // Handle error (optional)
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId) {
      fetchTrades();
    }
  }, [tokenId, start_time, instrument_name, exchange, showAllTrades, account]);

  return { data, isLoading };
};
