import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export interface IManualAccount {
  account: string;
  manual_mode: boolean;
  withdraws: any[];
}

export const useManualModeAccounts = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const url = ADMIN_API_URL(AdminEndpointEnum.MANUAL_ACCOUNTS);

  const swr = useSWR<IManualAccount[], AxiosError>(
    tokenId ? [url, tokenId] : null,
    async () => {
      const response = await axios.get<IManualAccount[]>(url, {
        headers: { Authorization: `Bearer ${tokenId}` },
      });

      return response.data;
    }
  );

  const setManualMode = async (account: string, enabled: boolean) => {
    try {
      await axios
        .post(
          ADMIN_API_URL(AdminEndpointEnum.SET_MANUAL_MODE),
          {
            account,
            enabled,
          },
          {
            headers: { Authorization: `Bearer ${tokenId}` },
          }
        )
        .finally(() => swr.mutate());

      handleSuccess(
        account,
        enabled
          ? "Account flagged successfully"
          : "Account unflagged successfully"
      );
    } catch (error) {
      handleError(
        error as AxiosError,
        enabled ? "Flagging failed" : "Unflagging failed"
      );
    }
  };

  const handleSuccess = useCallback(
    (id: string, message: string) => {
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: message,
          subheader: id,
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    },
    [addToast]
  );

  const handleError = useCallback(
    (error: AxiosError, message: string) => {
      // eslint-disable-next-line no-console
      console.error(error);

      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: message,
          subheader: ((error as AxiosError)?.response?.data as any).error,
          status: ToastStatusEnum.ERROR,
        },
        4000
      );
    },
    [addToast]
  );

  return {
    ...swr,
    setManualMode,
  };
};
