import moment from "moment";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import { COLORS } from "../../../constants/design/colors";
import { IAevoOrderHistory, IAevoOrderHistoryRow } from "../../../interfaces/aevo";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, TableHeaderCell, TableLoader } from "../../Table/style";
import CollapseHeaderCount from "../../TableCollapse";

interface IOrderHistoryTableProps {
  data: IAevoOrderHistory | undefined;
  name: string;
  isLoading?: boolean;
}

export function OrderHistoryTable({ data, name, isLoading }: IOrderHistoryTableProps) {
  const memoizedOrderHistory: IAevoOrderHistoryRow[] = useMemo(() => data?.order_history || [], [data]);

  const columns: ITableColumn<IAevoOrderHistoryRow>[] = useMemo(
    () => [
      {
        title: "Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Created On",
        align: "left",
        accessor: "created_timestamp",
        valueExtractor: (value) =>
          moment(value / 1e6)
            .format("DD MMM YYYY HH:mm:ss")
            .toString(),
      },
      {
        title: "Side",
        align: "right",
        accessor: "side",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={
              value
                ? {
                    color: value === "buy" ? COLORS.green.one : COLORS.red.one,
                  }
                : undefined
            }
          >
            {value}
          </Align>
        ),
      },
      {
        title: "TP/SL",
        align: "right",
        accessor: "stop_type",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={
              value
                ? {
                    color: value === "TAKE_PROFIT" ? COLORS.green.one : COLORS.red.one,
                  }
                : undefined
            }
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {value ? (value === "TAKE_PROFIT" ? "Take Profit" : "Stop Loss") : "-"}
          </Align>
        ),
      },
      {
        title: "Trigger",
        align: "right",
        accessor: "trigger",
        valueExtractor: (value) => (value ? `${value}` : "-"),
      },
      {
        title: "Error",
        align: "right",
        accessor: "error",
        valueExtractor: (value) => (value ? `${value}` : "-"),
      },
      {
        title: "Status",
        align: "right",
        accessor: "order_status",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "ID",
        align: "left",
        accessor: "order_id",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedOrderHistory,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <Collapse
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedOrderHistory.length} showBadge title={name} />,
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
