import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import {
  Align,
  TableHeaderCell,
  TableLoader,
  TableWrapper,
} from "../../Table/style";
import { IPosition } from "../../../interfaces/aevo";
import { COLORS } from "../../../constants/design/colors";

interface IDebugPositionTableProps {
  positions: IPosition[];
  isLoading?: boolean;
  isMobile?: boolean;
}

export function DebugPositionTable({
  positions,
  isLoading,
  isMobile,
}: IDebugPositionTableProps) {
  const columns: ITableColumn<IPosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        Cell: ({ row }) => (
          <Align align="right">{Math.abs(Number(row.original.amount))}</Align>
        ),
      },
      {
        title: "Side",
        align: "right",
        accessor: "instrument_id",
        Cell: ({ row }) => (
          <Align align="right">
            <div
              style={{
                color:
                  Number(row.original.amount) > 0
                    ? COLORS.green.one
                    : COLORS.red.one,
              }}
            >
              {Number(row.original.amount) > 0 ? "Long" : "Short"}
            </div>
          </Align>
        ),
      },
      {
        title: "Value",
        align: "right",
        accessor: "value",
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "mark_price",
      },
      {
        title: "Avg Entry Price",
        align: "right",
        accessor: "avg_entry_price",
      },
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "unrealized_pnl",
      },
      {
        title: "Maintenance Margin",
        align: "right",
        accessor: "maintenance_margin",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: positions,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        } as any,
        autoResetSortBy: false,
      },
      useSortBy
    );

  return (
    <TableWrapper>
      <TableLoader isLoading={isLoading} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // eslint-disable-next-line react/jsx-key
                <TableHeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { ...rowProps } = row.getRowProps();
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...rowProps}>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
}
