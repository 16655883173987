/* eslint-disable no-nested-ternary */
import { useContext, useMemo } from "react";
import currency from "currency.js";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { Collapse, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import moment from "moment";
import { ISanitizedConversation } from "../../hooks/api/intercom/useGetConversations";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { initialMarginUtilization, utilizationColor, maintenanceMarginUtilization } from "../../utils/math";
import { Align, TableHeaderCell, MinifiedTableWrapper, TableLoader } from "../Table/style";
import { ITableColumn } from "../Table/TableColumn";
import { DefaultCellForColumn } from "../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../Table/DefaultHeaderForColumn";
import { DataContext } from "../../context/DataContext";
import { LinkText } from "../Dashboard/style";
import CollapseHeaderCount from "../TableCollapse";
import { IIntercomAdmin } from "./admins";
import { SPACING } from "../../constants/design/spacing";
import { Badge, IconWrapper, InnerHTML, StatusCount, TimeAgo } from "./style";
import { getTimeAgo } from "../../utils/date";
import { generateSeedWithAddress } from "../../utils/icon";

interface IConversationTableProps {
  conversations: ISanitizedConversation[];
  isLoading: boolean;
  hasAccountData?: boolean;
  showClosedTickets?: boolean;
}

function ConversationTable({ conversations, hasAccountData, showClosedTickets, isLoading }: IConversationTableProps) {
  const { records } = useContext(DataContext);
  const navigate = useNavigate();

  const accountColumn: ITableColumn<ISanitizedConversation>[] = useMemo(
    () => [
      {
        id: "address",
        title: "Account Address",
        align: "left",
        accessor: "user",
        Cell: ({ value, row }) => (
          <Align align="left">
            <LinkText
              style={{
                margin: 0,
                color: row.original.conversation.open
                  ? records[value?.account!]
                    ? COLORS.blue.one
                    : TEXT_COLORS.one
                  : TEXT_COLORS.four,
              }}
            >
              {value ? (
                <>
                  <div>{records[value?.account!] ? records[value?.account!] : value?.account}</div>
                  <div style={{ color: records[value?.account!] ? COLORS.highlight.two : COLORS.neutral.three }}>
                    {value?.username}
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ color: COLORS.red.three }}>
                    {row.original.conversation.contacts.contacts[0].external_id}
                  </div>
                  <div style={{ color: COLORS.red.one }}>⚠ Invalid address</div>
                </div>
              )}
            </LinkText>
          </Align>
        ),
      },
    ],
    [records]
  );

  const emailColumn: ITableColumn<ISanitizedConversation>[] = useMemo(
    () => [
      {
        id: "address",
        title: "Intercom User",
        align: "left",
        accessor: "conversation",
        Cell: ({ value, row }) => (
          <Align align="left">
            <div
              style={{
                textTransform: "none",
                color: row.original.conversation.open ? TEXT_COLORS.one : TEXT_COLORS.three,
              }}
            >
              <div>{value?.source.author.email}</div>
            </div>
          </Align>
        ),
      },
    ],
    []
  );

  const marginColumn: ITableColumn<ISanitizedConversation>[] = useMemo(
    () => [
      {
        title: "Equity",
        align: "right",
        accessor: "user",
        valueExtractor: (value) => currency(value?.equity).format(),
      },
      {
        title: "IM%",
        align: "right",
        id: "IM%",
        accessor: "user",
        width: 80,
        Cell: ({ value }) => {
          const percentage = initialMarginUtilization(
            Number(value?.equity),
            Number(value?.initial_margin),
            Number(value?.maintenance_margin)
          ).toFixed(1);
          return (
            <Align align="right" style={{ color: utilizationColor(Number(percentage)) }}>
              {percentage}%
            </Align>
          );
        },
        canSort: true,
        sortType: (a, b) => {
          const percentageA = initialMarginUtilization(
            Number(a.original.user?.equity),
            Number(a.original.user?.initial_margin),
            Number(a.original.user?.maintenance_margin)
          );

          const percentageB = initialMarginUtilization(
            Number(b.original.user?.equity),
            Number(b.original.user?.initial_margin),
            Number(b.original.user?.maintenance_margin)
          );

          if (percentageA > percentageB) return 1;
          if (percentageA < percentageB) return -1;
          return 0;
        },
      },
      {
        title: "MM%",
        align: "right",
        id: "MM%",
        accessor: "user",
        width: 80,
        Cell: ({ row }: any) => {
          const percentage = maintenanceMarginUtilization(
            Number(row.original.equity),
            Number(row.original.maintenance_margin)
          ).toFixed(1);
          return (
            <Align align="right" style={{ color: utilizationColor(Number(percentage)) }}>
              {percentage}%
            </Align>
          );
        },
        canSort: true,
        sortType: (a, b) => {
          const percentageA = maintenanceMarginUtilization(
            Number(a.original.user?.equity),
            Number(a.original.user?.maintenance_margin)
          );

          const percentageB = maintenanceMarginUtilization(
            Number(b.original.user?.equity),
            Number(b.original.user?.maintenance_margin)
          );

          if (percentageA > percentageB) return 1;
          if (percentageA < percentageB) return -1;
          return 0;
        },
      },
    ],
    []
  );

  const columns: ITableColumn<ISanitizedConversation>[] = useMemo(
    () => [
      ...(hasAccountData ? accountColumn : emailColumn),

      {
        title: "Status",
        align: "center",
        id: "status",
        width: 50,
        accessor: "conversation",
        Cell: ({ value }) => (
          <Align align="center">
            <Tag color={value.open ? "success" : "error"}>{value.open ? "OPEN" : "CLOSED"}</Tag>
          </Align>
        ),
      },
      ...(hasAccountData ? marginColumn : []),
      {
        title: "Activity",
        align: "left",
        id: "activity",
        accessor: "conversation",
        Cell: ({ value, row }) => (
          <Align align="left">
            {/* eslint-disable-next-line react/no-danger */}
            <InnerHTML
              dangerouslySetInnerHTML={{ __html: value.source.body }}
              style={{
                color: row.original.conversation.open ? TEXT_COLORS.two : TEXT_COLORS.four,
              }}
            />
          </Align>
        ),
      },
      {
        title: "Assignee",
        align: "left",
        id: "assignee",
        accessor: "admin",
        Cell: ({ value }) => (
          <Align align="left" style={{ color: value?.name ? TEXT_COLORS.two : TEXT_COLORS.three }}>
            {value?.id ? (
              <IconWrapper style={{ marginRight: SPACING.two }}>
                <Jazzicon diameter={20} seed={generateSeedWithAddress(String((value as IIntercomAdmin)?.id))} />
              </IconWrapper>
            ) : undefined}
            <span>{value?.name ?? "Unassigned"}</span>
          </Align>
        ),
      },
      {
        title: "Last Updated",
        align: "center",
        id: "last_updated",
        width: 50,
        accessor: "conversation",
        Cell: ({ value }) => (
          <Align align="center">
            <TimeAgo>{getTimeAgo(moment.unix(value.updated_at))}</TimeAgo>
          </Align>
        ),
        canSort: true,
        sortType: (a, b) => {
          if (moment.unix(a.original.conversation.updated_at).isBefore(moment.unix(b.original.conversation.updated_at)))
            return 1;
          if (moment.unix(a.original.conversation.updated_at).isAfter(moment.unix(b.original.conversation.updated_at)))
            return -1;
          return 0;
        },
      },
      {
        title: "Actions",
        align: "center",
        id: "actions",
        accessor: "conversation",
        Cell: ({ value }) => (
          <Align align="center">
            <Link
              onClick={(e) => e.stopPropagation()}
              style={{ color: COLORS.blue.one }}
              to={`https://app.intercom.com/a/inbox/okozz78m/inbox/shared/all/conversation/${value?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </Link>
          </Align>
        ),
      },
    ],
    [accountColumn, emailColumn, hasAccountData, marginColumn]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: conversations,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: (
            <CollapseHeaderCount
              count={conversations.length}
              showBadge
              title={hasAccountData ? "Tickets with Equity" : "Tickets w/o Equity"}
              extra={
                <StatusCount>
                  <span
                    style={{
                      marginLeft: SPACING.five,
                    }}
                  >
                    Open Tickets
                  </span>
                  {conversations.filter((conversation) => conversation.conversation.open).length ? (
                    <Badge isOpen>
                      {conversations.filter((conversation) => conversation.conversation.open).length}
                    </Badge>
                  ) : (
                    <span style={{ marginLeft: SPACING.one }}>(None)</span>
                  )}
                  {showClosedTickets ? (
                    <>
                      <span
                        style={{
                          marginLeft: SPACING.five,
                        }}
                      >
                        Closed Tickets
                      </span>
                      {conversations.filter((conversation) => !conversation.conversation.open).length ? (
                        <Badge>{conversations.filter((conversation) => !conversation.conversation.open).length}</Badge>
                      ) : (
                        <span style={{ marginLeft: SPACING.one }}>(None)</span>
                      )}
                    </>
                  ) : undefined}
                </StatusCount>
              }
            />
          ),
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr
                        {...rowProps}
                        onClick={() =>
                          hasAccountData ? navigate(`/account/${row.original.user?.account}`) : undefined
                        }
                      >
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}

export default ConversationTable;
