import styled, { css } from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS, FONT_STYLE } from "../../constants/design/fontSize";
import { TableWrapper } from "../Table/style";

export const ManualWithdrawalActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > div {
    flex: 1;
    overflow: auto;
    margin-bottom: ${SPACING.three}px;
  }
`;

export const WithdrawalContentWrapper = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100dvw;
          height: calc(100dvh - ${COMPONENTS.header.mobile + SPACING.one * 2}px);
          overflow-y: scroll;

          > * {
            padding: ${SPACING.two}px;
          }
        `
      : css`
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 50px 1fr;
          height: 100vh;
        `}
`;

export const TabWrapper = styled.div<{ isMobile?: boolean }>`
  grid-row: 1 / span 1;
  height: ${COMPONENTS.header.subheader}px;
  background: black;
  z-index: 1;
  display: flex;
  padding-left: ${SPACING.two}px;
  border-bottom: 1px solid ${BORDER_COLORS.one};

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding-top: 0;
          padding-bottom: 0;
        `
      : css`
          border-top: 1px solid ${BORDER_COLORS.one};
        `}

  > * {
    margin: auto 0;
  }
`;

export const ButtonWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;

  > button {
    
    &:not(:last-of-type) {
      margin-right: ${SPACING.two}px;
    }

    ${({ isMobile }) =>
      isMobile
        ? css`
            padding: 0;
            min-height: unset;
            min-width: unset;
            height: 32px;
            width: 32px;

            > svg {
              outline: white !important;
              width: 24px;
              height: 24px;
            }
          `
        : css``}
`;

export const RequestWrapper = styled.div`
  ${FONT_STYLE.label.one};
  line-height: 1.5;
  word-wrap: break-word;

  > * {
    display: contents;
  }
`;

export const QueryText = styled.div<{ isMobile?: boolean }>`
  ${FONT_STYLE.label.one};
  color: ${TEXT_COLORS.two};

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: 0 !important;
          height: fit-content;
        `
      : css`
          padding-bottom: ${SPACING.one}px;

          &::before {
            content: "*";
            color: ${COLORS.purple.one};
            padding-right: ${SPACING.one}px;
            ${FONT_CSS.data.two}
          }
        `}
`;

export const OverviewWrapper = styled.div<{ isMobile?: boolean }>`
  display: grid;

  ${({ isMobile }) =>
    isMobile
      ? css`
      grid-template-columns 1fr;
      `
      : css`
          grid-gap: 0;
          grid-template-columns: 500px calc(100vw - 500px);
        `}

  > * {
    overflow-y: scroll;
  }
`;

export const StatsWrapper = styled.div<{ isMobile?: boolean }>`
  height: calc(100vh - ${COMPONENTS.header.main + COMPONENTS.header.subheader + SPACING.three * 2}px);

  ${({ isMobile }) =>
    isMobile
      ? css`
          border-right: none;
          width: calc(100dvw - ${SPACING.two * 2}px);
        `
      : css`
          padding: ${SPACING.three}px;
          border-right: 1px solid ${BORDER_COLORS.one};
        `}
`;

export const Stat = styled.div`
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.two};
  background: ${BACKGROUND_COLORS.four};
  padding: ${SPACING.two}px;
  height: calc(100% - ${SPACING.two * 2}px);
  max-height: -webkit-fill-available;

  span {
    color: ${TEXT_COLORS.two};
    ${FONT_STYLE.data.three};
  }
`;

export const StatWrapper = styled.div``;

export const AccountHeader = styled.div<{ isMobile?: boolean }>`
  transition: 0.2s all ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: auto 0;
  }

  ${({ isMobile }) =>
    isMobile
      ? undefined
      : css`
          height: ${COMPONENTS.header.subheader}px;
          border-bottom: 1px solid ${BORDER_COLORS.one};
          padding-left: ${SPACING.three}px;
        `}
`;

export const NoteWrapper = styled.div`
  padding: ${SPACING.three}px;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  background: ${LAYER_COLORS.one};
  overflow-y: scroll;
  text-align: justify;
  margin: ${SPACING.three}px;

  > span {
    margin: 0;
    color: ${COLORS.highlight.one};
    ${FONT_CSS.label.two};
  }
`;

export const CollateralWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${SPACING.two}px;

  > div {
    background: ${BACKGROUND_COLORS.four};
    display: flex;
    border: 1px solid ${BORDER_COLORS.two};
    border-radius: 6px;
    justify-content: space-between;
    padding: ${SPACING.two}px;
  }
`;

export const Collateral = styled.div`
  grid-columns: span 1;
  display: flex;
  flex-direction: column;
  ${FONT_CSS.data.three};
`;

export const Divider = styled.div<{ noBg?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${SPACING.two}px;

  > div {
    ${({ noBg }) =>
      noBg
        ? undefined
        : css`
            border: 1px solid ${BORDER_COLORS.two};
            border-radius: 6px;
            background: ${BACKGROUND_COLORS.four};
          `}

    align-items: center;
    display: grid;

    grid-template-rows: repeat(2, 1fr);
  }

  span {
    color: ${TEXT_COLORS.two};
    ${FONT_STYLE.label.one};
  }
`;

export const Label = styled.span`
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.data.four};
  text-transform: uppercase;
  display: block;
  margin: ${SPACING.three}px 0 ${SPACING.one}px 0;
`;

export const PositionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${SPACING.two}px;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;

  > div {
    ${FONT_CSS.label.one};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${SPACING.two}px 0;

    &:not(:last-of-type) {
      border-bottom: 1px dashed ${BORDER_COLORS.three};
      margin: ${SPACING.one}px 0;
    }

    &:first-of-type {
      border-bottom: 1px solid ${BORDER_COLORS.one};
    }
  }
`;

export const OverrideInputWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  width: fit-content;

  > * {
    padding: 0;
    min-height: fit-content !important;
    border-radius: 4px;

    &:not(:first-child) {
      margin-left: ${SPACING.two}px;
    }
  }

  > input {
    height: 16px;
    background: white;
    border: 1px solid ${BORDER_COLORS.one};
    color: ${TEXT_COLORS.one};
    background-color: ${BACKGROUND_COLORS.component};
    padding: ${SPACING.two}px;
    border-radius: 6px;

    ${({ isMobile }) =>
      isMobile
        ? css`
            width: 200px;
          `
        : css`
            width: 350px !important;
          `}

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.three};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const ProfileDivider = styled.div<{ isActive?: boolean; isMobile?: boolean; isNoted?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: ${SPACING.three}px;

  ${({ isActive, isNoted }) =>
    isActive
      ? css`
          border-bottom: 3px solid ${isNoted ? COLORS.red.one : COLORS.highlight.one};
        `
      : css`
          border-bottom: 3px solid ${BORDER_COLORS.one};
        `}

  ${({ isMobile }) =>
    isMobile
      ? css`
          flex-direction: column;
          border-bottom: none !important;
          padding-bottom: 0;
        `
      : undefined}
`;

export const AccountsList = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  ${({ isMobile }) =>
    isMobile
      ? undefined
      : css`
          width: calc(100% - ${SPACING.three * 2}px);
          margin: ${SPACING.three}px;
        `}

  > button {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 1px solid ${BORDER_COLORS.one};
    border-radius: 6px;
    text-align: left;
    width: auto;
    margin: 0;
    overflow: hidden;

    ${({ isMobile }) =>
      isMobile
        ? css`
            margin: ${SPACING.one}px 0;
          `
        : css`
            &:not(:first-of-type) {
              margin-top: ${SPACING.three}px;
            }
          `}

    &:hover {
      transition: 0.2s all ease-in-out;
      background: ${LAYER_COLORS.one};

      > ${ProfileDivider} {
        background: none;
      }
    }

    > * {
      width: -webkit-fill-available;
    }
  }
`;

export const ActionWrapper = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100%;
          display: flex;
          align-items: center;
          gap: ${SPACING.two}px;
          margin-top: ${SPACING.three}px;

          > button {
            flex: 1;
          }
        `
      : css`
          display: grid;
          grid-gap: ${SPACING.two}px;
          grid-template-columns: repeat(2, max-content);
        `}

  > button {
    border-radius: 4px;
    width: max-content;
    height: 36px;
    padding: 0 ${SPACING.two}px;
  }
`;

export const ManualTableWrapper = styled(TableWrapper)`
  table {
    thead > tr > th:last-of-type {
      position: sticky;
      right: 0;
      z-index: 200;
    }

    tbody > tr {
      background-color: ${BACKGROUND_COLORS.componentSolid};

      td:last-of-type {
        position: sticky;
        right: 0;
        z-index: 100;

        &:nth-of-type(odd) {
          background-color: ${BACKGROUND_COLORS.four};
        }
        &:nth-of-type(even) {
          background-color: ${BACKGROUND_COLORS.nine};
        }
      }
    }
  }
`;

export const BalanceInfo = styled.div`
  ${FONT_CSS.label.two};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.two}px;

  > div {
    display: flex;
    align-items: center;
    color: ${TEXT_COLORS.two};

    span {
      margin-right: ${SPACING.one}px;
      display: block;
      border-radius: 50%;
      width: 4px;
      height: 4px;
    }

    &:nth-of-type(1) > span {
      background-color: white;
    }

    &:nth-of-type(2) > span {
      background-color: ${COLORS.orange.one};
    }

    &:nth-of-type(3) > span {
      background-color: ${COLORS.blue.one};
    }
  }
`;
