/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import { IEpochReward, IStrategiesReward } from "../../../hooks/api/admin/useAccountStrategies";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { MinifiedTableWrapper, TableLoader, TableHeaderCell, Align } from "../../Table/style";
import { ITableColumn } from "../../Table/TableColumn";
import CollapseHeaderCount from "../../TableCollapse";
import { COLORS } from "../../../constants/design/colors";

interface IEpochRewardsTableProps {
  rewards?: IStrategiesReward | undefined;
  isLoading?: boolean;
  name: string;
}

export function EpochRewardsTable({ rewards, isLoading, name }: IEpochRewardsTableProps) {
  const memoizedRewards: IEpochReward[] = useMemo(() => {
    const epochRewards = rewards?.epoch_rewards || [];
    return [...epochRewards].reverse();
  }, [rewards]);

  const columns: ITableColumn<IEpochReward>[] = useMemo(() => {
    const uniqueTokens = Array.from(
      new Set(memoizedRewards.flatMap((reward) => reward.epoch_reward.asset_rewards.map((ar) => ar.asset)))
    );

    return [
      {
        title: "Epoch",
        align: "left",
        accessor: "epoch",
        width: "5%",
      },
      ...uniqueTokens.map((token) => ({
        title: token,
        align: "right" as const,
        id: token,
        accessor: (row: IEpochReward) => {
          const assetReward = row.epoch_reward.asset_rewards.find((r) => r.asset === token);
          return assetReward ? assetReward.amount_float : "-";
        },
      })),
    ];
  }, [memoizedRewards]);

  const totalRewards: { [key: string]: number } = useMemo(() => {
    const totals: { [key: string]: number } = {};
    memoizedRewards.forEach((reward) => {
      reward.epoch_reward.asset_rewards.forEach((r) => {
        totals[r.asset] = (totals[r.asset] || 0) + parseFloat(r.amount_float);
      });
    });
    return totals;
  }, [memoizedRewards]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedRewards,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedRewards.length} showBadge title={name} />,
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  <tr
                    style={{
                      color: COLORS.highlight.one,
                      backgroundColor: COLORS.highlight.six,
                    }}
                  >
                    <td>
                      <Align align="left">Total</Align>
                    </td>
                    {Object.values(totalRewards).map((reward) => (
                      <td key={reward}>
                        <Align align="right">{reward === 0 ? "-" : reward}</Align>
                      </td>
                    ))}
                  </tr>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
