import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import currency from "currency.js";
import moment from "moment";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import {
  TableHeaderCell,
  MinifiedTableWrapper,
  Align,
  LeftTitleWrapper,
  RightTitleWrapper,
  TableLoader,
  TitleWrapper,
} from "../../Table/style";
import { ITableColumn } from "../../Table/TableColumn";
import { IDeribitPosition } from "../../../interfaces/deribit";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";

interface IDeribitPositionTableProps {
  positions: IDeribitPosition[];
  selectedAsset: string | undefined;
  isLoading?: boolean;
}

export function DeribitPositionTable({ positions, selectedAsset, isLoading }: IDeribitPositionTableProps) {
  const memoizedPositions = useMemo(() => {
    if (!selectedAsset) return positions || [];

    return (positions || []).filter(
      (pos) => retrieveAssetByInstrumentName(pos?.instrument_name || "") === selectedAsset
    );
  }, [positions, selectedAsset]);

  const columns: ITableColumn<IDeribitPosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Side",
        align: "left",
        accessor: "direction",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "buy" ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {value === "buy" ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        accessor: "size",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
      {
        title: "Average Price",
        align: "right",
        id: "avgPrice",
        Cell: ({ row }: any) => {
          const { average_price, average_price_usd, kind } = row.original;
          const avgPrice = kind === "future" ? average_price : average_price_usd;
          return <Align align="right">{currency(avgPrice).format()}</Align>;
        },
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "mark_price",
        valueExtractor: (value) => `${currency(value).format()}`,
      },
      {
        title: "Delta",
        align: "right",
        accessor: "delta",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Gamma",
        align: "right",
        accessor: "gamma",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Vega",
        align: "right",
        accessor: "vega",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Theta",
        align: "right",
        accessor: "theta",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Unrealized PnL",
        align: "right",
        id: "upnl",
        Cell: ({ row }: any) => {
          const { mark_price, average_price, size, floating_profit_loss_usd, kind } = row.original;

          const pnl =
            kind === "future"
              ? (Number(average_price) - Number(mark_price)) * Math.abs(Number(size))
              : Number(floating_profit_loss_usd);

          return (
            <Align
              align="right"
              style={{
                color:
                  // eslint-disable-next-line no-nested-ternary
                  Number(pnl) > 0 ? COLORS.green.one : Number(pnl) < 0 ? COLORS.red.one : TEXT_COLORS.one,
              }}
            >
              {currency(Number(pnl)).format()}
            </Align>
          );
        },
      },
      {
        title: "Maintenance Margin",
        align: "right",
        accessor: "maintenance_margin",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={{
              color: Number(value) > 0 ? TEXT_COLORS.one : TEXT_COLORS.three,
            }}
          >
            {currency(Number(value)).format()}
          </Align>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedPositions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (memoizedPositions && memoizedPositions.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedPositions.map(
          (v: IDeribitPosition) => [
            v.instrument_name ?? "-",
            v.direction ?? "-",
            v.size ? parseFloat(String(v.size)).toFixed(2) : "-",
            v.average_price ? currency(Number(v.average_price)).format() : "-",
            v.mark_price ? currency(Number(v.mark_price)).format() : "-",
            v.delta ? `${parseFloat(String(v.delta)).toFixed(4)}` : "-",
            v.gamma ? `${parseFloat(String(v.gamma)).toFixed(4)}` : "-",
            v.vega ? `${parseFloat(String(v.vega)).toFixed(4)}` : "-",
            v.theta ? `${parseFloat(String(v.theta)).toFixed(4)}` : "-",
            v.floating_profit_loss ? currency(Number(v.floating_profit_loss)).format() : "-",
            v.maintenance_margin ? currency(Number(v.maintenance_margin)).format() : "-",
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedPositions]);

  return (
    <div>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>Deribit Positions {memoizedPositions.length ? `(${memoizedPositions.length})` : "(None)"}</h3>
        </LeftTitleWrapper>
        <RightTitleWrapper>
          <DownloadCSVButton data={csvData} filename={`Deribit Positions ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
        </RightTitleWrapper>
      </TitleWrapper>

      <MinifiedTableWrapper>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </MinifiedTableWrapper>
    </div>
  );
}
