import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export interface IVolume {
  options_volume: number;
  perp_volume: number;
  pnl: number;
  username: string;
}

export interface IAccountVolume {
  account_statistics: {
    daily: IVolume;
    weekly: IVolume;
    monthly: IVolume;
    all_time: IVolume;
  };
}

export const useAccountVolume = (account: string) => {
  const { tokenId } = useContext(AuthContext);

  const endpoint = `${ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_VOLUME)}`;

  const swr = useSWR<IAccountVolume, AxiosError>(
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountVolume = await axios.get<IAccountVolume>(endpoint, {
        headers: { Authorization: `Bearer ${tokenId}` },
        params: {
          account,
        },
      });

      return accountVolume.data;
    }
  );

  return swr;
};
