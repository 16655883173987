import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { DataContext } from "../../context/DataContext";
import { TradeTypeEnum, useAccount } from "../../hooks/api/admin/useAccount";
import { IAccount } from "../../interfaces/aevo";
import { shortenAddress } from "../../utils/strings";
import ConfirmationModal from "../shared/ConfirmationModal";
import { Spinner } from "../shared/Spinner";
import { AccountStats } from "./stats/AccountStats";
import { MMPStats } from "./stats/MMPStats";
import { MarginStats } from "./stats/MarginStats";
import { AccountContentWrapper, AccountStatWrapper, CloseAllButton, MobileFooterWrapper } from "./style";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import { Select } from "../shared/Select";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { MobilePositions } from "../Positions/MobilePositions";
import { TxHistoryTable } from "./tables/TxHistoryTable";
import { TradeHistoryTable } from "./tables/TradeHistoryTable";
import { OrderTable } from "./tables/OrderTable";
import { quickAddresses } from "../Dashboard";
import { MobileQuickAddressWrapper, QuickAddressTag } from "../Dashboard/style";
import arrow from "../../assets/svg/arrow-up-right.svg";
import { MobileDirectory } from "../shared/MobileDirectory";
import { useAccountOrders } from "../../hooks/api/admin/useAccountOrders";
import { useAccountTransactions } from "../../hooks/api/admin/useAccountTransactions";

export function MobileAccount() {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [tradeTypes, setTradeTypes] = useState<Set<TradeTypeEnum>>(
    new Set<TradeTypeEnum>([TradeTypeEnum.TRADE, TradeTypeEnum.LIQUIDATION])
  );
  const accountFooterRef = useRef<HTMLDivElement>(null);

  const {
    data: accountData,
    isLoading: isAccountLoading,
    isValidating: isAccountValidating,
    mutate,
  } = useAccount(undefined, Array.from(tradeTypes));

  const { data: accountTxsData, isLoading: accountTxsLoading } = useAccountTransactions();

  const {
    data: accountOrderData,
    cancelAllOrders,
    // isLoading: isAccountOrderLoading,
    // isValidating: isAccountOrderValidating,
    // mutate: mutateOrders,
  } = useAccountOrders(undefined);

  useEffect(() => {
    mutate();
  }, [mutate, tradeTypes]);

  const { setAddress, records } = useContext(DataContext);
  const { account } = useParams<{ account: string }>();
  const [activeTab, setTab] = useState<"Overview" | "Positions" | "TxHistory" | "TradeHistory" | "Orders">("Overview");

  useEffect(() => {
    if (account) setAddress(account);
  }, [account, setAddress]);

  const accountLoading = useMemo(
    () => isAccountLoading || (!isAccountLoading && isAccountValidating),
    [isAccountLoading, isAccountValidating]
  );

  const tabs = useMemo(
    () => [
      {
        label: "Overview",
        isActive: activeTab === "Overview",
        onClick: () => setTab("Overview"),
      },
      {
        label: "Positions",
        isActive: activeTab === "Positions",
        onClick: () => setTab("Positions"),
      },
      {
        label: "Transactions",
        isActive: activeTab === "TxHistory",
        onClick: () => setTab("TxHistory"),
      },
      {
        label: "Trades",
        isActive: activeTab === "TradeHistory",
        onClick: () => setTab("TradeHistory"),
      },
      {
        label: "Orders",
        isActive: activeTab === "Orders",
        onClick: () => setTab("Orders"),
      },
    ],
    [activeTab]
  );

  return (
    <>
      <ConfirmationModal
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Close All Orders"}
        extras={
          <div>
            This will close {accountOrderData?.open_orders.length} order(s) made by {shortenAddress(account || "")}
          </div>
        }
        show={show}
        minWidth={"250px"}
        onHide={() => setShow(false)}
        confirmationButton={{
          title: "Confirm",
          onClick: async () => {
            setLoading(true);
            cancelAllOrders({ account } as IAccount).finally(() => {
              setLoading(false);
            });
          },
          disabled: isLoading,
        }}
      />
      <AccountContentWrapper
        isMobile
        style={{
          height: `calc(100dvh - ${COMPONENTS.header.mobile + (accountFooterRef?.current?.clientHeight || 0)}px)`,
        }}
      >
        <MobileDirectory />
        {
          // eslint-disable-next-line no-nested-ternary
          !accountData ? (
            isAccountValidating ? (
              <div>
                <SpinnerContainerWrapper>
                  <Spinner />
                </SpinnerContainerWrapper>
              </div>
            ) : (
              <div>
                <SpinnerContainerWrapper>
                  <div>No account data for {account}</div>
                </SpinnerContainerWrapper>
              </div>
            )
          ) : (
            <>
              {activeTab === "Overview" ? (
                <AccountStatWrapper isMobile>
                  <AccountStats data={accountData} isMobile />
                  <div style={{ marginTop: SPACING.five }}>
                    <MarginStats accountData={accountData} isMobile />
                  </div>
                  <div style={{ marginTop: SPACING.five }}>
                    <MMPStats data={accountData} isMobile />
                  </div>
                </AccountStatWrapper>
              ) : null}
              {activeTab === "Positions" ? <MobilePositions /> : null}
              {activeTab === "TxHistory" ? (
                <TxHistoryTable
                  isMobile
                  data={accountTxsData?.tx_history.transaction_history || []}
                  records={records}
                  isLoading={accountTxsLoading}
                />
              ) : null}
              {activeTab === "TradeHistory" ? (
                <TradeHistoryTable isMobile tradeTypes={tradeTypes} setTradeTypes={setTradeTypes} />
              ) : null}
              {activeTab === "Orders" ? (
                <OrderTable
                  open_orders={accountOrderData?.open_orders || []}
                  isLoading={accountLoading}
                  closeAllButton={
                    <CloseAllButton
                      disabled={(accountOrderData?.open_orders || []).length < 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow(true);
                      }}
                    >
                      Close All Orders
                    </CloseAllButton>
                  }
                />
              ) : null}
            </>
          )
        }
      </AccountContentWrapper>
      <MobileFooterWrapper ref={accountFooterRef}>
        <MobileQuickAddressWrapper style={{ marginBottom: SPACING.two }}>
          {Object.entries(quickAddresses).map(([key, value], i) => (
            <QuickAddressTag
              style={{
                marginLeft: i === 0 ? 0 : undefined,
                marginRight: i + 1 === Object.values(quickAddresses).length ? 0 : undefined,
                borderColor: account === value ? COLORS.blue.one : BORDER_COLORS.one,
                color: account === value ? COLORS.blue.one : TEXT_COLORS.one,
              }}
              to={`/account/${value}`}
              key={String(key)}
            >
              <span>{key}</span>
              {account !== value ? <img src={arrow} alt="arrow" /> : undefined}
            </QuickAddressTag>
          ))}
        </MobileQuickAddressWrapper>
        <Select isRound options={tabs} />
      </MobileFooterWrapper>
    </>
  );
}
