import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "../../hooks/api/admin/useAccount";
import { OrdersWrapper } from "./style";
import { useOrderHistory } from "../../hooks/api/admin/useOrderHistory";
import { OrderHistoryTable } from "../Account/tables/OrderHistoryTable";
import { COLORS } from "../../constants/design/colors";
import { IAccount } from "../../interfaces/aevo";
import { shortenAddress } from "../../utils/strings";
import { CloseAllButton } from "../Account/style";
import { OrderTable } from "../Account/tables/OrderTable";
import { TPSLHistoryTable } from "../Account/tables/TPSLHistoryTable";
import ConfirmationModal from "../shared/ConfirmationModal";
import { useAccountOrders } from "../../hooks/api/admin/useAccountOrders";

export const filteredAssets: string[] = ["USDC", "USDT", "WETH"];

export function Orders() {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { isLoading: isAevoPositionLoading, isValidating: isAevoPositionValidating } = useAccount();
  const { data: orderHistory } = useOrderHistory(undefined, false);
  const { data: stopOrderHistory } = useOrderHistory(undefined, true);
  const { data: accountOrderData, isLoading: isAccountOrderLoading, cancelAllOrders } = useAccountOrders(undefined);
  const { account } = useParams<{ account: string }>();

  const aevoLoading = useMemo(
    () => isAevoPositionLoading || (!isAevoPositionLoading && isAevoPositionValidating),
    [isAevoPositionLoading, isAevoPositionValidating]
  );

  return (
    <>
      <ConfirmationModal
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Close All Orders"}
        extras={
          <div>
            This will close {accountOrderData?.open_orders.length} order(s) made by {shortenAddress(account || "")}
          </div>
        }
        show={show}
        minWidth={"250px"}
        onHide={() => setShow(false)}
        confirmationButton={{
          title: "Confirm",
          onClick: async () => {
            setLoading(true);
            cancelAllOrders({ account } as IAccount).finally(() => {
              setLoading(false);
            });
          },
          disabled: isLoading,
        }}
      />
      <OrdersWrapper>
        <OrderTable
          open_orders={accountOrderData?.open_orders || []}
          isLoading={isAccountOrderLoading}
          closeAllButton={
            <CloseAllButton
              disabled={(accountOrderData?.open_orders || []).length < 1}
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
              }}
            >
              Close All Orders
            </CloseAllButton>
          }
        />
        <OrderHistoryTable data={orderHistory} name={"Order History"} isLoading={aevoLoading} />
        <OrderHistoryTable data={stopOrderHistory} name={"Stop Order History"} isLoading={aevoLoading} />
        <TPSLHistoryTable />
      </OrdersWrapper>
    </>
  );
}
