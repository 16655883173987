import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { ethers } from "ethers";
import { IntercomEndpointEnum } from "../../../enums/endpoints";
import { INTERCOM_API_URL } from "../../../utils/env";
import { IConversation, IConversationData, IConversationResponse } from "./interfaces";
import { AuthContext } from "../../../context/AuthContext";
import { useAccount } from "../admin/useAccount";
import { IAccount } from "../../../interfaces/aevo";
import { IIntercomAdmin, INTERCOM_ADMINS } from "../../../components/Intercom/admins";

export interface ISanitizedConversation {
  user: IAccount | undefined;
  conversation: IConversation;
  admin: IIntercomAdmin | undefined;
}

export const useGetConversations = (per_page: 10 | 20 | 50 | 100 | 150 = 150, open = true) => {
  const { tokenId } = useContext(AuthContext);
  const { getAccounts } = useAccount();

  const swr = useSWR<IConversationData | undefined, AxiosError>(
    tokenId ? [IntercomEndpointEnum.CONVERSATIONS, tokenId, per_page, open] : undefined,
    async () => {
      try {
        const response = await axios.get<IConversationResponse | undefined>(
          INTERCOM_API_URL(IntercomEndpointEnum.CONVERSATIONS),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
            params: {
              per_page,
              open,
            },
          }
        );

        return response.data?.data;
      } catch (error) {
        console.error(error);
      }
    },
    {
      refreshInterval: 60000,
    }
  );

  // Returns conversations without valid Aevo accounts
  const getConversationsWithoutAddresses = useCallback(() => {
    const conversationWithoutAddresses: IConversation[] = [];

    swr.data?.conversations?.forEach((conversation) => {
      conversation.contacts.contacts.forEach((contact) => {
        try {
          if (
            contact.external_id &&
            contact.external_id.length === 42 &&
            ethers.utils.getAddress(contact.external_id)
          ) {
            // Do nothing
          } else {
            conversationWithoutAddresses.push(conversation);
          }
        } catch (error) {
          // console.error(error);
        }
      });
    });

    const sanitizedConversationsWithoutAddresses: ISanitizedConversation[] = conversationWithoutAddresses.map(
      (conversation) => ({
        user: undefined,
        conversation,
        admin: INTERCOM_ADMINS[conversation.admin_assignee_id] ?? undefined,
      })
    );

    return sanitizedConversationsWithoutAddresses;
  }, [swr.data?.conversations]);

  // Returns conversations with valid Aevo accounts
  const getConversationsWithAddresses = useCallback(async () => {
    const conversationsWithAddresses: IConversation[] = [];

    swr.data?.conversations?.forEach((conversation) => {
      conversation.contacts.contacts.forEach((contact) => {
        try {
          if (
            contact.external_id &&
            contact.external_id.length === 42 &&
            ethers.utils.getAddress(contact.external_id)
          ) {
            conversationsWithAddresses.push(conversation);
          }
        } catch (error) {
          //   console.error(error);
        }
      });
    });

    const addresses = conversationsWithAddresses.map((conversation) => conversation.contacts.contacts[0].external_id);
    const accounts = await getAccounts(addresses);

    const sanitizedConversationsWithAddresses = conversationsWithAddresses.map((conversation) => ({
      user: accounts.find((account) => account?.account === conversation.contacts.contacts[0].external_id),
      conversation,
      admin: INTERCOM_ADMINS[conversation.admin_assignee_id] ?? undefined,
    }));

    return sanitizedConversationsWithAddresses;
  }, [getAccounts, swr.data?.conversations]);

  return {
    ...swr,
    getConversationsWithAddresses,
    getConversationsWithoutAddresses,
  };
};
