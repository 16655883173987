// 8-point grid system
export const SPACING = {
  one: 4,
  two: 8,
  twoHalf: 12,
  three: 16,
  four: 24,
  five: 32,
  six: 48,
  seven: 64,
  eight: 72,
  nine: 80,
} as const;

export const BORDER_RADIUS = 12;

// Might be worth including browser height into the mix
export const COMPONENTS = {
  header: {
    main: 80,
    subheader: 50,
    stats: 65,
    mobile: 40,
  },
  tableRow: 52,
  tableHeader: 32,
  tabs: 48,
  selectHeight: 32,
  modalHeaderHeight: 56,
  modalButtonWidth: 42,
  modalButtonHeight: 38,
  thinButton: 16,
} as const;

// Screen widths
export const WIDTHS = {
  S: 640,
  M: 1440,
  L: 1728,
};
