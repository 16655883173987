import styled from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const TagContainer = styled.div<{ color?: string; bgColor?: string }>`
  ${FONT_CSS.label.one}
  width: fit-content;
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor || COLORS.green.five};
  margin-bottom: ${SPACING.three}px;
  display: flex;
  align-items: center;

  span {
    color: ${({ color }) => color};
    text-transform: capitalize;
  }
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZE.oneHalf};

  &:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }

  span:first-child {
    color: ${TEXT_COLORS.three};
  }

  span:last-child {
    color: ${TEXT_COLORS.one};
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: ${SPACING.three}px;
`;

export const EditOrderInfoTag = styled.div<{
  color?: string;
  background?: string;
}>`
  ${FONT_CSS.label.one}
  width: fit-content;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;
  display: flex;
  margin-bottom: ${SPACING.three}px;
  text-transform: capitalize;

  > div {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      &:after {
        display: inline-block;
        content: "";
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ color }) => color};
        margin: 0 ${SPACING.one}px;
      }
    }
  }
`;

export const FooterContainer = styled.div`
  padding: ${SPACING.two}px;
  margin-top: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.two};
  border-radius: 4px;
`;

export const CancelText = styled.div`
  ${FONT_STYLE.body.three};
  color: ${TEXT_COLORS.two};
`;

export const Separator = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 0 ${SPACING.one}px;
  background-color: ${TEXT_COLORS.one};
`;
