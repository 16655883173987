import { AdminEndpointEnum } from "../../enums/endpoints";

const DEFAULT_LABEL = "DEFAULT";

export const labelTransformer = (obj : {[key: string]: string}) => Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (key === "") {
          return [DEFAULT_LABEL, value];
        }
          return [key, value];
      })
    );

export const dataHelper = (data: any, uri : string) => {
    if (uri === AdminEndpointEnum.ACCOUNT_FLOWS) {
      const dataCopy = { ...data };
      dataCopy.inflows.labels = labelTransformer(data.inflows.labels);
      dataCopy.outflows.labels = labelTransformer(data.outflows.labels);
      return dataCopy;
    }
    return data;
};
