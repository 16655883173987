import { ButtonHTMLAttributes, useMemo } from "react";
import { ButtonWithBackground } from "./style";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";

interface IRowActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "close" | "edit";
}

function RowActionButton({ variant, ...props }: IRowActionButtonProps) {
  const icon = useMemo(() => {
    switch (variant) {
      case "close":
        return <Close />;
      case "edit":
        return <Edit />;
      default:
        return null;
    }
  }, [variant]);

  return <ButtonWithBackground {...props}>{icon}</ButtonWithBackground>;
}

export default RowActionButton;
