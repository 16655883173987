import { Data, Layout } from "plotly.js";
import { useCallback, useMemo } from "react";
import Plot from "react-plotly.js";
import { BACKGROUND_COLORS } from "../../constants/design/colors";
import { useMMTradeStrategies } from "../../hooks/api/mm/useMMTradeStrategies";
import { useVertexTrades } from "../../hooks/api/mm/useVertexTrades";
import { usePrelaunchTrades } from "../../hooks/api/mm/usePrelaunchTrades";
import { useMarkHistoryStrategies } from "../../hooks/api/mm/useMarkHistoryStrategies";
import { IS_VALID_ASSET } from "../../utils/asset";
import { ChartContainer } from "./style";
import { IInstrumentChartProps } from "../OptionsTrades/InstrumentChart";
import { IFormattedInstruments } from "../../hooks/api/mm/useOptionsInstruments";

interface ITrade {
  date_time: string;
  exchange: "AEVO" | "BINANCE" | "BYBIT";
  instrument_name: string;
  side: string;
  price: string;
  amount: string;
  is_taker: number;
  trade_id: string;
  order_id: string;
  fees: string;
  timestamp: string;
}

function StrategiesChart({
  instrumentName,
  startTime,
  endTime,
  strategy,
  bybitAssets,
  showAllTrades,
}: IInstrumentChartProps & {
  strategy: string;
  bybitAssets?: IFormattedInstruments[] | undefined;
  showAllTrades: boolean;
}) {
  const legendMarkerSize = 10;
  const minMarkerSize = 3; // Minimum marker size
  const maxMarkerSize = 12; // Maximum marker size
  const instrument_name = `${instrumentName}-PERP`;
  const { data: markHistory } = useMarkHistoryStrategies(String(startTime), String(endTime), instrumentName);

  let primaryExchange = "AEVO";
  let secondaryExchange: string;
  if (strategy === "VERTEX") {
    secondaryExchange = "Aevo";
    primaryExchange = "Vertex";
  } else if (IS_VALID_ASSET(instrumentName, bybitAssets ?? [])) {
    secondaryExchange = "BYBIT";
  } else {
    secondaryExchange = "BINANCE";
  }

  let primaryExchangeTrades: ITrade[] = useMemo(() => [], []);
  let secondaryExchangeTrades: ITrade[] = useMemo(() => [], []);

  function isAllowedExchange(exchange: string): exchange is "AEVO" | "BINANCE" | "BYBIT" | "Vertex" | "Aevo" {
    return ["AEVO", "BINANCE", "BYBIT", "Vertex", "Aevo"].includes(exchange);
  }

  if (isAllowedExchange(secondaryExchange)) {
    const tradesHook = () => {
      switch (strategy) {
        case "PERP MM":
          return useMMTradeStrategies;
        case "PRELAUNCH":
          return usePrelaunchTrades;
        default:
          return useVertexTrades;
      }
    };

    const hook = tradesHook();

    primaryExchangeTrades = hook(
      String(startTime * 1000),
      String(endTime * 1000),
      instrument_name,
      primaryExchange as "AEVO" | "BINANCE" | "BYBIT" | "Vertex" | "Aevo",
    ).data as ITrade[]; // Added type assertion

    if (strategy === "PRELAUNCH") {
      if (!showAllTrades && primaryExchangeTrades?.length > 0) {
        const toDrop: number[] = [];

        primaryExchangeTrades.reduce((acc, current, index) => {
          // Check if the amount for the trade is unique (trade amount)
          const amount = Number(current.amount);
          if (!acc.has(amount)) {
            // Initialise the set if the trade is unique
            acc.set(amount, []);
          }
          // Add the trade is_taker value to the set as a boolean
          acc.get(amount)?.push({ index, isTaker: !!current.is_taker });
          return acc;
        }, new Map<number, { index: number; isTaker: boolean }[]>())
        .forEach((group, amount) => {
          // Check if there are multiple unique isTaker values in the group
          if (new Set(group.map((g) => g.isTaker)).size > 1) {
            // Add the indices of the conflicting trades to the toDrop array
            toDrop.push(...group.map((g) => g.index));
          }
        });
        // Drop the identified rows to remove the self trades
        primaryExchangeTrades = primaryExchangeTrades.filter((trade, index) => !toDrop.includes(index));
      }
    }

    if (strategy !== "PRELAUNCH") {
      secondaryExchangeTrades = hook(
        String(startTime * 1000),
        String(endTime * 1000),
        instrument_name,
        secondaryExchange as "AEVO" | "BINANCE" | "BYBIT" | "Vertex" | "Aevo",
      ).data as ITrade[];
     } // Add type assertion here
  } else {
    throw new Error(`Invalid exchange: ${secondaryExchange}`);
  }

  const normalizeSize = useCallback(
    (amount: number, minAmount: number, maxAmount: number) => ((amount - minAmount) / (maxAmount - minAmount)) *
        (maxMarkerSize - minMarkerSize) +
      minMarkerSize,
    []
  );

  const layout = useMemo(
    () => ({
        title: `${instrumentName}`,
        xaxis: {
          autorange: true,
          type: "date",
        },
        yaxis: {
          autorange: true,
        },
        plot_bgcolor: BACKGROUND_COLORS.five,
        paper_bgcolor: "transparent", // Set overall background color to black
        font: {
          color: "white", // Optional: Change font color for better contrast
        },
      } as Layout),
    [instrumentName]
  );

  const plotData = useMemo(() => {
    if (!primaryExchangeTrades || !secondaryExchangeTrades || !markHistory) {
      return [] as Data[];
    }
    const allTrades = [...primaryExchangeTrades, ...secondaryExchangeTrades];
    const maxAmount = Math.max(
      ...allTrades.map((trade) => parseFloat(trade.amount))
    );
    const minAmount = Math.min(
      ...allTrades.map((trade) => parseFloat(trade.amount))
    );

    const primaryBuys = primaryExchangeTrades.filter((trade) => trade.side === "buy");
    const primarySells = primaryExchangeTrades.filter((trade) => trade.side === "sell");
    const secondaryExchangeBuys = secondaryExchangeTrades.filter(
      (trade) => trade.side === "buy"
    );
    const secondaryExchangeSells = secondaryExchangeTrades.filter(
      (trade) => trade.side === "sell"
    );

    return [
      {
        x: markHistory.map((item) => new Date(parseInt(item.timestamp, 10)).toISOString()),
        y: markHistory.map((item) => parseFloat(item.price)),
        type: "scatter",
        mode: "lines",
        line: {
          color: "white",
          width: 0.5, // Adjust this value to make the line thinner
        },
        name: "Price",
      },
      {
        x: primaryBuys.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: primaryBuys.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "green",
          symbol: "triangle-up",
          line: { width: 0 },
          size: primaryBuys.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: primaryBuys.map(
          (trade) => `Price: ${trade.price}, Volume: ${
            String((Number(trade.amount) * (Number(trade.price) - ((Number(trade.fees) * (trade.side === "buy" ? 1 : -1)) / Number(trade.amount)))).toFixed(2))
            } (USD), Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${primaryExchange} Buys`,
        showlegend: false,
      },
      {
        x: primarySells.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: primarySells.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "red",
          symbol: "triangle-down",
          line: { width: 0 },
          size: primarySells.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: primarySells.map(
          (trade) => `Price: ${trade.price}, Volume: ${
            String((Number(trade.amount) * (Number(trade.price) - ((Number(trade.fees) * (trade.side === "buy" ? 1 : -1)) / Number(trade.amount)))).toFixed(2))
            } (USD), Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${primaryExchange} Sells`,
        showlegend: false,
      },
      {
        x: secondaryExchangeBuys.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: secondaryExchangeBuys.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "blue",
          symbol: "triangle-up",
          line: { width: 0 },
          size: secondaryExchangeBuys.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: secondaryExchangeBuys.map(
          (trade) => `Price: ${trade.price}, Volume: ${
            String((Number(trade.amount) * (Number(trade.price) - ((Number(trade.fees) * (trade.side === "buy" ? 1 : -1)) / Number(trade.amount)))).toFixed(2))
            } (USD), Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${secondaryExchange} Buys`,
        showlegend: false,
      },
      {
        x: secondaryExchangeSells.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: secondaryExchangeSells.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "purple",
          symbol: "triangle-down",
          line: { width: 0 },
          size: secondaryExchangeSells.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: secondaryExchangeSells.map(
          (trade) => `Price: ${trade.price}, Volume: ${
            String((Number(trade.amount) * (Number(trade.price) - ((Number(trade.fees) * (trade.side === "buy" ? 1 : -1)) / Number(trade.amount)))).toFixed(2))
            } (USD), Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${secondaryExchange} Sells`,
        showlegend: false,
      },
      {
        x: [null], // Invisible in the plot
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "green",
          symbol: "triangle-up",
          size: legendMarkerSize,
        },
        name: `${primaryExchange} Buys`,
        showlegend: true,
        hoverinfo: "none", // No hover info for this series
      },
      {
        x: [null],
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "red",
          symbol: "triangle-down",
          size: legendMarkerSize,
        },
        name: `${primaryExchange} Sells`,
        showlegend: true,
        hoverinfo: "none",
      },
      {
        x: [null], // Invisible in the plot
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "blue",
          symbol: "triangle-up",
          size: legendMarkerSize,
        },
        name: `${secondaryExchange} Buys`,
        showlegend: true,
        hoverinfo: "none", // No hover info for this series
      },
      {
        x: [null],
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "purple",
          symbol: "triangle-down",
          size: legendMarkerSize,
        },
        name: `${secondaryExchange} Sells`,
        showlegend: true,
        hoverinfo: "none",
      },
    ] as Data[];
  }, [
    primaryExchangeTrades,
    markHistory,
    normalizeSize,
    secondaryExchange,
    secondaryExchangeTrades,
  ]);

  return (
    <ChartContainer>
      {!primaryExchangeTrades || !secondaryExchangeTrades || !markHistory ? (
        <div>... Loading {instrument_name}</div>
      ) : (
        <Plot
          data={plotData}
          layout={layout}
          useResizeHandler
          style={{ width: "calc(100% - 64px)", height: "calc(100% - 64px)" }}
        />
      )}
    </ChartContainer>
  );
}

export default StrategiesChart;
