import { COLORS, TEXT_COLORS } from "../constants/design/colors";
import { IAggregatedPosition } from "../interfaces/positions";

// initialMargin in percentage. 100 = 100%, 1 = 1%
export const initialMarginUtilization = (
  equity: number,
  initialMargin: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = ((initialMargin + maintenanceMargin) / equity) * 100;
  return utilization;
};

// maintenanceMargin in percentage. 100 = 100%, 1 = 1%
export const maintenanceMarginUtilization = (
  equity: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = (maintenanceMargin / equity) * 100;
  return utilization;
};

export const utilizationColor = (progress: number) => {
  if (progress) {
    if (progress >= 50 && progress < 80) {
      return COLORS.orange.one;
    }

    if (progress >= 80) {
      return COLORS.red.one;
    }

    if (progress > 0) {
      return TEXT_COLORS.one;
    }
  }

  return TEXT_COLORS.three;
};

export const sortByContracts = (positions: IAggregatedPosition[]) => positions.sort((a, b) => {
    // First, compare by isPriority
    if (a.isPriority && !b.isPriority) return -1;
    if (!a.isPriority && b.isPriority) return 1;

    // Then, compare by contracts
    if (a.contracts > 0 && b.contracts <= 0) return -1;
    if (a.contracts < 0 && b.contracts > 0) return 1;
    if (a.contracts === 0 && b.contracts !== 0) return 1;
    if (b.contracts === 0 && a.contracts !== 0) return -1;

    return 0;
  });

export const timestamp24HAgoMs = (Date.now() - 24 * 60 * 60 * 1000).toString();
export const timestamp24HAgoSec = Math.floor(
  (Date.now() - 24 * 60 * 60 * 1000) / 1000
).toString();
export const timestamp24HAgoNs = ((Date.now() - 24 * 60 * 60 * 1000) * 1e6).toString();

export const timestamp1WeekAgoMs = (Date.now() - 7 * 24 * 60 * 60 * 1000).toString();
export const timestamp1WeekAgoSec = Math.floor(
  (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000
).toString();
export const timestamp1WeekAgoNs = ((Date.now() - 7 * 24 * 60 * 60 * 1000) * 1e6).toString();
