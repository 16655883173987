import { useMemo, useState } from "react";
import { useAccount } from "../../hooks/api/admin/useAccount";
import { useBinancePositions } from "../../hooks/api/binance/useBinancePositions";
import { useDeribitPositions } from "../../hooks/api/deribit/useDeribitPositions";
import { IAccount } from "../../interfaces/aevo";
import { IBinancePosition } from "../../interfaces/binance";
import { IDeribitPosition } from "../../interfaces/deribit";
import { AevoPositionTable } from "../Account/tables/AevoPositionTable";
import { AggregatedPositionTable } from "../Account/tables/AggregatedPositionTable";
import { BinancePositionTable } from "../Account/tables/BinancePositionTable";
import { DeribitPositionTable } from "../Account/tables/DeribitPositionTable";

export const filteredAssets: string[] = ["USDC", "USDT", "WETH"];

export function MobilePositions() {
  const [selectedAsset] = useState<string | undefined>(undefined);

  const { data: accountData, isLoading: isAevoPositionLoading, isValidating: isAevoPositionValidating } = useAccount();
  const {
    data: deribitPositionsData = [],
    isLoading: isDeribitPositionLoading,
    isValidating: isDeribitPositionValidating,
  } = useDeribitPositions(selectedAsset);
  const {
    data: binancePositionsData,
    isLoading: isBinancePositionLoading,
    isValidating: isBinancePositionValidating,
  } = useBinancePositions();

  const aevoLoading = useMemo(
    () => isAevoPositionLoading || (!isAevoPositionLoading && isAevoPositionValidating),
    [isAevoPositionLoading, isAevoPositionValidating]
  );

  const binanceLoading = useMemo(
    () => isBinancePositionLoading || (!isBinancePositionLoading && isBinancePositionValidating),
    [isBinancePositionLoading, isBinancePositionValidating]
  );

  const deribitLoading = useMemo(
    () => isDeribitPositionLoading || (!isDeribitPositionLoading && isDeribitPositionValidating),
    [isDeribitPositionLoading, isDeribitPositionValidating]
  );

  return (
    <>
      {accountData?.positions?.length && (deribitPositionsData?.length || binancePositionsData?.length) ? (
        <div>
          <AggregatedPositionTable
            aevoPositions={accountData.positions}
            deribitPositions={deribitPositionsData}
            binancePositions={binancePositionsData}
            selectedAsset={selectedAsset}
            isLoading={aevoLoading || binanceLoading || deribitLoading}
          />
        </div>
      ) : null}

      <AevoPositionTable data={accountData as IAccount} selectedAsset={selectedAsset} isLoading={aevoLoading} />

      {binancePositionsData?.length ? (
        <BinancePositionTable
          data={binancePositionsData as IBinancePosition[]}
          selectedAsset={selectedAsset}
          isLoading={binanceLoading}
        />
      ) : null}

      {deribitPositionsData?.length ? (
        <DeribitPositionTable
          positions={deribitPositionsData as IDeribitPosition[]}
          selectedAsset={selectedAsset}
          isLoading={deribitLoading}
        />
      ) : null}
    </>
  );
}
