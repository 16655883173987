import styled, { css } from "styled-components";
import { BORDER_COLORS } from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_SIZE } from "../../constants/design/fontSize";

export const Page = styled.div`
  height: calc(100vh - ${COMPONENTS.header.main}px);
  display: grid;

  > h1 {
    margin: ${SPACING.three}px ${SPACING.four}px;
  }
`;

export const JSONContainer = styled.div`
  overflow-y: scroll;
  height: fit-content;
`;

export const TxWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;

  h3 {
    margin-top: 0;
    font-size: ${FONT_SIZE.three};
  }
`;

export const TxColumnWrapper = styled.div<{ isMobile?: boolean }>`
  overflow: scroll;
  border-right: 1px solid ${BORDER_COLORS.two};
  min-width: 400px;
  height: fit-content;
  max-height: -webkit-fill-available;

  ${({ isMobile }) => isMobile
      ? css`
          grid-row: 2 / span 1;
        `
      : css``}
`;

export const TxQueueWrapper = styled.div<{ isMobile?: boolean }>`
  overflow: scroll;
  border-right: 1px solid ${BORDER_COLORS.two};
  min-width: 400px;
  height: fit-content;
  max-height: -webkit-fill-available;

  ${({ isMobile }) => isMobile
      ? css`
          grid-row: 1 / span 1;
        `
      : css`
          max-height: calc(100vh - ${COMPONENTS.header.main + COMPONENTS.tableHeader}px);
        `}
`;

export const ReportRow = styled.div`
  margin-left: ${SPACING.three}px;
  font-size: ${FONT_SIZE.two};
`;
