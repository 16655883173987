import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IOrderbookState } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useOrderbookState = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const getOrderbookStateByTradeId = useCallback(
    async (trade_id: string): Promise<IOrderbookState | undefined> => {
      try {
        if (tokenId) {
          const endpoint = ADMIN_API_URL(AdminEndpointEnum.ORDERBOOK_STATE);
          const response = await axios.get(endpoint, {
            params: {
              trade_id
            },
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Check Orderbook State Request Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, tokenId]
  );

  const getOrderbookStateBySequenceNo = useCallback(
    async (seq: string): Promise<IOrderbookState | undefined> => {
      try {
        if (tokenId) {
          const endpoint = ADMIN_API_URL(AdminEndpointEnum.ORDERBOOK_STATE_BY_SEQ);
          const response = await axios.get(endpoint, {
            params: {
              seq
            },
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Check Orderbook State Request Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, tokenId]
  );

  return {
    getOrderbookStateByTradeId,
    getOrderbookStateBySequenceNo,
  };
};
