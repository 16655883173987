import moment from "moment";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import currency from "currency.js";
import { COLORS } from "../../../constants/design/colors";
import { IAevoTradeHistory } from "../../../interfaces/aevo";
import { shortenAddress } from "../../../utils/strings";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import {
  LeftTitleWrapper,
  RightTitleWrapper,
  TableHeaderCell,
  TableLoader,
  TableWrapper,
  TitleWrapper,
} from "../../Table/style";
import DownloadCSVButton from "../../shared/DownloadCSVButton";

interface IFilledTradesTableProps {
  trades: IAevoTradeHistory[];
}

export function FilledTradesTable({ trades }: IFilledTradesTableProps) {
  const memoizedTradeHistory = useMemo(() => trades || [], [trades]);

  const columns: ITableColumn<IAevoTradeHistory>[] = useMemo(
    () => [
      {
        title: "ID",
        align: "left",
        accessor: "trade_id",
        Cell: ({ value }) => <div>{shortenAddress(value)} </div>,
      },
      {
        title: "Created On",
        align: "left",
        accessor: "created_timestamp",
        valueExtractor: (value) =>
          moment(value / 1e6)
            .format("DD MMM YYYY HH:mm:ss")
            .toString(),
      },
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Side",
        align: "left",
        accessor: "side",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "buy" ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {value}
          </div>
        ),
      },
      {
        title: "Price",
        align: "right",
        accessor: "price",
        valueExtractor: (value) => (value ? `$${value}` : ""),
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedTradeHistory,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (memoizedTradeHistory && memoizedTradeHistory.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedTradeHistory.map(
          (h: IAevoTradeHistory) => [
            h.trade_id,
            moment(Number(h.created_timestamp) / 1e6)
              .format("DD MMM YYYY HH:mm:ss")
              .toString(),
            h.instrument_name,
            h.side,
            h.price,
            h.amount,
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedTradeHistory]);

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>
            Filled Trades
            {memoizedTradeHistory.length ? `(${memoizedTradeHistory.length})` : "(None)"}
          </h3>
        </LeftTitleWrapper>
        <RightTitleWrapper>
          <DownloadCSVButton data={csvData} filename={`Filled Trades ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
        </RightTitleWrapper>
      </TitleWrapper>
      <TableWrapper>
        <TableLoader isLoading={false} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
}
