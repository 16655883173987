import { Link } from "react-router-dom";
import { PageEndpointEnum } from "../../enums/endpoints";
import { MobileHeaderRow } from "./style";
import { ReactComponent as Logo } from "../../assets/logo/logo_text_white.svg";

export function MobileHeader() {
  return (
    <MobileHeaderRow>
      <Link to={PageEndpointEnum.DASHBOARD}>
        <Logo height={20} width={60} />
      </Link>
    </MobileHeaderRow>
  );
}
