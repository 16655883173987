import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export interface IAccountStats {
  balance: string;
  pnl: string;
  realized_pnl: string;
  instrument_realized_pnl: {
    [key: string]: string;
  };
}

export const useAccountStats = (account: string) => {
  const { tokenId } = useContext(AuthContext);

  const endpoint = `${ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_STATS)}`;

  const swr = useSWR<IAccountStats, AxiosError>(
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountStats = await axios.get<IAccountStats>(endpoint, {
        headers: { Authorization: `Bearer ${tokenId}` },
        params: {
          account,
        },
      });

      return accountStats.data;
    }
  );

  return swr;
};
