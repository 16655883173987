import styled from "styled-components";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const TooltipWrapper = styled.div`
  background: black;
  color: ${TEXT_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 4px;
  text-transform: capitalize;
  padding: ${SPACING.two}px;
  ${FONT_STYLE.data.three}
`;
