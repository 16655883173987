import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";
import { DataContext } from "../../../context/DataContext";
import { ITPSLHistory } from "../../../interfaces/aevo";

interface ITPSLResponse {
  order_history: ITPSLHistory[];
  count: string;
}

export type InstrumentType = "NONE" | "OPTION" | "PERPETUAL";

export const useTPSLHistory = (limit: number = 20) => {
  const { queryConfig, tokenId } = useContext(AuthContext);
  const { address: account } = useContext(DataContext);

  const swr = useSWRImmutable<ITPSLHistory[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    account && tokenId ? [AdminEndpointEnum.ACCOUNT_TPSL_HISTORY, account, tokenId, limit] : undefined,
    async () => {
      const positions = (
        await axios.get<ITPSLResponse>(ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_TPSL_HISTORY), {
          ...queryConfig(),
          params: {
            account,
            limit,
            sort_order: "DESC",
          },
        })
      ).data.order_history;

      return positions;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT_TPSL_HISTORY],
    }
  );

  return {
    ...swr,
  };
};
