import { useCallback, useMemo, useState } from "react";
import { IOrderbookState } from "../../interfaces/aevo";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { GlobalFilterWrapper } from "../Table/style";
import { BidsAsksWrapper, DetailsWrapper, FilterContainer } from "./style";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import { useOrderbookState } from "../../hooks/api/admin/useOrderbookState";
import { BidsAsksTable } from "../Account/tables/BidsAsksTable";

export type BidAsk = {
  price: string;
  amount: string;
  isBid: boolean;
};

export function Sequence() {
  const { getOrderbookStateBySequenceNo } = useOrderbookState();
  const [loading, setLoading] = useState<boolean>(false);
  const [seqNo, setSeqNo] = useState<string>();

  const [orderbookResult, setOrderbookResult] = useState<IOrderbookState>();

  const onGetOrderbookState = useCallback(
    async (tradeIdVal?: string) => {
      if (!tradeIdVal) {
        return;
      }
      setOrderbookResult(undefined);
      setLoading(true);
      try {
        const response = await getOrderbookStateBySequenceNo(tradeIdVal);
        if (response) {
          setOrderbookResult(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [getOrderbookStateBySequenceNo]
  );

  const combinedOrderbook: BidAsk[] = useMemo(() => {
    if (!orderbookResult) {
      return [];
    }

    const mappedAsks = orderbookResult.asks
      .map((ask) => ({
        price: ask[0],
        amount: ask[1],
        isBid: false,
      }))
      .reverse() as BidAsk[];

    const mappedBids = orderbookResult.bids.map((bid) => ({
      price: bid[0],
      amount: bid[1],
      isBid: true,
    })) as BidAsk[];

    return [...mappedAsks, ...mappedBids];
  }, [orderbookResult]);

  return (
    <DetailsWrapper>
      <FilterContainer>
        <GlobalFilterWrapper>
          <input
            value={seqNo}
            onChange={(e) => setSeqNo(e.target.value)}
            placeholder="Seq. No."
          />
        </GlobalFilterWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => onGetOrderbookState(seqNo)}
          style={{
            minHeight: `${COMPONENTS.selectHeight}px`,
            maxHeight: `${COMPONENTS.selectHeight}px`,
          }}
          disabled={!seqNo}
        >
          {loading ? <Spinner /> : "View Orderbook"}
        </Button>
      </FilterContainer>
      {orderbookResult && (
        <>
          <h3>{orderbookResult.instrument_name}</h3>
          <BidsAsksWrapper>
            <BidsAsksTable bidsAsks={combinedOrderbook} />
          </BidsAsksWrapper>
        </>
      )}
    </DetailsWrapper>
  );
}
