/* eslint-disable no-param-reassign */
import { createContext, useCallback, useEffect, useState } from "react";
import { IContextProviderProps } from "../interface";
import { IAccount } from "../../interfaces/aevo";
import { useAccounts } from "../../hooks/api/admin/useAccounts";
import { IAirtableRecord, useAirtableTags } from "../../hooks/api/admin/useAirtableTags";

interface IDataContextType {
  address: string;
  setAddress: (address: string) => void;

  accounts: IAccount[];
  setAccounts: (accounts: IAccount[]) => void;

  records: IAirtableRecord;
  setRecords: (records: IAirtableRecord) => void;

  totalAccounts: number;

  pagination: number;
  setPagination: (pagination: number) => void;

  size: number;
  setSize: (size: number) => void;

  isLoading: boolean;
  clearFilter: () => void;
}

const MAX_SIZE_PER_PAGE = Number(process.env.REACT_APP_MAX_SIZE_PER_PAGE) || 2500;

export const DataContext = createContext<IDataContextType>({
  address: "",
  setAddress: () => {},

  accounts: [],
  setAccounts: () => {},

  records: {},
  setRecords: () => {},

  totalAccounts: 0,

  pagination: 1,
  setPagination: () => {},

  size: MAX_SIZE_PER_PAGE,
  setSize: () => {},

  isLoading: false,

  clearFilter: () => {},
});

export function DataContextProvider({ children }: IContextProviderProps) {
  const [address, setAddress] = useState<string>("");
  const [pagination, setPagination] = useState<number>(1);
  const [size, setSize] = useState<number>(MAX_SIZE_PER_PAGE);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [records, setRecords] = useState<IAirtableRecord>({});
  const { data: accountsData, isValidating } = useAccounts(pagination, size);
  const { data: airtableRecordsData } = useAirtableTags();

  // Cache the paginated accounts to prevent re-queries
  useEffect(() => {
    setAccounts(accountsData?.data || []);
  }, [accountsData?.data]);

  const clearFilter = useCallback(() => {
    setAccounts(accountsData?.data || []);
  }, [accountsData?.data]);

  useEffect(() => {
    setRecords(airtableRecordsData?.records || {});
  }, [airtableRecordsData]);

  return (
    <DataContext.Provider
      value={{
        address,
        setAddress,
        accounts,
        setAccounts,
        records,
        setRecords,
        totalAccounts: accountsData?.total_accounts || 0,
        pagination: accountsData?.pagination || pagination,
        setPagination,
        size: accountsData?.size || size,
        setSize,
        isLoading: !accountsData && isValidating,
        clearFilter,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
