import { useCallback, useState } from "react";
import { OptionsDropdown } from "../OptionsDropdown/OptionsDropdown";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { FilterWrapper, TradesWrapper, ChartWrapper } from "./style";
import InstrumentChart from "./InstrumentChart";
import { useOptionsInstruments } from "../../hooks/api/mm/useOptionsInstruments";
import DatetimePicker from "../OptionsDropdown/DatetimePicker";

export function OptionsTrades() {
  const [asset, setAsset] = useState<string>();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [fetchKey, setFetchKey] = useState<number>(0);
  const handleFetch = useCallback(() => {
    if (dateRange[0] && dateRange[1] && dateRange[1].getTime() > dateRange[0].getTime()) {
      setFetchKey((prevKey) => prevKey + 1);
    } else {
      alert("End time must be greater than start time.");
    }
  }, [dateRange]);

  const handleDateChange = (startTimestamp: number, endTimestamp: number) => {
    const startDate = new Date(startTimestamp * 1000);
    const endDate = new Date(endTimestamp * 1000);
    setDateRange([startDate, endDate]);
  };

  const { data: fetchedData } = useOptionsInstruments(
    asset ?? "",
    dateRange[0] ? String(Math.floor(dateRange[0].getTime() / 1000)) : "0",
    dateRange[1] ? String(Math.floor(dateRange[1].getTime() / 1000)) : String(Math.floor(Date.now() / 1000))
  );

  const data = asset && dateRange[0] && dateRange[1] ? fetchedData ?? [] : [];

  const filteredData = data?.filter((item) => item.InstrumentName.slice(0, 3) === asset?.slice(0, 3));
  const uniqueInstrumentNames = Array.from(new Set(filteredData?.map((item) => item.InstrumentName)));

  return (
    <TradesWrapper>
      <FilterWrapper>
        <DatetimePicker onDateChange={(startTimestamp, endTimestamp) => handleDateChange(startTimestamp, endTimestamp)} overridePlaceholder="Select Date Range" />
        <OptionsDropdown onClick={setAsset} overridePlaceholder="Select Asset" />
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          disabled={!asset}
          onClick={handleFetch}
          style={{ minHeight: 24, maxHeight: 24 }}
        >
          {"Fetch"}
        </Button>
      </FilterWrapper>

      {asset && fetchKey > 0 && uniqueInstrumentNames.map((instrumentName) => (
        <ChartWrapper key={`${fetchKey}-${asset}-${instrumentName}`}>
          <InstrumentChart
            key={`${fetchKey}-${asset}-${instrumentName}`}
            instrumentName={instrumentName}
            startTime={dateRange[0] ? dateRange[0].getTime() / 1000 : 0}
            endTime={dateRange[1] ? dateRange[1].getTime() / 1000 : 0}
          />
        </ChartWrapper>
      ))}
    </TradesWrapper>
  );
}
