import { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import { SPACING } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { ToastEnum, ToastStatusEnum, useToast } from "../../hooks/toast";
import { useJSON } from "../../hooks/api/admin/useJSON";
import { Page, QueryWrapper, ActionWrapper, JSONContainer } from "./style";

export function JSONViewer() {
  const url = useParams()["*"] || "";
  const { search: queryParams } = useLocation();
  const { addToast } = useToast();
  const { data: jsonData, mutate, isValidating, isLoading } = useJSON(url, queryParams);

  const onCopyJSON = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
    addToast(
      {
        header: "JSON Copied",
        type: ToastEnum.SIMPLE,
        status: ToastStatusEnum.SUCCESS,
      },
      5000
    );
  }, [addToast, jsonData]);

  return (
    <Page>
      <QueryWrapper>
        <div>
          <span>GET</span> <span>/{url}</span>
          <span>{queryParams}</span>
        </div>
      </QueryWrapper>

      <ActionWrapper>
        <Button buttonTheme={ButtonThemeEnum.HIGHLIGHT} type="button" onClick={onCopyJSON}>
          Copy JSON
        </Button>
        <Button buttonTheme={ButtonThemeEnum.SYSTEM} type="button" onClick={() => mutate()}>
          {!isLoading && isValidating ? <Spinner theme="orange" /> : "Refresh"}
        </Button>
      </ActionWrapper>

      <JSONContainer>
        {!isLoading ? (
          <ReactJson
            style={{ padding: SPACING.three }}
            src={jsonData}
            theme="summerfruit"
            collapsed={false}
            displayDataTypes={false}
            groupArraysAfterLength={200}
            quotesOnKeys={false}
            iconStyle="square"
          />
        ) : (
          <Spinner />
        )}
      </JSONContainer>
    </Page>
  );
}
