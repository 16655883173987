import { motion } from "framer-motion";
import styled from "styled-components";
import {
  COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { SPACING, COMPONENTS } from "../../constants/design/spacing";

export const ToastListWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: ${SPACING.three + COMPONENTS.header.main}px;
  right: ${SPACING.three}px;
  max-height: calc(100vh - ${COMPONENTS.header.main}px - ${SPACING.three}px);
  overflow-y: auto;
`;

export const ToastWrapper = styled(motion.div)`
  background-color: rgba(21, 21, 27, 0.96);
  border: 1px solid ${COLORS.white.two};
  margin-bottom: ${SPACING.three}px;
  min-width: 250px;
  width: fit-content;
  border-radius: 6px;
  margin-left: auto;
`;

export const ToastHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${COMPONENTS.tabs}px;

  > * {
    margin: auto 0;
    display: flex;
  }

  p {
    margin: auto;
  }

  img {
    margin-left: ${SPACING.twoHalf}px;
    margin-right: ${SPACING.two}px;
  }

  > div {
    &:first-child {
      margin: auto 0;
    }
  }
`;

export const ToastInfoWrapper = styled.div`
  padding-top: ${SPACING.three}px;
  border-top: 1px solid ${BORDER_COLORS.one};
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 0;

    &:not(:last-child) {
      margin-bottom: ${SPACING.two}px;
    }
  }
`;

export const CloseButton = styled.button`
  height: 100%;
  aspect-ratio: 1;
  border-left: 1px solid ${COLORS.white.two};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatLabel = styled.span`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.one};
`;

export const StatValue = styled.span``;

export const Header = styled.div`
  display: block;
  margin: auto 0;
  font-size: ${FONT_SIZE.two};
  padding-right: ${SPACING.three}px;
`;

export const SubheaderWrapper = styled.div`
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.two};
`;
