/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { MMTradesEndpointEnum } from "../../../enums/endpoints";
import { MMTRADES_API_URL } from "../../../utils/env";

export interface IMMOptionsTrade {
  Exchange: string;
  InstrumentName: string;
  Price: string;
  Amount: string;
  Side: string;
  isLiquidation: string;
  _CreatedTimestamp: String;
}

interface IMMOptionsTradesResponse {
  message: string;
  success: boolean;
  data: IMMOptionsTrade[];
}

export interface IFormattedMMOptionsTrade {
  Exchange: string;
  InstrumentName: string;
  Price: string;
  Amount: string;
  Side: string;
  isLiquidation: string;
  CreatedTimestamp: string;
}

export const useOptionsTrades = (
  start_time: string,
  end_time: string,
  instrument_name?: string,
  exchange?: "AEVO" | "DERIBIT",
  showAllTrades?: boolean,
  account?: string
) => {
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IFormattedMMOptionsTrade[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset data to undefined when instrument_name changes
    setData(undefined);
    setIsLoading(true);
    // dont query if querying a specific aevo account

    const fetchTrades = async () => {
      const params: { [key: string]: any } = { start_time };
      if (instrument_name) params.instrument_name = instrument_name;
      if (exchange) params.exchange = exchange;
      if (showAllTrades) params.all_trades = true;
      if (end_time) params.end_time = end_time;

      try {
        const response = await axios.get<IMMOptionsTradesResponse>(
          MMTRADES_API_URL(MMTradesEndpointEnum.OPTIONS_TRADES),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
            params,
          }
        );
        const positions = response.data.data;

        const formattedRows = positions.map((item: any) => ({
          Exchange: item.Exchange,
          InstrumentName: item.InstrumentName,
          Side: item.Side,
          Price: item.Price,
          Amount: item.Amount,
          isLiquidation: item.isLiquidation,
          CreatedTimestamp: item._CreatedTimestamp
        }));
        setData(formattedRows);
      } catch (error) {
        // Handle error (optional)
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId) {
      fetchTrades();
    }
  }, [tokenId, start_time, instrument_name, exchange, showAllTrades, account]);

  return { data, isLoading };
};
