const BINANCE_CREDENTIALS: string[] = [
  "0x7B19b83dC868CDD41569A924550efE92B27cBF2e",
  "0x6cDc77Af264be2349F98F5d2cb58A453757CAAE7",
];

const DERIBIT_CREDENTIALS: string[] = [
  "0xE36295FCD5eF04394F13412491142D22888EFD17",
  "0x6cDc77Af264be2349F98F5d2cb58A453757CAAE7",
];

export const hasCredentials = (
  account: string,
  type: "deribit" | "binance"
) => {
  if (type === "deribit") {
    return Boolean(DERIBIT_CREDENTIALS.includes(account));
  }

  return Boolean(BINANCE_CREDENTIALS.includes(account));
};
