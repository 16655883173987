import { TagContainer, TagContent, Separator } from "./style";

interface ITagProps {
  texts: string[];
  color: string;
  backgroundColor: string;
}

// Separator
function Tag({ texts, color, backgroundColor }: ITagProps) {
  return (
    <TagContainer color={color} bgColor={backgroundColor}>
      {texts.map((text, index) => (
        <TagContent key={text}>
          <span>{text}</span>
          {index !== texts.length - 1 && (
            <Separator style={{ backgroundColor: color }} />
          )}
        </TagContent>
      ))}
    </TagContainer>
  );
}

export default Tag;
