import { useCallback, useEffect, useMemo, useState } from "react";
import Jazzicon from "react-jazzicon";
import Flex from "antd/es/flex";
import { ISanitizedConversation, useGetConversations } from "../../hooks/api/intercom/useGetConversations";
import { CheckboxWrapper, ClosedCheckbox, IconWrapper, IntercomWrapper } from "./style";
import ConversationTable from "./ConversationTable";
import { FilterWrapper } from "../Table/style";
import { Select } from "../shared/Select";
import { SPACING } from "../../constants/design/spacing";
import Checkbox from "../shared/Checkbox";
import { INTERCOM_ADMINS } from "./admins";
import { LinkButton } from "../Account/style";
import { AddressTag } from "../Book/style";
import { generateSeedWithAddress } from "../../utils/icon";

const INTERCOM_ADMINS_ARR = Object.entries(INTERCOM_ADMINS).map(([, { id }]) => String(id));

export function Intercom() {
  const [limit, setLimit] = useState<10 | 20 | 50 | 100 | 150>(150);

  const [convosWithEquity, setConvosWithEquity] = useState<ISanitizedConversation[]>([]);
  const [convosWithoutEquity, setConvosWithoutEquity] = useState<ISanitizedConversation[]>([]);

  const [isFiltering, setIsFiltering] = useState(false);
  const [showClosedTickets, setShowClosedTickets] = useState(false);
  const [selectedAddresses, setAddresses] = useState<Set<string>>(new Set(INTERCOM_ADMINS_ARR));

  const { getConversationsWithAddresses, getConversationsWithoutAddresses, isLoading, isValidating } =
    useGetConversations(limit, !showClosedTickets);

  const onSelectAddress = useCallback(
    (key: string) => {
      const updatedAddresses = selectedAddresses;
      if (selectedAddresses.has(key)) {
        updatedAddresses.delete(key);
      } else {
        updatedAddresses.add(key);
      }
      setAddresses(new Set(updatedAddresses));
    },
    [selectedAddresses]
  );
  const isAllSelected = useMemo(
    () => selectedAddresses.size === Object.entries(INTERCOM_ADMINS).length,
    [selectedAddresses]
  );

  const onSelectAll = useCallback(() => {
    if (isAllSelected) {
      setAddresses(new Set());
    } else {
      setAddresses(new Set(INTERCOM_ADMINS_ARR));
    }
  }, [isAllSelected]);

  useEffect(() => {
    const fetchConvosWithEquity = async () => {
      try {
        setIsFiltering(true);
        const response: ISanitizedConversation[] = await getConversationsWithAddresses();

        // Sort conversations by user equity, then sort by status
        let filteredConvos = response
          .sort((a, b) => {
            if (a.user?.equity && b.user?.equity) {
              return Number(b.user.equity) - Number(a.user.equity);
            }
            return 0;
          })
          .sort((a, b) => {
            if (a.conversation.open && !b.conversation.open) return -1;
            if (!a.conversation.open && b.conversation.open) return 1;
            return 0;
          })
          .filter((conversation) => (showClosedTickets ? true : conversation.conversation.open));

        if (selectedAddresses.size > 0) {
          filteredConvos = filteredConvos
            .filter((conversation) => selectedAddresses.has(String(conversation.admin?.id)))
            .filter((conversation) => (showClosedTickets ? true : conversation.conversation.open));
        } else {
          filteredConvos = [];
        }

        setConvosWithEquity(filteredConvos);
      } catch (error) {
        console.error(error);
      }
    };

    fetchConvosWithEquity();
    setIsFiltering(false);
  }, [getConversationsWithAddresses, getConversationsWithoutAddresses, selectedAddresses, showClosedTickets]);

  useEffect(() => {
    const fetchConvosWithoutEquity = () => {
      setIsFiltering(true);
      let filteredConvos = getConversationsWithoutAddresses().sort((a, b) => {
        if (a.conversation.open && !b.conversation.open) return -1;
        if (!a.conversation.open && b.conversation.open) return 1;
        return 0;
      });

      filteredConvos.filter((conversation) => (showClosedTickets ? true : conversation.conversation.open));

      if (selectedAddresses.size > 0) {
        filteredConvos = filteredConvos
          .filter((conversation) => selectedAddresses.has(String(conversation.admin?.id)))
          .filter((conversation) => (showClosedTickets ? true : conversation.conversation.open));
      } else {
        filteredConvos = [];
      }

      setConvosWithoutEquity(filteredConvos);
    };

    fetchConvosWithoutEquity();
    setIsFiltering(false);
  }, [getConversationsWithAddresses, getConversationsWithoutAddresses, selectedAddresses, showClosedTickets]);

  return (
    <IntercomWrapper>
      <div style={{ display: "flex" }}>
        <div>
          <FilterWrapper>Ticket Query Limit</FilterWrapper>
          <Select
            options={[
              {
                label: "10",
                isActive: limit === 10,
                onClick: () => setLimit(10),
              },
              {
                label: "20",
                isActive: limit === 20,
                onClick: () => setLimit(20),
              },

              {
                label: "50",
                isActive: limit === 50,
                onClick: () => setLimit(50),
              },
              {
                label: "100",
                isActive: limit === 100,
                onClick: () => setLimit(100),
              },
              {
                label: "150",
                isActive: limit === 150,
                onClick: () => setLimit(150),
              },
            ]}
          />
        </div>
        <div style={{ marginLeft: SPACING.three }}>
          <FilterWrapper>Closed Tickets</FilterWrapper>
          <CheckboxWrapper type="button" onClick={() => setShowClosedTickets(!showClosedTickets)}>
            <Checkbox checked={showClosedTickets} />
            <ClosedCheckbox style={{ opacity: showClosedTickets ? 1 : 0.6 }}>
              {showClosedTickets ? "Enabled" : "Disabled"}
            </ClosedCheckbox>
          </CheckboxWrapper>
        </div>
      </div>
      <div>
        <FilterWrapper>
          <span>Assignees</span>
          <LinkButton onClick={onSelectAll}>{isAllSelected ? "Deselect" : "Select"} All</LinkButton>
        </FilterWrapper>
        <Flex gap={SPACING.two} wrap>
          {Object.entries(INTERCOM_ADMINS).map(([key, value]) => (
            <AddressTag
              isActive={selectedAddresses.has(key)}
              onClick={() => onSelectAddress(key)}
              key={String(key)}
              style={{ display: "flex" }}
            >
              {value.id ? (
                <IconWrapper style={{ marginRight: SPACING.two, height: 15, width: 15 }}>
                  <Jazzicon diameter={15} seed={generateSeedWithAddress(String(value.id))} />
                </IconWrapper>
              ) : undefined}
              {value.name}
            </AddressTag>
          ))}
        </Flex>
      </div>
      <div>
        <ConversationTable
          hasAccountData
          conversations={convosWithEquity}
          showClosedTickets={showClosedTickets}
          isLoading={isValidating || isFiltering}
        />
      </div>
      <div>
        <ConversationTable
          conversations={convosWithoutEquity}
          showClosedTickets={showClosedTickets}
          isLoading={isValidating || isFiltering}
        />
      </div>
    </IntercomWrapper>
  );
}
