import { CSVLink } from "react-csv";
import { CSVLinkWrapper } from "./style";

interface IDownloadCSVButtonProps {
  data?: string[][];
  filename: string;
}

function DownloadCSVButton({ data, filename }: IDownloadCSVButtonProps) {
  return (
    <CSVLinkWrapper onClick={(e) => e.stopPropagation()}>
      {data ? (
        <CSVLink data={data} filename={filename} target="_blank">
          Download as CSV
        </CSVLink>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a aria-disabled>Download as CSV</a>
      )}
    </CSVLinkWrapper>
  );
}

export default DownloadCSVButton;
