import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { AirtableEndpointEnum } from "../../../enums/endpoints";
import { AIRTABLE_API_URL } from "../../../utils/env";
import { ICustomAggregatedPosition } from "../../../interfaces/positions";

export const useCustomPositions = () => {
  const { tokenId } = useContext(AuthContext);

  const swr = useSWR<ICustomAggregatedPosition[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ?
      [AIRTABLE_API_URL(AirtableEndpointEnum.ACCOUNT_POSITIONS), tokenId] :
      undefined,
    async () => {
      try {
        const accountSummary = await axios.get<any>(
          AIRTABLE_API_URL(AirtableEndpointEnum.ACCOUNT_POSITIONS),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        return accountSummary.data.data;
      } catch (error) {
        console.error(error);
      }
    }
  );

  return {
    ...swr,
  };
};
