import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAccount } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

interface IAccountsResponse {
  total_accounts: number;
  pagination: number;
  size: number;
  data: IAccount[];
}

export const useAccounts = (pagination: number, size: number) => {
  const { queryConfig, tokenId } = useContext(AuthContext);

  const swr = useSWRImmutable<IAccountsResponse, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ? [AdminEndpointEnum.ACCOUNT, tokenId, pagination, size] : undefined,
    async () => {
      const accountDetails = await (
        await axios.get<IAccountsResponse>(ADMIN_API_URL(AdminEndpointEnum.ACCOUNTS), {
          ...queryConfig(),
          params: {
            size,
            pagination,
          },
        })
      ).data;

      accountDetails.data.sort((a: IAccount, b: IAccount) => Number(b.equity) - Number(a.equity));

      return accountDetails;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNTS],
    }
  );

  return {
    ...swr,
  };
};
