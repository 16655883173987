import styled, { css } from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { GreekStatsWrapper } from "../Account/stats/style";

export const BookWrapper = styled.div`
  height: calc(100vh - ${COMPONENTS.header.main + SPACING.three}px);
  padding-bottom: ${SPACING.three}px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const StickyHeader = styled.div`
  background: ${BACKGROUND_COLORS.five};
  z-index: 100;
  padding: ${SPACING.three}px;

  > * {
    max-width: 100%;
  }

  > ${GreekStatsWrapper} {
    padding-top: ${SPACING.three}px;
    width: inherit;
  }
`;

export const AddressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100vw - ${SPACING.three * 2}px);
  padding-bottom: ${SPACING.three}px;
  margin-top: ${SPACING.one}px;
`;

export const TableWrapper = styled.div`
  margin: 0 ${SPACING.three}px;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const AddressTag = styled.span<{ isActive?: boolean }>`
  background: ${BACKGROUND_COLORS.component};
  padding: ${SPACING.two}px;
  ${FONT_STYLE.data.three};
  border-radius: 6px;
  position: relative;
  transition: 0.1s all ease-in-out;
  border: 1px solid ${BORDER_COLORS.one};
  display: inline-block;

  ${({ isActive }) =>
    isActive
      ? undefined
      : css`
          opacity: 0.5;
        `};

  &:hover {
    cursor: pointer;
    border: 1px solid ${COLORS.blue.one};
  }
`;

export const LastUpdatedText = styled.div`
  display: inline-flex;
  ${FONT_STYLE.label.two};
  color: ${TEXT_COLORS.two};

  > span {
    color: ${TEXT_COLORS.one};
    margin-left: ${SPACING.one}px;
  }
`;
