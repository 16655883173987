import styled from "styled-components";
import { SPACING } from "../../constants/design/spacing";

export const OrdersWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;

  > * {
    overflow: hidden;
    margin: ${SPACING.two}px;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: ${SPACING.two}px;
    }
  }

  > div {
    overflow: hidden;
  }
`;
