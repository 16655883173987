import styled from "styled-components";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const Page = styled.div`
  height: calc(100vh - ${COMPONENTS.header.main}px);
  display: grid;
  grid-template-rows: auto auto 1fr;
`;

export const ActionWrapper = styled.div`
  display: flex;
  padding: ${SPACING.two}px ${SPACING.three}px;
  border-top: 1px solid ${BORDER_COLORS.one};

  > *:not(:first-of-type) {
    margin-left: ${SPACING.two}px;
  }
`;

export const QueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.three}px;
  overflow-y: scroll;

  > div {
    align-items: center;
    > span {
      &:first-of-type {
        border-radius: 4px;
        border: 1px solid ${COLORS.orange.one};
        background-color: ${COLORS.orange.four};
        padding: ${SPACING.one}px;
      }

      &:nth-of-type(2) {
        color: ${TEXT_COLORS.two};
      }

      &:not(:first-of-type) {
        margin-left: ${SPACING.two}px;
      }
    }
  }

  > button {
    margin-top: ${SPACING.two}px;
    width: fit-content;
  }
`;

export const JSONContainer = styled.div`
  overflow: scroll;
  border-top: 1px solid ${BORDER_COLORS.one};
`;
