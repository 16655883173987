import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import currency from "currency.js";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, LeftTitleWrapper, TableHeaderCell, TableLoader, TableWrapper, TitleWrapper } from "../../Table/style";
import { COLORS } from "../../../constants/design/colors";
import { BidAsk } from "../../Debug/Orderbook";
import { SPACING } from "../../../constants/design/spacing";

interface IBidsAsksTableProps {
  bidsAsks: BidAsk[];
  isLoading?: boolean;
}

export function BidsAsksTable({ bidsAsks, isLoading }: IBidsAsksTableProps) {
  const columns: ITableColumn<BidAsk>[] = useMemo(
    () => [
      {
        title: "Price",
        align: "left",
        accessor: "price",
        Cell: ({ row }) => (
          <Align align="right">
            <div
              style={{
                color: row.original.isBid ? COLORS.green.one : COLORS.red.one,
              }}
            >
              {Number(row.original.price)}
            </div>
          </Align>
        ),
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        Cell: ({ row }) => (
          <Align align="right">{currency(Math.abs(Number(row.original.amount))).format({ symbol: "" })}</Align>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: bidsAsks,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <div>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>Orderbook</h3>
        </LeftTitleWrapper>
      </TitleWrapper>
      <TableWrapper style={{ width: 200, overflow: "auto", marginRight: SPACING.two }}>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps} style={{ height: 4 }}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
}
