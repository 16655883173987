import { AnimatePresence } from "framer-motion";
import { CSSProperties, PropsWithChildren, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { CloseButton, Content, ModalBody, ModalContent, ModalHeader, ModalWrapper, Title } from "./style";
import useOutsideAlerter from "../../../hooks/outsideAlerter/useOutsideAlerter";
import useKeydownHandler, { KeydownHandlerKeyEnum } from "../../../hooks/useKeydownHandler";

export interface IBaseModalProps {
  style?: CSSProperties;
  title?: string | JSX.Element;
  titleStyle?: CSSProperties;
  hideCloseButton?: boolean;
  noContentPadding?: boolean;
  animateTitle?: boolean;
  showHeader?: boolean;
  onHide?: () => void;
  show: boolean;
  [other: string]: any;
}

export function BaseModal({
  title,
  onHide,
  style,
  titleStyle,
  children,
  show,
  noContentPadding,
  animateTitle,
  hideCloseButton,
  showHeader = true,
  ...others
}: PropsWithChildren<IBaseModalProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const clickOutside = useCallback(() => {
    if (Number(wrapperRef.current?.style.opacity) > 0) {
      onHide?.();
    }
  }, [onHide]);

  useOutsideAlerter([modalContentRef], clickOutside);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, clickOutside);

  const root = document.getElementById("modal-portal");

  if (root) {
    return createPortal(
      <AnimatePresence>
        {show && (
          <ModalWrapper ref={wrapperRef} {...others} show={show}>
            <ModalBody
              style={style}
              ref={modalContentRef}
              transition={{
                duration: 0.1,
                ease: "easeInOut",
              }}
              initial={{
                scale: 0.9,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
              exit={{
                scale: 0.9,
                opacity: 0,
              }}
            >
              {/* CONTENT */}
              <ModalContent
                transition={{
                  duration: 0.1,
                  ease: "easeInOut",
                }}
                exit={{
                  scale: 0.9,
                  opacity: 0,
                }}
              >
                {showHeader && (title || !hideCloseButton) && (
                  <ModalHeader>
                    <AnimatePresence>
                      <Title
                        style={titleStyle ? { ...titleStyle } : {}}
                        key={animateTitle && typeof title === "string" ? title : ""}
                        transition={{
                          duration: 0.1,
                          ease: "easeInOut",
                        }}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        {title || ""}
                      </Title>
                    </AnimatePresence>
                    {onHide && !hideCloseButton && (
                      <CloseButton onClick={onHide}>
                        <Close />
                      </CloseButton>
                    )}
                  </ModalHeader>
                )}
                <Content noPadding={noContentPadding}>{children}</Content>
              </ModalContent>
            </ModalBody>
          </ModalWrapper>
        )}
      </AnimatePresence>,
      root
    );
  }
  return null;
}
