import ReactDropdown from "react-dropdown";
import "./style.css";

interface IMarketDropdownProps {
  onClick: (selectedOption: string) => void;
  overridePlaceholder?: string;
}

export function OptionsDropdown({ onClick, overridePlaceholder }: IMarketDropdownProps) {
  return (
    <ReactDropdown
      className="dropdown"
      menuClassName="dropdown-menu"
      placeholderClassName="dropdown-placeholder"
      options={[
        {
          label: overridePlaceholder ?? "All Assets",
          value: "",
        },
        // default to having ETH, BTC on top
        {
          label: "ETH",
          value: "ETH",
        },
        {
          label: "BTC",
          value: "BTC",
        }
      ]}
      onChange={(selectedOption) => onClick(selectedOption?.value)}
      placeholder={overridePlaceholder ?? "All Assets"}
    />
  );
}
