import { useCallback, useContext, useMemo } from "react";
import currency from "currency.js";
import { IKeyValue } from ".";
import { DataContext } from "../../../context/DataContext";
import { useAccount } from "../../../hooks/api/admin/useAccount";
import { useBinancePositions } from "../../../hooks/api/binance/useBinancePositions";
import { useDeribitAccount } from "../../../hooks/api/deribit/useDeribitAccount";
import { useDeribitPositions } from "../../../hooks/api/deribit/useDeribitPositions";
import { IGreeks, useComputations } from "../../../hooks/useComputations";
import { IAggregatedPosition } from "../../../interfaces/positions";
import { standardizeAevoPositions } from "../../../utils/positions/aevo";
import { standardizeBinancePositions } from "../../../utils/positions/binance";
import { standardizeDeribitPositions } from "../../../utils/positions/deribit";
import { GreekLogo, GreekStat, GreekStatsWrapper, GreekWrapper } from "./style";
import { hasCredentials } from "../../../constants/credentials";

interface IGreekStatsProps {
  selectedAsset?: string;
}

export interface IStat extends IGreeks {
  pnl: number;
}

export function GreekStats({ selectedAsset }: IGreekStatsProps) {
  const { computeDeribitCashFlow, computeDeribitEquity } = useComputations();
  const { address: account } = useContext(DataContext);
  const { data: deribitPositionsData = [] } = useDeribitPositions(selectedAsset);
  const { data: deribitAccountData } = useDeribitAccount(selectedAsset);
  const { data: accountData } = useAccount();
  const { data: binancePositionsData } = useBinancePositions();

  const computeStats = useCallback(
    (positions: IAggregatedPosition[]) => {
      const stats: IStat = {
        delta: 0,
        gamma: 0,
        vega: 0,
        theta: 0,
        pnl: 0,
      };

      if (selectedAsset) {
        positions
          .filter((pos) => pos.asset === selectedAsset)
          .forEach((position) => {
            stats.delta += Number(position.delta);
            stats.gamma += Number(position.gamma);
            stats.vega += Number(position.vega);
            stats.theta += Number(position.theta);
            stats.pnl += Number(position.pnl || 0);
          });
      } else {
        positions.forEach((position) => {
          stats.pnl += position.pnl || 0;
        });
      }

      return stats;
    },
    [selectedAsset]
  );

  const aevoStats: IStat = useMemo(
    () => computeStats(standardizeAevoPositions(accountData?.positions || [])),
    [accountData?.positions, computeStats]
  );

  const deribitStats: IStat = useMemo(() => {
    const deribitPositions = standardizeDeribitPositions(deribitPositionsData || []);
    if (selectedAsset) {
      const cashflow = computeDeribitCashFlow(deribitPositionsData, selectedAsset);

      const equity = computeDeribitEquity(Number(deribitAccountData?.equity), selectedAsset);
      if (cashflow) deribitPositions.push(cashflow);
      if (equity) deribitPositions.push(equity);
    }

    return computeStats(deribitPositions);
  }, [
    computeDeribitCashFlow,
    computeDeribitEquity,
    deribitPositionsData,
    computeStats,
    deribitAccountData?.equity,
    selectedAsset,
  ]);

  const binanceStats: IStat = useMemo(
    () => computeStats(standardizeBinancePositions(binancePositionsData || [])),
    [binancePositionsData, computeStats]
  );

  const aggregatedStats: IStat = useMemo(
    () => ({
      delta: aevoStats.delta + deribitStats.delta + binanceStats.delta,
      gamma: aevoStats.gamma + deribitStats.gamma + binanceStats.gamma,
      vega: aevoStats.vega + deribitStats.vega + binanceStats.vega,
      theta: aevoStats.theta + deribitStats.theta + binanceStats.theta,
      pnl: aevoStats.pnl + deribitStats.pnl + binanceStats.pnl,
    }),
    [aevoStats, binanceStats, deribitStats]
  );

  const greekContent = useCallback(
    (greek: "delta" | "gamma" | "vega" | "theta") => {
      const deribitStat: IKeyValue = {
        title: "Deribit",
        value: deribitStats[greek],
      };

      const binanceStat: IKeyValue = {
        title: "Binance",
        value: binanceStats[greek],
      };

      const stats: IKeyValue[] = [
        {
          title: "Aevo",
          value: aevoStats[greek],
        },
        ...(hasCredentials(account, "binance") ? [binanceStat] : []),
        ...(hasCredentials(account, "deribit") ? [deribitStat] : []),
        {
          title: "Total",
          value: aggregatedStats[greek],
        },
      ];

      return stats.map((s, i) => (
        <GreekStat key={i} value={Number(s.value)}>
          <span>{s.title}</span>
          <span>{currency(Number(s.value)).format({ symbol: "" })}</span>
        </GreekStat>
      ));
    },
    [account, aevoStats, aggregatedStats, binanceStats, deribitStats]
  );

  const pnlContent = useMemo(() => {
    const deribitStat: IKeyValue = {
      title: "Deribit",
      value: deribitStats.pnl,
    };

    const binanceStat: IKeyValue = {
      title: "Binance",
      value: binanceStats.pnl,
    };

    const stats: IKeyValue[] = [
      {
        title: "Aevo",
        value: aevoStats.pnl,
      },
      ...(hasCredentials(account, "binance") ? [binanceStat] : []),
      ...(hasCredentials(account, "deribit") ? [deribitStat] : []),
      {
        title: "Total",
        value: aggregatedStats.pnl,
      },
    ];

    return stats.map((s, i) => (
      <GreekStat key={i} value={Number(s.value)}>
        <span>{s.title}</span>
        <span>{currency(Number(s.value)).format()}</span>
      </GreekStat>
    ));
  }, [account, aevoStats.pnl, aggregatedStats.pnl, binanceStats.pnl, deribitStats.pnl]);

  if (!account) return null;
  return (
    <div>
      {selectedAsset ? (
        <GreekStatsWrapper>
          <GreekWrapper>
            <GreekLogo>Δ</GreekLogo>
            <div>{greekContent("delta")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>Γ</GreekLogo>
            <div>{greekContent("gamma")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>V</GreekLogo>
            <div>{greekContent("vega")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>θ</GreekLogo>
            <div>{greekContent("theta")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>$</GreekLogo>
            <div>{pnlContent}</div>
          </GreekWrapper>
        </GreekStatsWrapper>
      ) : null}
    </div>
  );
}
