import { KeyValueWrapper, Title, Value } from "./style";

export interface IKeyValue {
  title: string;
  value?: string | number | JSX.Element;
  wrapperStyle?: React.CSSProperties;
}

export function KeyValue({ title, value, wrapperStyle }: IKeyValue) {
  return (
    <KeyValueWrapper style={wrapperStyle}>
      <Title>{title}</Title>
      <Value value={Number(value) ? Number(value) : 0}>{value}</Value>
    </KeyValueWrapper>
  );
}
