import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { BORDER_COLORS, BACKGROUND_COLORS, TEXT_COLORS, COLORS } from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_SIZE } from "../../constants/design/fontSize";

export const AccountSettingsWrapper = styled.div``;

export const SettingsWrapper = styled.div<{ isAccount?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: scroll;
  padding: ${SPACING.three}px;

  svg {
    margin: auto 0;
    margin-right: ${SPACING.two}px;
    stroke: ${COLORS.blue.one};
  }

  height: ${({ isAccount }) =>
    isAccount
      ? css`
  calc(
    100vh - ${COMPONENTS.header.main + COMPONENTS.header.subheader}px -
      ${SPACING.three * 2}px
  );
  `
      : css`
  calc(
    100vh - ${COMPONENTS.header.main}px -
      ${SPACING.three * 2}px
  );
  `};
`;

export const SettingsGrid = styled(motion.div)`
  display: grid;
  overflow-y: scroll;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${SPACING.four}px ${SPACING.six}px;
`;

export const SettingsTableWrapper = styled.div``;

export const SubmitButton = styled.button`
  color: ${COLORS.blue.one};

  &:disabled {
    opacity: 0.4;
  }
`;

export const SettingTitle = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;

    img {
      margin-right: ${SPACING.two}px;
    }

    button:not(${SubmitButton}) {
      color: ${TEXT_COLORS.two};
    }
  }
`;

export const SettingsForm = styled.form`
  border-radius: 6px;
  border: 1px solid transparent;
  box-sizing: content-box;

  > div:not(${SettingTitle}) {
    display: flex;
    padding: 0 ${SPACING.two}px;

    > * {
      margin: auto 0;
    }
  }
`;

export const INPUT_WIDTH = 200;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${TEXT_COLORS.two};
  min-height: 35px;
  margin: auto;

  &:first-child {
    width: calc(100% - ${INPUT_WIDTH}px);
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    color: ${TEXT_COLORS.one};

    input {
      border: 1px solid ${COLORS.blue.one};
    }
  }
`;

export const SettingsStatWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${BORDER_COLORS.one};

  > div {
    display: flex;
    justify-content: space-between;
    padding: ${SPACING.two}px;

    > span {
      color: ${TEXT_COLORS.two};
    }

    &:nth-of-type(odd) {
      background-color: ${BACKGROUND_COLORS.four};
    }

    &:nth-of-type(even) {
      background-color: ${BACKGROUND_COLORS.nine};
    }

    &:hover {
      background-color: ${BACKGROUND_COLORS.componentSolid};
    }
  }
`;

export const FormInput = styled.input.attrs({
  step: 0.000001,
})<{ hasErrors?: boolean }>`
  width: ${INPUT_WIDTH}px;
  background-color: ${BACKGROUND_COLORS.four};
  border-radius: 4px;
  height: 24px;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  padding: 0 ${SPACING.two}px;
  align-self: flex-end;
  ${({ hasErrors }) => hasErrors && `border: 1px solid ${COLORS.red.one};`}
`;

export const FormError = styled.span`
  color: ${COLORS.red.one};
  font-size: ${FONT_SIZE.xs};
  text-align: right;
  width: 100%;
  justify-content: flex-end;
  display: block;
  margin: ${SPACING.one}px 0;
`;
