import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ISyncState } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";
import { pollingInterval } from "../../../constants/pollingInterval";

export const useSyncState = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.SYNC_STATE);

  const swr = useSWR<ISyncState, AxiosError>(
    tokenId ? [endpoint, tokenId] : undefined,
    async () => {
      const maintenance = await axios.get<ISyncState>(
        endpoint,
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        }
      );

      return maintenance.data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.SYNC_STATE],
    }
  );

  const requestSync = useCallback(
    async (current: ISyncState) => {
      try {
        if (tokenId) {
          await await axios
            .post(
              endpoint,
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(current));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Sync State Requested",
            subheader: "Sync state is in progress",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Sync State Request Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId, endpoint]
  );

  return {
    ...swr,
    requestSync,
  };
};
