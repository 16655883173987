import ReactDropdown from "react-dropdown";

interface IMarketDropdownProps {
  onClick: (selectedOption: string) => void;
  overridePlaceholder?: string;
}

export function StrategiesDropdown({ onClick, overridePlaceholder }: IMarketDropdownProps) {
  return (
    <ReactDropdown
      className="dropdown"
      menuClassName="dropdown-menu"
      placeholderClassName="dropdown-placeholder"
      options={[
        {
          label: overridePlaceholder ?? "All Assets",
          value: "",
        },
        {
          label: "PERP MM",
          value: "PERP MM",
        },
        {
          label: "VERTEX",
          value: "VERTEX",
        },
        {
          label: "OPTIONS",
          value: "OPTIONS",
        },
        {
          label: "PRELAUNCH",
          value: "PRELAUNCH",
        },

      ]}
      onChange={(selectedOption) => onClick(selectedOption?.value)}
      placeholder={overridePlaceholder ?? "All Assets"}
    />
  );
}
