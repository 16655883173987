import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAevoRiskPosition } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

interface IPositionsResponse {
  positions: IAevoRiskPosition[];
}

export type InstrumentType = "NONE" | "OPTION" | "PERPETUAL";

export const usePositions = (
  instrumentType: InstrumentType,
  asset: string,
  limit: number = 20,
) => {
  const { queryConfig, tokenId } = useContext(AuthContext);

  const swr = useSWRImmutable<IPositionsResponse, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ?
      [
        AdminEndpointEnum.RISK_POSITIONS,
        tokenId,
        limit,
        asset,
        instrumentType,
      ] :
      undefined,
    async () => {
      const positions = (
        await axios.get<IPositionsResponse>(
          ADMIN_API_URL(AdminEndpointEnum.RISK_POSITIONS),
          {
            ...queryConfig(),
            params: {
              limit,
              asset,
              ...(instrumentType !== "NONE" ?
                { instrument_type: instrumentType } :
                {}),
            },
          }
        )
      ).data;

      return positions;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.RISK_POSITIONS],
    }
  );

  return {
    ...swr,
  };
};
