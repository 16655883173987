import useSWR from "swr";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";

export interface IAssetReward {
  asset: string;
  amount_float: string;
}

export interface IEpochReward {
  epoch: string;
  epoch_reward: {
    asset_rewards: IAssetReward[];
  };
}

export interface IStrategiesReward {
  strategy_address: string;
  epoch_rewards: IEpochReward[];
}

export interface IStrategiesPosition {
  strategy_address: string;
  strategy_name: string;
  collateral_asset: string;
  pending_deposits: string;
  pending_withdrawals: string;
  shares: string;
  pps: string;
  roi: string;
}

export interface IStrategies {
  strategy_positions: IStrategiesPosition[];
  strategy_rewards: IStrategiesReward[];
}

export const useAccountStrategies = (overrideAccount?: string) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const account = overrideAccount || address;

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_STRATEGIES);
  const swr = useSWR<IStrategies, AxiosError>(tokenId && account ? [endpoint, tokenId, account] : null, async () => {
    const response = await axios.get<IStrategies>(endpoint, {
      ...queryConfig(),
      params: { account },
    });
    return response.data;
  });

  return swr;
};
