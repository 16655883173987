import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import {
  BORDER_COLORS,
  TEXT_COLORS,
  BACKGROUND_COLORS,
} from "../../constants/design/colors";

export const TradesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100% - ${COMPONENTS.header.main}px);
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  gap: ${SPACING.three}px;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AddressInputWrapper = styled.div`
  input {
    height: 16px;
    background: white;
    border: 1px solid ${BORDER_COLORS.one};
    color: ${TEXT_COLORS.one};
    background-color: ${BACKGROUND_COLORS.component};
    padding: ${SPACING.two}px;
    border-radius: 6px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.three};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
