export const INTERNAL_PERPS_SHORT_ADDRESS =
  "0x7B19b83dC868CDD41569A924550efE92B27cBF2e";
export const INTERNAL_PRELAUNCH_ADDRESS =
  "0x9ABf2c0C53c113eB32890370aBdF21183f4484Ba";
export const INTERNAL_PRELAUNCH_2_ADDRESS = "0xec84ba02c009ef03329375424aEBdF8208c2B902";
export const INTERNAL_OPTIONS_ADDRESS =
  "0xE36295FCD5eF04394F13412491142D22888EFD17";
export const INTERNAL_OPTIONS_DAILIES_ADDRESS =
  "0x3cfAf546d68416536F07B62D5E6720A5526e76B8";
export const SELLER_ADDRESS = "0xD4fc8e4219C2804578aB1460B15eFd56B1B74745";
export const OBADJE_OPTIONS_ADDRESS = "0x275E3d37c60cc40b4Dd1A91E6c565e8dA85b3f0b";
export const INTERNAL_PERPS_LONG_ADDRESS = "0xaA886655252e5B6e7612A49863aB1293AF96Be12";
export const SPOT_COUNTERPARTY_ADDRESS = "0x82F9E742e5213D3a2353cb68feA993B51f46815C";
export const INSURANCE_FUND_ADDRESS = "0x6cDc77Af264be2349F98F5d2cb58A453757CAAE7";
export const DISTRIBUTOR_ADDRESS = "0x7DFF18bBa6f2f881a2Eb5733F5C59c0f8f3d2624";
export const LIQUIDATION_REFUNDER_ADDRESS = "0xA9E8baD097Fd293ED835EcFADC56aC973DA8706D";
export const AEUSD_VAULT_ADDRESS = "0xceB3d89ed0fBF2acEBFf36E2FB23DACb79BaF9e7";
export const RISKY_ONCHAIN_MM_ADDRESS = "0xd55878B13f2e9dB91704c00EC9743f2E0aFa1982";

export const deribitAddresses = [
  INTERNAL_OPTIONS_ADDRESS,
];

export const binanceAddresses = [
  INTERNAL_PERPS_SHORT_ADDRESS,
];

export const canSeeAggregatedPositions = [
  INTERNAL_OPTIONS_ADDRESS,
  INTERNAL_PERPS_SHORT_ADDRESS,
];
