import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { IDeribitAccountSummary } from "../../../interfaces/deribit";
import { pollingInterval } from "../../../constants/pollingInterval";
import { DeribitEndpointEnum } from "../../../enums/endpoints";
import { DERIBIT_API_URL } from "../../../utils/env";
import { DataContext } from "../../../context/DataContext";
import { deribitAddresses } from "../../../constants/admin";

interface IDeribitResponse {
  message: string;
  success: boolean;
  data: IDeribitAccountSummary;
}

export const useDeribitAccount = (asset?: string, overrideAccount?: string) => {
  const { tokenId } = useContext(AuthContext);
  const { address } = useContext(DataContext);
  const account = overrideAccount || address;

  const swr = useSWR<IDeribitAccountSummary, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account && asset && deribitAddresses.includes(account)
      ? [DERIBIT_API_URL(DeribitEndpointEnum.ACCOUNT_SUMMARY), tokenId, account, asset]
      : undefined,
    async () => {
      const accountSummary = await axios.get<IDeribitResponse>(DERIBIT_API_URL(DeribitEndpointEnum.ACCOUNT_SUMMARY), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${tokenId}`,
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          account,
          currency: asset,
        },
      });

      return accountSummary.data.data as IDeribitAccountSummary;
    },
    {
      refreshInterval: pollingInterval[DeribitEndpointEnum.ACCOUNT_SUMMARY],
    }
  );

  return {
    ...swr,
  };
};
