import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IDebugState } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useDebug = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();
  const endpoint = ADMIN_API_URL(AdminEndpointEnum.DEBUG);

  const debugAccount = useCallback(
    async (trade_id: string): Promise<IDebugState | undefined> => {
      try {
        if (tokenId) {
          const response = await axios.get(endpoint, {
            params: {
              trade_id
            },
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Check State Request Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, endpoint, tokenId]
  );

  return {
    debugAccount
  };
};
