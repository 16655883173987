import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";
import { useAccount } from "./useAccount";

export interface IManualAccount {
  account: string;
  manual_mode: boolean;
  withdraws: any[];
}

export const useReferral = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();
  const { mutate } = useAccount();

  const createReferral = async (
    referrer: string,
    referee: string,
    referral_bonus: number,
    referee_discount: number
  ) => {
    try {
      await axios
        .post(
          ADMIN_API_URL(AdminEndpointEnum.REFERRAL),
          {
            referrer,
            referee,
            referral_bonus,
            referee_discount,
          },
          {
            headers: { Authorization: `Bearer ${tokenId}` },
          }
        )
        .finally(() => mutate());

      handleSuccess(referee, "Referral created");
    } catch (error) {
      handleError(error as AxiosError, "Referral creation failed");
    }
  };

  const handleSuccess = useCallback(
    (id: string, message: string) => {
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: message,
          subheader: id,
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    },
    [addToast]
  );

  const handleError = useCallback(
    (error: AxiosError, message: string) => {
      // eslint-disable-next-line no-console
      console.error(error);

      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: message,
          subheader: ((error as AxiosError)?.response?.data as any).error,
          status: ToastStatusEnum.ERROR,
        },
        4000
      );
    },
    [addToast]
  );

  return {
    createReferral,
  };
};
