import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { COMPONENTS, SPACING, WIDTHS } from "../../constants/design/spacing";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { ToggleContainer } from "../shared/Toggle/style";

export const AccountOverviewWrapper = styled.div`
  > div {
    &:not(:last-of-type) {
      padding-bottom: 0;
    }
  }
`;

export const AccountRow = styled.div`
  grid-column: 1 / span 1;
  height: fit-content;
`;

export const AccountStatWrapper = styled.div<{ isMobile?: boolean }>`
  border-right: 1px solid ${BORDER_COLORS.two};
  overflow-y: scroll;

  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          min-width: 400px;
        `}

  > ${AccountRow} {
    margin-bottom: ${SPACING.three}px;

    &:last-of-type {
      margin-bottom: ${SPACING.three}px;
    }

    > *:not(h3) {
      font-size: ${FONT_SIZE.two};
    }
  }
`;
export const AccountTable = styled(AccountRow)`
  grid-column: 2 / span 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  max-height: calc(100vh - ${COMPONENTS.header.main}px - ${SPACING.three * 2}px);
  display: grid;
  margin: ${SPACING.three}px;
  grid-gap: ${SPACING.three}px;

  > * {
    min-height: fit-content;
    max-height: 500px;
    overflow: hidden;
    margin: ${SPACING.two}px;
  }
`;

export const CloseAllButton = styled.button`
  flex: 1;
  padding: ${SPACING.two}px;
  border-radius: ${SPACING.two}px;
  font-size: ${FONT_SIZE.two};
  color: ${COLORS.red.one};
  background-color: ${COLORS.red.four};

  transition: outline 0.2s ease-in-out;
  outline: 1px solid transparent;
  &:hover {
    outline: 1px solid ${COLORS.red.one};
  }

  &:active {
    color: ${COLORS.red.three};
    outline: 1px solid transparent;
  }

  &:disabled {
    opacity: 0.64;
    transition: none;
    &:hover {
      outline: none;
    }
  }

  @media screen and (max-width: ${WIDTHS.S}px) {
    display: none;
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > * {
    margin: auto;

    &:not(:last-child) {
      margin-right: ${SPACING.one}px;
    }
  }

  input {
    border: 1px solid ${BORDER_COLORS.one};
    border-radius: 4px;
    color: ${TEXT_COLORS.one};
    background: none;
    width: 100%;
  }

  svg {
    max-width: 20px;
    width: 100%;
    max-height: 20px;
    height: 100%;
    background: ${BACKGROUND_COLORS.component};
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.2s all ease-in-out;

    &:first-of-type {
      &:hover {
        border: 1px solid ${COLORS.green.one};
      }
    }

    &:last-of-type {
      &:hover {
        border: 1px solid ${COLORS.red.one};
      }
    }
  }
`;

export const LinkText = styled(Link)`
  color: ${TEXT_COLORS.one};
  text-decoration: none;
`;

export const MarginWrapper = styled.div`
  display: flex;

  > ${ToggleContainer} {
    cursor: pointer;
    margin: auto 0;
    height: 30px;
    width: 50px;
    padding: 0 ${SPACING.one}px;
    padding-right: 0;
  }
`;

export const LinkButton = styled.button.attrs({
  type: "button",
})`
  ${FONT_STYLE.label.two};
  color: ${COLORS.blue.one};
  cursor: pointer;
  margin-left: ${SPACING.two}px;
  transition: 0.2s all ease-in-out;

  &:disabled {
    opacity: 0.4;
  }
`;

export const RightSide = styled.div`
  overflow-y: scroll;
  height: calc(100vh - ${COMPONENTS.header.main}px - ${COMPONENTS.header.subheader}px);
  width: 420px;
  grid-column: 2 / span 1;
  border-left: 1px solid ${BORDER_COLORS.one};
`;

export const LeftSide = styled.div`
  grid-column: 1 / span 1;
  max-width: calc(100vw - 420px);
  overflow-y: scroll;
`;

export const AccountContentWrapper = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100dvw;
          height: calc(100dvh - ${COMPONENTS.header.mobile + SPACING.one * 2}px);
          overflow-y: scroll;
          border-top: 1px solid ${BORDER_COLORS.one};

          > * {
            padding: ${SPACING.two}px;
          }
        `
      : css`
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 50px 1fr;
          height: 100vh;
        `}
`;

export const TabWrapper = styled.div`
  grid-row: 1 / span 1;
  height: ${COMPONENTS.header.subheader}px;
  border-top: 1px solid ${BORDER_COLORS.one};
  border-bottom: 1px solid ${BORDER_COLORS.one};
  background: black;
  z-index: 1;
  display: flex;
  padding-left: ${SPACING.two}px;

  * {
    text-transform: capitalize;
  }

  > * {
    margin: auto 0;
  }
`;

export const TabButton = styled.button.attrs({
  type: "button",
})`
  text-align: center;
  margin: auto;
  padding: ${SPACING.three}px 0;
  width: 100%;
  height: 100%;
  color: white !important;
  flex: 1;
  border-right: 1px solid ${BORDER_COLORS.two};
`;

export const FilterWrapper = styled.div<{ isMobile?: boolean }>`
  display: inline-block;

  > * {
    margin: auto 0;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          flex-direction: column;

          > * {
            margin: auto 0 !important;

            &:not(:first-child) {
              margin-top: ${SPACING.two}px !important;
            }
          }
        `
      : css``}
`;

export const MobileFooterWrapper = styled.div`
  border-top: 1px solid ${BORDER_COLORS.one};
  padding: ${SPACING.two}px;
  position: fixed;
  bottom: 0;
  background: black;

  > * {
    width: calc(100dvw - ${SPACING.two * 2}px);
    overflow-x: scroll;
  }
`;

export const RedirectButton = styled.button`
  display: inline-flex;
  padding-left: 0;

  > svg {
    margin: auto 0;

    &:hover {
      transition: 0.2s ease-in-out all;
      stroke: ${COLORS.blue.one};
    }
  }
`;

export const QueryTitle = styled.div`
  display: flex;
  color: ${TEXT_COLORS.two};
  margin: ${SPACING.two}px 0;
  margin-top: ${SPACING.three}px;
  ${FONT_CSS.data.three}

  span {
    color: ${COLORS.orange.one};
    margin: 0 ${SPACING.two}px;
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;

  svg {
    max-width: 20px;
    width: 100%;
    max-height: 20px;
    height: 100%;
    background: ${BACKGROUND_COLORS.component};
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.2s all ease-in-out;

    &:hover {
      border: 1px solid ${COLORS.green.one};
    }
  }
`;

export const ReferralConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
  width: 100%;
  ${FONT_STYLE.label.one};

  input {
    border: 1px solid ${BORDER_COLORS.one};
    border-radius: 4px;
    color: ${TEXT_COLORS.one};
    background: none;
    width: 100%;
  }
`;
