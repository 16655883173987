import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CLIENT_ID } from "./utils/env";
import { ModalPortal } from "./portal";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={CLIENT_ID()}>
    <React.StrictMode>
      <App />
      <ModalPortal />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
