import { useCallback, useMemo } from "react";
import currency from "currency.js";
import { IKeyValue } from ".";
import { IAggregatedPosition, ICustomAggregatedPosition } from "../../../interfaces/positions";
import { standardizeCustomPositions } from "../../../utils/positions/custom";
import { IStat } from "./GreekStats";
import { GreekLogo, GreekStat, GreekStatsWrapper, GreekWrapper } from "./style";

interface ICustomGreekStatsProps {
  selectedAsset: string | string | undefined;
  aevoPositions: IAggregatedPosition[];
  binancePositions: IAggregatedPosition[];
  customPositions: ICustomAggregatedPosition[];
  deribitPositions: IAggregatedPosition[];
}

export function CustomGreekStats({
  selectedAsset,
  aevoPositions,
  binancePositions,
  customPositions,
  deribitPositions,
}: ICustomGreekStatsProps) {
  const computeStats = useCallback(
    (positions: IAggregatedPosition[]) => {
      const stats: IStat = {
        delta: 0,
        gamma: 0,
        vega: 0,
        theta: 0,
        pnl: 0,
      };

      if (selectedAsset) {
        positions
          .filter((pos) => pos.asset === selectedAsset)
          .forEach((position) => {
            stats.delta += Number(position.delta);
            stats.gamma += Number(position.gamma);
            stats.vega += Number(position.vega);
            stats.theta += Number(position.theta);
            stats.pnl += Number(position.pnl || 0);
          });
      } else {
        positions.forEach((position) => {
          stats.delta += Number(position.delta);
          stats.gamma += Number(position.gamma);
          stats.vega += Number(position.vega);
          stats.theta += Number(position.theta);
          stats.pnl += Number(position.pnl || 0);
        });
      }

      return stats;
    },
    [selectedAsset]
  );

  const aevoStats: IStat = useMemo(() => computeStats(aevoPositions || []), [aevoPositions, computeStats]);
  const deribitStats: IStat = useMemo(() => computeStats(deribitPositions || []), [deribitPositions, computeStats]);
  const binanceStats: IStat = useMemo(() => computeStats(binancePositions || []), [binancePositions, computeStats]);
  const customStats: IStat = useMemo(
    () => computeStats(standardizeCustomPositions(customPositions || [])),
    [computeStats, customPositions]
  );

  const aggregatedStats: IStat = useMemo(
    () => ({
      delta: aevoStats.delta + deribitStats.delta + binanceStats.delta + customStats.delta,
      gamma: aevoStats.gamma + deribitStats.gamma + binanceStats.gamma + customStats.gamma,
      vega: aevoStats.vega + deribitStats.vega + binanceStats.vega + customStats.vega,
      theta: aevoStats.theta + deribitStats.theta + binanceStats.theta + customStats.theta,
      pnl: aevoStats.pnl + deribitStats.pnl + binanceStats.pnl + customStats.pnl,
    }),
    [aevoStats, binanceStats, customStats, deribitStats]
  );

  const greekContent = useCallback(
    (greek: "delta" | "gamma" | "vega" | "theta") => {
      const stats: IKeyValue[] = [
        {
          title: "Aevo",
          value: aevoStats[greek],
        },
        {
          title: "Deribit",
          value: deribitStats[greek],
        },
        {
          title: "Binance",
          value: binanceStats[greek],
        },
        {
          title: "Custom",
          value: customStats[greek],
        },
        {
          title: "Total",
          value: aggregatedStats[greek],
        },
      ];

      return stats.map((s, i) => (
        <GreekStat key={i} value={Number(s.value)}>
          <span>{s.title}</span>
          <span>{currency(Number(s.value)).format({ symbol: "" })}</span>
        </GreekStat>
      ));
    },
    [aevoStats, deribitStats, binanceStats, customStats, aggregatedStats]
  );

  const pnlContent = useCallback(() => {
    const stats: IKeyValue[] = [
      {
        title: "Aevo",
        value: aevoStats.pnl,
      },
      {
        title: "Deribit",
        value: deribitStats.pnl,
      },
      {
        title: "Binance",
        value: binanceStats.pnl,
      },
      {
        title: "Custom",
        value: customStats.pnl,
      },
      {
        title: "Total",
        value: aggregatedStats.pnl,
      },
    ];

    return stats.map((s, i) => (
      <GreekStat key={i} value={Number(s.value)}>
        <span>{s.title}</span>
        <span>{currency(Number(s.value)).format({ symbol: "$" })}</span>
      </GreekStat>
    ));
  }, [aevoStats, deribitStats, binanceStats, customStats, aggregatedStats]);

  return (
    <div>
      {selectedAsset ? (
        <GreekStatsWrapper>
          <GreekWrapper>
            <GreekLogo>Δ</GreekLogo>
            <div>{greekContent("delta")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>Γ</GreekLogo>
            <div>{greekContent("gamma")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>V</GreekLogo>
            <div>{greekContent("vega")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>θ</GreekLogo>
            <div>{greekContent("theta")}</div>
          </GreekWrapper>
          <GreekWrapper>
            <GreekLogo>PnL</GreekLogo>
            <div>{pnlContent()}</div>
          </GreekWrapper>
        </GreekStatsWrapper>
      ) : null}
    </div>
  );
}
