import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export interface IManualWithdrawal {
  account: string;
  amount: string;
  collateral: string;
  id: string;
  seq: number;
  time: string;
  to: string;
  type: string;
}
export const useManualWithdrawals = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const endpoint = `${ADMIN_API_URL(AdminEndpointEnum.MANUAL_WITHDRAWS)}`;

  const swr = useSWR<IManualWithdrawal[], AxiosError>(
    tokenId ? [endpoint, tokenId] : undefined,
    async () => {
      const txQueue = await axios.get<IManualWithdrawal[]>(
        endpoint,
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        }
      );

      return txQueue.data;
    }
  );

  const postWithdrawAction = useCallback(async (
    postEndpoint: AdminEndpointEnum,
    id: string,
  ) => {
    if (tokenId) {
      await axios
        .post(
          ADMIN_API_URL(postEndpoint),
          {
            withdraw_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        )
        .then(() => swr.mutate());
    }
  }, [swr, tokenId]);

  const handleSuccess = useCallback((id: string, message: string) => {
    addToast(
      {
        type: ToastEnum.SIMPLE,
        header: message,
        subheader: id,
        status: ToastStatusEnum.SUCCESS,
      },
      4000
    );
  }, [addToast]);

  const handleError = useCallback((
    error: AxiosError,
    message: string
  ) => {
    // eslint-disable-next-line no-console
    console.error(error);

    addToast(
      {
        type: ToastEnum.SIMPLE,
        header: message,
        subheader: ((error as AxiosError)?.response?.data as any).error,
        status: ToastStatusEnum.ERROR,
      },
      4000
    );
  }, [addToast]);

  const approveWithdrawal = useCallback(
    async (id: string) => {
      try {
        await postWithdrawAction(
          AdminEndpointEnum.APPROVE_WITHDRAW,
          id,
        );
        handleSuccess(id, "Withdrawal approved successfully");
      } catch (error) {
        handleError(error as AxiosError, "Withdrawal failed to approve");
      }
    },
    [postWithdrawAction, handleSuccess, handleError]
  );

  const cancelWithdrawal = useCallback(
    async (id: string) => {
      try {
        await postWithdrawAction(
          AdminEndpointEnum.CANCEL_WITHDRAW,
          id,
        );
        handleSuccess(id, "Withdrawal cancelled successfully");
      } catch (error) {
        handleError(error as AxiosError, "Withdrawal failed to cancel");
      }
    },
    [postWithdrawAction, handleSuccess, handleError]
  );

  return {
    ...swr,
    approveWithdrawal,
    cancelWithdrawal,
  };
};
