/* eslint-disable no-nested-ternary */
import { Badge, Header } from "./style";
import { TEXT_COLORS } from "../../constants/design/colors";

interface ICollapseHeaderProps {
  title: string;
  count: number;
  showBadge?: boolean;
  onClick?: () => void;
  extra?: JSX.Element;
}

function CollapseHeaderCount({ title, count, showBadge = false, onClick, extra }: ICollapseHeaderProps) {
  return (
    <Header style={{ color: count > 0 ? TEXT_COLORS.one : TEXT_COLORS.three }}>
      <span>{title}</span>
      {count > 0 ? showBadge ? <Badge>{count}</Badge> : <span>({count})</span> : <span>(None)</span>}
      {extra}
    </Header>
  );
}

export default CollapseHeaderCount;
