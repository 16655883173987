import axios from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export interface IPlan {
  instrument: number;
  limit_price: number;
  amount: number;
  side: string;
  is_buy: boolean;
}

export interface IAccountLiquidationPlans {
  created_timestamp: number;
  plans: IPlan[];
  margin: string;
  initial_margin: string;
  maintenance_margin: string;
}

export interface IAccountLiquidationPlansResponse {
  liquidation_plans: IAccountLiquidationPlans[];
}

export const useAccountLiquidationPlans = () => {
  const { tokenId } = useContext(AuthContext);

  const endpoint = `${ADMIN_API_URL(AdminEndpointEnum.LIQUIDATION_PLANS)}`;

  const getAccountLiquidationPlans = useCallback(
    async (account: string): Promise<IAccountLiquidationPlans[]> => {
      try {
        if (tokenId) {
          const response = await axios.get<IAccountLiquidationPlansResponse>(
            endpoint,
            {
              params: {
                account,
              },
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          );

          return response.data.liquidation_plans;
        }
        return [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      }
    },
    [endpoint, tokenId]
  );

  return {
    getAccountLiquidationPlans,
  };
};
