import { MouseEvent, useCallback } from "react";
import { ReactComponent as Checkmark } from "../../../assets/svg/check.svg";
import { Box, CheckboxContainer, CheckboxType } from "./style";
import { COLORS } from "../../../constants/design/colors";

interface ICheckboxProps {
  type?: CheckboxType;
  checked: boolean;
  onToggle?: () => void;
  title?: string;
}

function Checkbox({ type, checked, onToggle, title }: ICheckboxProps) {
  const onCheckboxToggle = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onToggle?.();
    },
    [onToggle]
  );

  return (
    <CheckboxContainer onClick={onCheckboxToggle} isActive={checked} type={type}>
      <Box isActive={checked} type={type}>
        <Checkmark style={{ opacity: checked ? 1 : 0, outline: COLORS.blue.one }} />
      </Box>
      {title && <div>{title}</div>}
    </CheckboxContainer>
  );
}

export default Checkbox;
