import { GoogleOAuthProvider } from "@react-oauth/google";
import { useContext, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConfigProvider from "antd/es/config-provider";
import theme from "antd/es/theme";
import { Account } from "./components/Account";
import { Dashboard } from "./components/Dashboard";
import { Header } from "./components/Header";
import { JSONViewer } from "./components/JSONViewer";
import { Login } from "./components/Login";
import { Toast } from "./components/Toast";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import { ToastContextProvider } from "./context/ToastContext";
import { PageEndpointEnum } from "./enums/endpoints";
import { GlobalStyle } from "./style";
import { CLIENT_ID } from "./utils/env";
import { DataContextProvider } from "./context/DataContext";
import { Spinner } from "./components/shared/Spinner";
import { SpinnerContainerWrapper } from "./components/shared/Spinner/style";
import { Settings } from "./components/Settings";
import { Book } from "./components/Book";
import { TxViewer } from "./components/TxViewer";
import { RiskPage } from "./components/Risk";
import { MMTrades } from "./components/MMTrades";
import { OptionsTrades } from "./components/OptionsTrades";
import { MMStrategies } from "./components/MMStrategies";
import useScreenSize from "./hooks/api/screenSize/useScreenSize";
import { MobileHeader } from "./components/Header/MobileHeader";
import { MobileDashboard } from "./components/Dashboard/MobileDashboard";
import { MobileAccount } from "./components/Account/MobileAccount";
import { MobileTxViewer } from "./components/TxViewer/MobileTxViewer";
import { Debug } from "./components/Debug";
import { Withdraw } from "./components/Withdrawals";
import { Volume } from "./components/Volume";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "./constants/design/colors";
import { Intercom } from "./components/Intercom";
import { Campaigns } from "./components/Campaigns";

interface IRoute {
  path: string;
  component?: JSX.Element;
  key: string;
}

const unauthenticatedRoutes: IRoute[] = [
  {
    path: "/*",
    component: <Login />,
    key: "login",
  },
];

function App() {
  const { tokenId, isAuthenticating } = useContext(AuthContext);
  const { isMobileScreen } = useScreenSize();

  const authenticatedRoutes: IRoute[] = useMemo(
    () => [
      {
        path: "/",
        component: !isMobileScreen ? <Dashboard /> : <MobileDashboard />,
        key: "dashboard",
      },
      {
        path: `${PageEndpointEnum.ACCOUNT}/:account`,
        component: !isMobileScreen ? <Account /> : <MobileAccount />,
        key: "account",
      },
      {
        path: `${PageEndpointEnum.ACCOUNT}/:account/:tab`,
        component: !isMobileScreen ? <Account /> : <MobileAccount />,
        key: "account",
      },
      {
        path: PageEndpointEnum.BOOK,
        component: !isMobileScreen ? <Book /> : undefined,
        key: "book",
      },
      {
        path: PageEndpointEnum.WITHDRAW,
        component: !isMobileScreen ? <Withdraw /> : <Withdraw isMobile />,
        key: "withdraw",
      },
      {
        path: PageEndpointEnum.SETTINGS,
        component: !isMobileScreen ? <Settings /> : undefined,
        key: "settings",
      },
      {
        path: PageEndpointEnum.JSON,
        component: !isMobileScreen ? <JSONViewer /> : undefined,
        key: "json",
      },
      {
        path: PageEndpointEnum.TX,
        component: !isMobileScreen ? <TxViewer /> : <MobileTxViewer />,
        key: "tx",
      },
      {
        path: PageEndpointEnum.RISK,
        component: !isMobileScreen ? <RiskPage /> : undefined,
        key: "risk",
      },
      {
        path: PageEndpointEnum.MMTRADES,
        component: !isMobileScreen ? <MMTrades /> : undefined,
        key: "mm-trades",
      },
      {
        path: PageEndpointEnum.OPTIONSTRADES,
        component: !isMobileScreen ? <OptionsTrades /> : undefined,
        key: "options-trades",
      },
      {
        path: PageEndpointEnum.MMSTRATEGIES,
        component: !isMobileScreen ? <MMStrategies /> : undefined,
        key: "mm-strategies",
      },
      {
        path: PageEndpointEnum.DEBUG,
        component: !isMobileScreen ? <Debug /> : undefined,
        key: "debug",
      },
      {
        path: PageEndpointEnum.VOLUME,
        component: <Volume />,
        key: "volume",
      },
      {
        path: PageEndpointEnum.INTERCOM,
        component: <Intercom />,
        key: "intercom",
      },
      {
        path: PageEndpointEnum.CAMPAIGNS,
        component: <Campaigns />,
        key: "campaigns",
      },
    ],
    [isMobileScreen]
  );

  const routes = useMemo(
    () => (tokenId ? authenticatedRoutes : unauthenticatedRoutes),
    [authenticatedRoutes, tokenId]
  );

  const headerContent = useMemo(() => {
    if (tokenId) {
      if (!isMobileScreen) {
        return <Header />;
      }

      return <MobileHeader />;
    }
  }, [isMobileScreen, tokenId]);

  if (isAuthenticating) {
    return (
      <SpinnerContainerWrapper height={"100vh"}>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID()}>
      <BrowserRouter>
        <div
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          {headerContent}
          <Toast />
          <DataContextProvider>
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                  fontSize: 12,
                  colorPrimary: COLORS.blue.one,
                  colorFillSecondary: COLORS.blue.two,
                  fontFamily: "BaseFont",
                  colorPrimaryBg: LAYER_COLORS.one,
                  colorBgContainer: LAYER_COLORS.one,
                  colorBorder: BORDER_COLORS.two,
                  colorBgBase: LAYER_COLORS.one,
                },
                components: {
                  Collapse: {
                    headerBg: LAYER_COLORS.one,
                    contentBg: BACKGROUND_COLORS.five,
                    borderRadius: 6,
                    colorBorder: BORDER_COLORS.one,
                    contentPadding: 0,
                  },
                  Radio: {
                    colorBorder: BORDER_COLORS.two,
                    // colorPrimaryBorder: "red",
                    // colorBorderBg: "red",
                    // colorPrimaryActive: "red",
                    // buttonSolidCheckedColor: "red",
                    colorText: TEXT_COLORS.two,
                  },
                  Tag: {
                    colorSuccessBg: COLORS.green.five,
                    colorSuccess: COLORS.green.one,
                    colorSuccessBorder: COLORS.green.one,
                    colorErrorBg: COLORS.red.five,
                    colorError: COLORS.red.one,
                    colorErrorBorder: COLORS.red.one,
                  },
                },
              }}
            >
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Routes>
            </ConfigProvider>
          </DataContextProvider>
        </div>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

function RootApp() {
  return (
    <>
      <GlobalStyle />
      <ToastContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ToastContextProvider>
    </>
  );
}

export default RootApp;
