import { IAevoPosition } from "../../interfaces/aevo";
import { IAggregatedPosition } from "../../interfaces/positions";
import { retrieveAssetByInstrumentName } from "../strings";

// Aevo uses ETH-02JUN23-1950-C (with leading 0 in expiry)
// Deribit uses ETH-2JUN23-1950-C
export const sanitizeAevoInstrumentName = (name: string) => {
  if (name.includes("-PERP")) {
    return name.concat("ETUAL");
  }
  const components = name.split("-");
  if (components[1][0] === "0") {
    components[1] = components[1].replace(/^0+/, "");
  }
  return components.join("-");
};

export const standardizeAevoPositions = (
  positions: Partial<IAevoPosition>[],
  asset?: string
): IAggregatedPosition[] => {
  const standardizedPositions: Record<string, IAggregatedPosition> = {};

  // Loop through aevo positions
  positions.forEach((pos) => {
    // Use Deribit convention for instrument name
    const instrumentName = sanitizeAevoInstrumentName(pos.instrument_name!);
    const posSign = pos.side === "buy" ? 1 : -1;
    const isPerpetual = pos.instrument_type === "PERPETUAL";
    const delta = isPerpetual ?
      posSign * Number(pos.amount) :
      Number(pos.option?.delta);

    if (!asset || retrieveAssetByInstrumentName(instrumentName) === asset) {
      standardizedPositions[instrumentName] = {
        instrument_name: instrumentName,
        asset: retrieveAssetByInstrumentName(instrumentName),
        delta,
        gamma: Number(pos?.option?.gamma || 0),
        vega: Number(pos?.option?.vega || 0),
        theta: Number(pos?.option?.theta || 0),
        contracts: posSign * Number(pos.amount),
        pnl: Number(pos.unrealized_pnl || 0),
        side: pos.side || "-",
        isPerpetual,
        source: "Aevo",
      };
    }
  });

  return Object.values(standardizedPositions);
};
