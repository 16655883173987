import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAccountTxs } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

export enum TradeTypeEnum {
  TRADE = "trade",
  LIQUIDATION = "liquidation",
  SETTLEMENT = "settlement",
  FUNDING = "funding",
}

export const useAccountTransactions = (overrideAccount?: string) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const account = overrideAccount || address;

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_TXS);
  const swr = useSWR<IAccountTxs, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const params = {
        account,
        start_time: 0,
        end_time: 0,
        admin_limit: 1000,
        offset: 0,
      };

      const response = await axios.get<IAccountTxs>(endpoint, {
        ...queryConfig(),
        params,
      });

      const { data } = response;
      data.tx_history.transaction_history.sort((a, b) => Number(b.initiated_timestamp) - Number(a.initiated_timestamp));

      return data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT],
    }
  );

  return {
    ...swr,
  };
};
