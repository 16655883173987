import { TableOptions } from "react-table";
import { startCase } from "../../utils/strings";
import SortIcon from "../shared/SortIcon";
import { Align } from "./style";
import { ITableColumn } from "./TableColumn";

interface IDefaultHeaderForColumnProps extends TableOptions<{}> {
  column: ITableColumn<any>;
}

export function DefaultHeaderForColumn({
  column,
}: IDefaultHeaderForColumnProps) {
  const { id, isSorted, isSortedDesc, align, title } = column;
  return (
    <Align align={align || "left"}>
      {startCase(title || id || "")}
      {(column as any)?.canSort && (
        <SortIcon isSortedDesc={isSortedDesc} isSorted={isSorted} />
      )}
    </Align>
  );
}
