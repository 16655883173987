import currency from "currency.js";
import { useCallback, useContext, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import moment from "moment";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, CopyButton, TableHeaderCell, TableLoader } from "../../Table/style";
import { IManualWithdrawal, useManualWithdrawals } from "../../../hooks/api/admin/useManualWithdrawals";
import { DataContext } from "../../../context/DataContext";
import { useMarkets } from "../../../hooks/api/aevo/useMarkets";
import { shortenString } from "../../../utils/strings";
import { ButtonWrapper, ManualTableWrapper, RequestWrapper } from "../style";
import ConfirmationModal from "../../shared/ConfirmationModal";
import { ToastEnum, ToastStatusEnum, useToast } from "../../../hooks/toast";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";
import { ReactComponent as Check } from "../../../assets/svg/check.svg";
import { SPACING } from "../../../constants/design/spacing";
import { Button, ButtonThemeEnum } from "../../shared/Buttons/styles";

interface IWithdrawalsTableProps {
  data: IManualWithdrawal[];
  isLoading?: boolean;
  isMobile?: boolean;
}

const PROXY_ADDRESSES: Record<string, string> = {
  "0x4d44B9AbB13C80d2E376b7C5c982aa972239d845": "Mainnet",
  "0xE3EF8bEE5c378D4D3DB6FEC96518e49AE2D2b957": "Socket",
};

const WARNING_AMOUNT = 20000;
const ALERT_AMOUNT = 100000;

export function WithdrawalsTable({ data, isLoading, isMobile }: IWithdrawalsTableProps) {
  const { records } = useContext(DataContext);
  const { addToast } = useToast();
  const { approveWithdrawal, cancelWithdrawal } = useManualWithdrawals();
  const { data: marketsData } = useMarkets();
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<IManualWithdrawal | undefined>(undefined);

  const onCopy = useCallback(
    (address: string) => {
      navigator.clipboard.writeText(address);
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Withdrawal ID Copied",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    },
    [addToast]
  );

  const computeUSDValue = useCallback(
    (amount: string, asset: string) => {
      let collateral = asset;

      if (asset === "WETH") {
        collateral = "ETH";
      } else if (asset === "WBTC") {
        collateral = "BTC";
      }

      if (!collateral.includes("USD")) {
        const market = marketsData?.find((m) => m.underlying_asset === collateral);

        if (market) {
          return Number(market.mark_price) * Number(amount);
        }
      } else {
        return Number(amount);
      }

      return 0;
    },
    [marketsData]
  );

  const onSetApprove = useCallback((request: IManualWithdrawal) => {
    setSelectedRequest(request);
    setShowApprove(true);
    setShowReject(false);
  }, []);

  const onSetReject = useCallback((request: IManualWithdrawal) => {
    setSelectedRequest(request);
    setShowReject(true);
    setShowApprove(false);
  }, []);

  const columns: ITableColumn<IManualWithdrawal>[] = useMemo(
    () => [
      {
        title: "ID",
        align: "left",
        accessor: "id",
        Cell: ({ value }) => (
          <Align align="left">
            <CopyButton
              onClick={(e) => {
                e.stopPropagation();
                onCopy(value || "");
              }}
              style={{ padding: 0 }}
            >
              <Copy />
            </CopyButton>
            <div style={{ marginLeft: SPACING.two }}>{shortenString(value, 4, 4)} </div>
          </Align>
        ),
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        Cell: ({ value, row }: any) => (
          <Align align="right">
            {currency(value, { precision: 0 }).format({ symbol: "" })} {row.original.collateral}
          </Align>
        ),
      },
      {
        title: "USD Value",
        align: "right",
        accessor: "amount",
        id: "usd",
        Cell: ({ value, row }: any) => {
          const usdValue = computeUSDValue(value, row.original.collateral);
          return (
            <Align align="right">
              <div
                style={{
                  color:
                    // eslint-disable-next-line no-nested-ternary
                    usdValue >= ALERT_AMOUNT
                      ? COLORS.red.one
                      : usdValue >= WARNING_AMOUNT
                      ? COLORS.orange.one
                      : TEXT_COLORS.one,
                }}
              >
                {currency(usdValue).format()}
              </div>
            </Align>
          );
        },
      },
      {
        title: "Destination",
        align: "right",
        accessor: "to",
        Cell: ({ value }) => <Align align="right">{PROXY_ADDRESSES[value]}</Align>,
      },
      {
        title: "Created On",
        align: "right",
        accessor: "time",
        valueExtractor: (value) => moment(value).format("DD MMM YYYY HH:mm:ss").toString(),
      },
      {
        title: "",
        align: "right",
        accessor: "account",
        id: "actions",
        Cell: ({ row }) => (
          <Align align="right">
            <ButtonWrapper isMobile={isMobile}>
              <Button
                buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                onClick={(e) => {
                  e.stopPropagation();
                  onSetApprove(row.original);
                }}
              >
                {isMobile ? <Check /> : "Approve"}
              </Button>
              <Button
                buttonTheme={ButtonThemeEnum.NEGATIVE}
                onClick={(e) => {
                  e.stopPropagation();
                  onSetReject(row.original);
                }}
              >
                {isMobile ? <Cross /> : "Reject"}
              </Button>
            </ButtonWrapper>
          </Align>
        ),
      },
    ],
    [computeUSDValue, isMobile, onCopy, onSetApprove, onSetReject]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
      expandSubRows: true,
    },
    useSortBy
  );

  return (
    <div>
      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Approve Withdrawal"}
        extras={
          <RequestWrapper>
            This will <span style={{ color: COLORS.blue.one }}>approve</span> the withdrawal request of{" "}
            {selectedRequest?.amount} {selectedRequest?.collateral} made by{" "}
            <div
              style={{
                color: records[selectedRequest?.account!] ? COLORS.blue.one : TEXT_COLORS.one,
              }}
            >
              {records[selectedRequest?.account!] ? records[selectedRequest?.account!] : selectedRequest?.account!}
            </div>
          </RequestWrapper>
        }
        show={showApprove && !!selectedRequest}
        onHide={() => setShowApprove(false)}
        confirmationButton={{
          title: "Approve Withdrawal",
          onClick: async () => {
            if (selectedRequest) {
              approveWithdrawal(selectedRequest.id).finally(() => {
                setShowApprove(false);
                setSelectedRequest(undefined);
              });
            }
          },
          disabled: isLoading,
        }}
      />

      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.red.one}
        secondaryColor={COLORS.red.two}
        tertiaryColor={COLORS.red.three}
        title={"Reject Withdrawal"}
        extras={
          <RequestWrapper>
            This will <span style={{ color: COLORS.red.one }}>reject</span> the withdrawal request of{" "}
            {selectedRequest?.amount} {selectedRequest?.collateral} made by{" "}
            <div
              style={{
                color: records[selectedRequest?.account!] ? COLORS.blue.one : TEXT_COLORS.one,
              }}
            >
              {records[selectedRequest?.account!] ? records[selectedRequest?.account!] : selectedRequest?.account!}
            </div>
          </RequestWrapper>
        }
        show={showReject && !!selectedRequest}
        onHide={() => setShowReject(false)}
        confirmationButton={{
          title: "Reject",
          onClick: async () => {
            if (selectedRequest) {
              cancelWithdrawal(selectedRequest.id).finally(() => setShowReject(false));
            }
          },
          disabled: isLoading,
        }}
      />

      <ManualTableWrapper>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </ManualTableWrapper>
    </div>
  );
}
