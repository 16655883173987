/* eslint-disable no-plusplus */
import moment from "moment";

interface TxServiceState {
  txindex: number;
  txs: string[];
  events: string[];
}

export async function parseTxJSON(tokenId: string, txService: TxServiceState) {
  const { txindex, txs, events } = txService;

  const newTxs = txs.map((tx) => {
    const jsonStr = window.atob(tx);
    return JSON.parse(jsonStr);
  });

  const newEvents = events.map((ev) => {
    const jsonStr = window.atob(ev);
    return JSON.parse(jsonStr);
  });

  const txCount = newTxs.length;
  const eventCount = newEvents.length;

  const encodedTxs: {
    [details: number]: boolean;
  } = {};

  try {
    if (txCount > 0) {
      for (let i = 0; i < newTxs.length; i++) {
        if (newTxs[i].transactionEvent && newTxs[i].transactionEvent.originSeq) {
          const { originSeq } = newTxs[i].transactionEvent;

          for (let j = 0; j < newEvents.length; j++) {
            if (newEvents[j].seq && originSeq === newEvents[j].seq) {
              newTxs[i].originEvent = newEvents[j];
              encodedTxs[originSeq] = true;
              break;
            }
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  let txPendingDiff = "";
  let eventPendingDiff = "";

  if (txCount > 0) {
    const txTimestamp = moment(newTxs[0].timestamp).utc();
    txPendingDiff = txTimestamp.from(moment().utc());
  }
  if (eventCount > 0) {
    const eventTimestamp = moment(newEvents[0].timestamp).utc();
    eventPendingDiff = eventTimestamp.from(moment().utc());
  }

  return {
    txCount,
    eventCount,
    txindex,
    txPendingDiff,
    eventPendingDiff,
    txs: newTxs,
    events: newEvents.filter((event) => !encodedTxs[event.seq]),
  };
}
