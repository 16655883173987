import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import { DefaultCellForColumn } from "../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../Table/TableColumn";
import {
  LeftTitleWrapper,
  TableHeaderCell,
  TableLoader,
  TableWrapper,
  TitleWrapper,
} from "../Table/style";
import { ICampaignStat } from "../../interfaces/aevo";

interface IPositionTableProps {
  campaigns: ICampaignStat[];
  isLoading?: boolean;
}

export function CampaignsTable({ campaigns, isLoading }: IPositionTableProps) {
  const columns: ITableColumn<any>[] = useMemo(
    () => [
      {
        title: "Campaign",
        align: "left",
        accessor: "campaign_name",
      },
      {
        title: "Sign Ups",
        align: "right",
        id: "sign_ups",
        accessor: "sign_ups",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: campaigns,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        } as any,
        autoResetSortBy: false,
        expandSubRows: true,
      },
      useSortBy
    );

  return (
    <div>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>Campaigns</h3>
        </LeftTitleWrapper>
      </TitleWrapper>

      <TableWrapper>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
}
