import {
  IAggregatedPosition,
  ICustomAggregatedPosition,
} from "../../interfaces/positions";

// Aevo uses ETH-02JUN23-1950-C (with leading 0 in expiry)
// Deribit uses ETH-2JUN23-1950-C
export const sanitizeCustomInstrumentName = (name: string) => {
  const components = name.split("-");
  if (components[1][0] === "0") {
    components[1] = components[1].replace(/^0+/, "");
  }
  return components.join("-");
};

// We assume that all custom positions will always be an Option
export const standardizeCustomPositions = (
  positions: Partial<ICustomAggregatedPosition>[],
  asset?: string
): IAggregatedPosition[] => {
  const standardizedPositions: Record<string, IAggregatedPosition> = {};

  const filteredPositions = asset ?
    positions.filter((pos) => pos.asset === asset) :
    positions;
  filteredPositions.forEach((pos) => {
    const posSign = pos.side === "buy" ? 1 : -1;
    standardizedPositions[pos.instrument_name as string] = {
      instrument_name: sanitizeCustomInstrumentName(
        pos.instrument_name as string
      ),
      asset: pos.asset as string,
      side: pos.side as string,
      delta: Number(pos.delta),
      contracts: posSign * Number(pos.contracts),
      gamma: Number(pos.gamma) * Number(pos.contracts),
      vega: Number(pos.vega) * Number(pos.contracts),
      theta: posSign * Number(pos.theta) * Number(pos.contracts),
      pnl: Number(pos.pnl),
      isPerpetual: false,
      source: pos.source || "Custom",
    };
  });

  return Object.values(standardizedPositions);
};
