import currency from "currency.js";
import { useCallback, useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, TableHeaderCell, TableLoader } from "../../Table/style";
import { IAggregatedPosition, ICustomAggregatedPosition } from "../../../interfaces/positions";
import { LinkButton } from "../style";
import { standardizeCustomPositions } from "../../../utils/positions/custom";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";
import { useMarkets } from "../../../hooks/api/aevo/useMarkets";
import CollapseHeaderCount from "../../TableCollapse";
import { Spinner } from "../../shared/Spinner";

interface ICustomPositionTableProps {
  customPositions: ICustomAggregatedPosition[];
  selectedAsset: string | string | undefined;
  isLoading: boolean;
  onRefresh?: () => void;
}

export function CustomPositionTable({
  customPositions,
  selectedAsset,
  isLoading,
  onRefresh,
}: ICustomPositionTableProps) {
  const { data: marketsData, isLoading: isMarketLoading, isValidating: isMarketValidating } = useMarkets();

  const memoizedPositions = useMemo(() => {
    if (!selectedAsset) return standardizeCustomPositions(customPositions);
    return standardizeCustomPositions(customPositions.filter((pos) => pos.asset === selectedAsset));
  }, [customPositions, selectedAsset]);

  const getNotional = useCallback(
    (pos: IAggregatedPosition, returnValue: boolean = false) => {
      const asset = retrieveAssetByInstrumentName(pos.instrument_name!);
      const market = marketsData?.find((m) => m.underlying_asset === asset);
      if (market) {
        if (returnValue) {
          return Number(market.mark_price) * Number(pos.contracts);
        }
        return currency(Number(market.mark_price) * pos.contracts).format();
      }

      if (!isMarketLoading && isMarketValidating) {
        if (returnValue) {
          return 0;
        }
        return <Spinner />;
      }

      if (returnValue) {
        return 0;
      }
      return "-";
    },
    [marketsData, isMarketLoading, isMarketValidating]
  );

  const columns: ITableColumn<IAggregatedPosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Net Side",
        align: "left",
        accessor: "contracts",
        id: "net_side",
        Cell: ({ value }) => (
          <div
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                value === 0 ? TEXT_COLORS.three : value > 0 ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {value === 0 ? "-" : value > 0 ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        id: "contracts",
        accessor: "contracts",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
      {
        title: "Notional",
        align: "right",
        id: "notional",
        accessor: "contracts",
        Cell: ({ row }) => <Align align={"right"}>{getNotional(row.original)}</Align>,
        sortType: (a: any, b: any) =>
          Number(getNotional(a.original, true)) > Number(getNotional(b.original, true)) ? 1 : -1,
      },
      {
        title: "Delta",
        align: "right",
        accessor: "delta",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Gamma",
        align: "right",
        accessor: "gamma",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Gamma (USD)",
        align: "right",
        id: "gamma-notional",
        // @ts-ignore
        accessor: "gamma",
        Cell: ({ value, row }: any) => {
          const market = marketsData?.find((m) => m.underlying_asset === row.original.asset);
          return (
            <Align align={"right"}>
              {value && market?.mark_price
                ? currency(Number(value) * Number(market?.mark_price) ** 2 * 0.01).format()
                : "-"}
            </Align>
          );
        },
      },
      {
        title: "Vega",
        align: "right",
        accessor: "vega",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Theta",
        align: "right",
        accessor: "theta",
        valueExtractor: (value) => (value ? `${currency(value).format({ symbol: "", precision: 4 })}` : "-"),
      },
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "pnl",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                Number(value || 0) > 0 ? COLORS.green.one : Number(value || 0) < 0 ? COLORS.red.one : TEXT_COLORS.one,
            }}
          >
            {currency(Number(value)).format()}
          </Align>
        ),
      },
      {
        title: "Source",
        align: "right",
        accessor: "source",
        valueExtractor: (value) => value ?? "-",
      },
    ],
    [getNotional, marketsData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedPositions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedPositions.length} showBadge title="Custom Positions" />,
          extra: (
            <LinkButton
              onClick={(e) => {
                e.stopPropagation();
                onRefresh?.();
              }}
              disabled={isLoading}
            >
              Refresh
            </LinkButton>
          ),
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
