/* eslint-disable no-nested-ternary */
import styled, { css } from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS, FONT_STYLE } from "../../constants/design/fontSize";

export const IntercomWrapper = styled.div`
  height: calc(100vh - ${COMPONENTS.header.main}px - ${SPACING.two * 2}px);
  overflow-y: scroll;
  display: grid;
  grid-template-rows: max-content max-content max-content;
  gap: ${SPACING.two}px;
  padding: ${SPACING.two}px;

  > * {
    grid-row: span 1;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  > .paper > svg {
    filter: blur(5px) saturate(100%) brightness(150%) hue-rotate(45deg);
  }
`;

export const InnerHTML = styled.div`
  width: 500px;
  height: 80px;
  overflow: scroll;

  &:first-child {
    margin: auto;
  }

  * {
    overflow-wrap: break-word;
    text-transform: none !important;
    text-align: left;
    padding: 0;
  }

  a {
    color: ${COLORS.blue.one};
  }
`;

export const TimeAgo = styled.div`
  text-transform: none !important;
  color: ${TEXT_COLORS.three};
  ${FONT_CSS.label.one};
`;

export const Badge = styled.div<{ isOpen?: boolean }>`
  border-radius: 12px;
  padding: ${SPACING.one}px;

  ${({ isOpen }) =>
    isOpen
      ? css`
          background-color: ${COLORS.green.four};
          color: ${COLORS.green.one};
          border: 1px solid ${COLORS.green.four};
        `
      : css`
          background-color: ${COLORS.red.four};
          color: ${COLORS.red.one};
          border: 1px solid ${COLORS.red.four};
        `};
`;

export const StatusCount = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: ${SPACING.two}px;
  }
`;

export const ClosedCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  ${FONT_STYLE.label.two};
  margin-left: ${SPACING.two}px;
`;

export const CheckboxWrapper = styled.button`
  display: flex;
  color: ${TEXT_COLORS.one};
  padding: 0;

  > * {
    transition: 0.2s ease-in-out;
    margin: auto;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;
