import {
  AdminEndpointEnum,
  AevoEndpointEnum,
  DeribitEndpointEnum,
} from "../enums/endpoints";

type PollingInterval = {
  [key in AdminEndpointEnum | AevoEndpointEnum | DeribitEndpointEnum]:
  | number
  | undefined;
};

// Keeps track of the polling interval for each endpoint
// in milliseconds
export const pollingInterval: Partial<PollingInterval> = {
  [AdminEndpointEnum.ACCOUNT]: 60000,
  [AdminEndpointEnum.ACCOUNT_TRADES]: 60000,
  [AdminEndpointEnum.ACCOUNT_TXS]: 60000,
  [AdminEndpointEnum.ACCOUNT_TPSL_HISTORY]: 60000,
  [DeribitEndpointEnum.ACCOUNT_SUMMARY]: 60000,
  [DeribitEndpointEnum.ACCOUNT_POSITIONS]: 60000,
  [AevoEndpointEnum.MARKETS]: 60000,
  [AdminEndpointEnum.CHECK_STATE]: 10000,
  [AdminEndpointEnum.SYNC_STATE]: 10000,
  [AdminEndpointEnum.RISK_POSITIONS]: 10000,
};
