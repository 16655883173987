import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAccountTrades } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

export enum TradeTypeEnum {
  TRADE = "trade",
  LIQUIDATION = "liquidation",
  SETTLEMENT = "settlement",
  FUNDING = "funding",
}

export const useAccountTrades = (
  overrideAccount?: string,
  asset?: string,
  limit?: number,
  start_time?: number,
  end_time?: number,
  tradeTypes?: TradeTypeEnum[]
) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const account = overrideAccount || address;

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_TRADES);
  const swr = useSWR<IAccountTrades, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account, limit, start_time, end_time, tradeTypes] : undefined,
    async () => {
      const params = {
        account,
        asset,
        start_time,
        end_time,
        admin_limit: limit,
        offset: 0,
        trade_types: Object.values(tradeTypes ?? TradeTypeEnum).join(","),
      };
      const response = await axios.get<IAccountTrades>(endpoint, {
        ...queryConfig(),
        params,
      });

      const { data } = response;
      data.trades.trade_history.sort((a, b) => Number(b.created_timestamp) - Number(a.created_timestamp));

      return data;
    }
  );

  return {
    ...swr,
  };
};
