export interface IIntercomAdmin {
    name: string;
    email?: string;
    id?: number;
}

export const INTERCOM_ADMINS: Record<string, IIntercomAdmin> = {
    undefined: {
        name: "Unassigned",
        email: undefined,
        id: undefined
    },
    6453219: {
        name: "Babbala",
        email: "umberto@ribbon.finance",
        id: 6453219
    },
    6788250: {
        name: "Muhammad",
        email: "owaisa770@gmail.com",
        id: 6788250
    },
    7251031: {
        name: "Yi Chu",
        email: "yichu@ribbon.finance",
        id: 7251031
    },
    6475518: {
        name: "Ken",
        email: "ken@ribbon.finance",
        id: 6475518
    },
    6690972: {
        name: "Timothy",
        email: "timothy@ribbon.finance",
        id: 6690972
    },
    6772497: {
        name: "Sahil",
        email: "sahil@ribbon.finance",
        id: 6772497
    },
    6755384: {
        name: "Tze",
        email: "tzehing@ribbon.finance",
        id: 6755384
    },
    6775621: {
        name: "Alex",
        email: "alex@ribbon.finance",
        id: 6775621
    },
    6756992: {
        name: "Steven",
        email: "steven@ribbon.finance",
        id: 6756992
    },
    6908501: {
        name: "Karon",
        email: "karon@ribbon.finance",
        id: 6908501
    },
    7643217: {
        name: "Leon",
        email: "leon@ribbon.finance",
        id: 7643217
    },
    6931580: {
        name: "Jeremy",
        email: "jeremy@ribbon.finance",
        id: 6931580
    },
    7084278: {
        name: "Ivy",
        email: "ivy@ribbon.finance",
        id: 7084278
    },
    6463204: {
        name: "Julian",
        email: "julian@ribbon.finance",
        id: 6463204
    },
    6464520: {
        name: "Bot",
        email: "operator+okozz78m@intercom.io",
        id: 6464520
    },

    6478308: {
        name: "Gabriel",
        email: "gabriel@ribbon.finance",
        id: 6478308
    },
    7448756: {
        name: "Helpdesk",
        email: "help@aevo.xyz",
        id: 7448756
    },

};
