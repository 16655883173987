import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Radio from "antd/es/radio";
import { Select as SharedSelect } from "../shared/Select";
import { SPACING } from "../../constants/design/spacing";

import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

interface ITableFilterDropdownProps {
  onClick: (type: "Limit" | "Date", value: number | Array<Date | null>) => void;
  overridePlaceholder?: string;
}

export enum TableFilterEnum {
  LIMIT = "Limit",
  DATE = "Date",
}

export function TableFilterDropdown({ onClick, overridePlaceholder }: ITableFilterDropdownProps) {
  const [option, setOption] = useState<TableFilterEnum>(TableFilterEnum.LIMIT);
  const [limit, setLimit] = useState<number>(500);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    onClick(option, option === TableFilterEnum.LIMIT ? limit : dateRange);
  }, [limit, dateRange, onClick, option]);

  return (
    <div style={{ display: "flex" }}>
      <Radio.Group value={option} onChange={(e) => setOption(e.target.value as TableFilterEnum)}>
        <Radio.Button value={TableFilterEnum.LIMIT}>Limit</Radio.Button>
        <Radio.Button value={TableFilterEnum.DATE}>Date Range</Radio.Button>
      </Radio.Group>

      {option === TableFilterEnum.LIMIT ? (
        <SharedSelect
          wrapperStyle={{ marginLeft: SPACING.two }}
          options={[
            {
              label: "50",
              isActive: limit === 50,
              onClick: () => setLimit(50),
            },
            {
              label: "100",
              isActive: limit === 100,
              onClick: () => setLimit(100),
            },
            {
              label: "250",
              isActive: limit === 250,
              onClick: () => setLimit(250),
            },
            {
              label: "500",
              isActive: limit === 500,
              onClick: () => setLimit(500),
            },
            {
              label: "1000",
              isActive: limit === 1000,
              onClick: () => setLimit(1000),
            },
            {
              label: "2500",
              isActive: limit === 2500,
              onClick: () => setLimit(2500),
            },
            {
              label: "5000",
              isActive: limit === 5000,
              onClick: () => setLimit(5000),
            },
          ]}
        />
      ) : (
        <div style={{ display: "flex", marginLeft: SPACING.two }}>
          <DatePicker
            selectsRange
            placeholderText="Select Date Range"
            dateFormat={"dd MMM yyyy"}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            onChange={(update) => setDateRange(update)}
            isClearable
          />
        </div>
      )}
    </div>
  );
}
