import { useCallback, useEffect, useMemo, useState } from "react";
import { WIDTHS } from "../../../constants/design/spacing";

const useScreenSize = () => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  // If medium screen, isMediumScreen will be true
  // If mobile screen, both isMediumScreen and isMobileScreen will be true
  const isMediumScreen = useMemo(
    () => width !== 0 && width <= WIDTHS.M,
    [width]
  );
  const isMobileScreen = useMemo(
    () => width !== 0 && width <= WIDTHS.S,
    [width]
  );

  const updateWidth = useCallback(() => {
    setWidth(Math.max(window.innerWidth, document.documentElement.clientWidth));
  }, []);

  const updateHeight = useCallback(() => {
    setHeight(
      Math.max(window.innerHeight, document.documentElement.clientHeight)
    );
  }, []);

  useEffect(() => {
    updateWidth();
    updateHeight();

    window.addEventListener(
      "resize",
      () => {
        updateWidth();
        updateHeight();
      },
      { passive: true }
    );
  }, [updateWidth, updateHeight]);

  return {
    height,
    width,
    isMediumScreen,
    isMobileScreen,
  };
};

export default useScreenSize;
