import { useMemo } from "react";
import { IKeyValue, KeyValue } from ".";
import { IAccount } from "../../../interfaces/aevo";
import { KeyValueContainer, StatTitle } from "./style";
import { SPACING } from "../../../constants/design/spacing";

interface IMMPStatsProps {
  data?: IAccount;
  isMobile?: boolean;
}

export function MMPStats({ data, isMobile }: IMMPStatsProps) {
  const stats: IKeyValue[][] = useMemo(() => {
    if (data === undefined) return [];

    return Object.entries(data.mmp).reduce((acc, [asset, mmp]) => {
      if (mmp.enabled) {
        acc.push([
          { title: "Asset", value: asset },
          { title: "Enabled", value: "TRUE" },
          {
            title: "Triggered",
            value: mmp.triggered ? "TRUE" : "FALSE",
          },
          {
            title: "Interval",
            value: mmp.interval ? mmp.interval.toString() : "0",
          },
          {
            title: "Frozen",
            value: mmp.frozen ? mmp.frozen.toString() : "0",
          },
          {
            title: "Amount Limit",
            value: mmp.amount_limit ? mmp.amount_limit.toString() : "0",
          },
          {
            title: "Delta Limit",
            value: mmp.delta_limit ? mmp.delta_limit.toString() : "0",
          },
          {
            title: "Amount Change",
            value: mmp.amount_change ? mmp.amount_change.toString() : "0",
          },
        ]);
      } else {
        acc.push([
          { title: "Asset", value: asset },
          { title: "Enabled", value: "FALSE" },
        ]);
      }
      return acc;
    }, [] as IKeyValue[][]);
  }, [data]);

  if (!data?.account) return null;
  return (
    <div>
      <StatTitle isMobile={isMobile}>Market Maker Protection</StatTitle>
      {stats.map((mmp, idx) => (
        <KeyValueContainer isMobile={isMobile} key={idx}>
          {mmp.map((mmpDetails, i) => (
            <KeyValue
              wrapperStyle={{ marginTop: i <= 1 && isMobile ? SPACING.two : 0 }}
              key={i}
              title={mmpDetails.title}
              value={mmpDetails.value}
            />
          ))}
        </KeyValueContainer>
      ))}
    </div>
  );
}
