import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";

export interface IAirtableRecord {
  [address: string]: string;
}

interface IAirtableTagsResponse {
  records: IAirtableRecord;
}

export const useAirtableTags = () => {
  const { queryConfig, tokenId } = useContext(AuthContext);

  const swr = useSWRImmutable<IAirtableTagsResponse, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ? [AdminEndpointEnum.ACCOUNT, tokenId] : undefined,
    async () => {
      const tags = await (
        await axios.get<IAirtableTagsResponse>(ADMIN_API_URL(AdminEndpointEnum.AIRTABLE_TAGS), {
          ...queryConfig(),
        })
      ).data;

      return tags;
    }
  );

  return {
    ...swr,
  };
};
