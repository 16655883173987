import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAccount, IAccountOrders } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useAccountOrders = (overrideAccount?: string) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const account = overrideAccount || address;
  const { addToast } = useToast();

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_ORDERS);
  const swr = useSWR<IAccountOrders, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountDetails = await axios.get<IAccountOrders>(endpoint, {
        ...queryConfig(),
        params: {
          account,
        },
      });

      return accountDetails.data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT],
    }
  );

  const cancelAllOrders = useCallback(
    async (selectedAccount: IAccount) => {
      try {
        if (tokenId) {
          return await await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.CANCEL_ALL),
              {
                account: selectedAccount.account,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(selectedAccount));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Orders Cancelled",
            subheader: "Account's orders are successfully cancelled",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Orders Cancellation Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  return {
    ...swr,
    cancelAllOrders,
  };
};
