import { TableOptions } from "react-table";
import { Align } from "./style";
import { ITableColumn } from "./TableColumn";

interface IDefaultCellForColumnProps extends TableOptions<{}> {
  column: ITableColumn<any>;
  cell: {
    value: any;
  };
}

export function DefaultCellForColumn({
  column,
  cell,
}: IDefaultCellForColumnProps) {
  const { align, valueExtractor } = column;

  return (
    <Align align={align || "left"}>
      {valueExtractor ? valueExtractor(cell.value) : cell.value}
    </Align>
  );
}
