import { AnimatePresence } from "framer-motion";
import {
  CloseButton,
  Header,
  StatLabel,
  StatValue,
  SubheaderWrapper,
  ToastHeaderWrapper,
  ToastInfoWrapper,
  ToastListWrapper,
  ToastWrapper,
} from "./style";
import success from "../../assets/svg/success.svg";
import error from "../../assets/svg/error.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { useToast, ToastEnum, ToastStatusEnum } from "../../hooks/toast";

export function Toast() {
  const { toasts, removeToast } = useToast();

  return (
    <ToastListWrapper>
      <AnimatePresence>
        {toasts.map((toast) => (
          <ToastWrapper
            key={toast.hash}
            initial={{
              opacity: 0,
            }}
            animate={{
              x: [100, 0, 0, 0],
              opacity: [0, 1, 1, 1],
            }}
            exit={{
              transition: {
                duration: 0.5,
              },
              x: toast.interval ? 100 : 0,
              opacity: toast.interval ? 0 : 1,
            }}
            transition={{
              duration: 1,
            }}
          >
            <ToastHeaderWrapper>
              <div>
                {toast.type !== ToastEnum.SIMPLE ? (
                  <img src={toast.icon} alt={toast.hash} />
                ) : (
                  <img
                    src={
                      toast.status === ToastStatusEnum.SUCCESS ? success : error
                    }
                    alt={toast.hash}
                  />
                )}
                <Header>
                  {toast.header}
                  <SubheaderWrapper>{toast.subheader}</SubheaderWrapper>
                </Header>
              </div>
              <CloseButton onClick={() => removeToast(toast.hash)}>
                <Close />
              </CloseButton>
            </ToastHeaderWrapper>
            {toast.type === ToastEnum.INFO && (
              <ToastInfoWrapper>
                {toast.stats.map((stat) => (
                  <div key={`${stat.label}-${stat.value}`}>
                    <StatLabel>{stat.label}</StatLabel>
                    <StatValue>{stat.value}</StatValue>
                  </div>
                ))}
              </ToastInfoWrapper>
            )}
          </ToastWrapper>
        ))}
      </AnimatePresence>
    </ToastListWrapper>
  );
}
