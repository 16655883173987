import { useMemo, useState } from "react";
import { IKeyValue, KeyValue } from ".";
import { useComputations } from "../../../hooks/useComputations";
import { IAccount } from "../../../interfaces/aevo";
import { KeyValueContainer, StatTitle } from "./style";
import { Select } from "../../shared/Select";
import { SPACING } from "../../../constants/design/spacing";
import { useDeribitAccount } from "../../../hooks/api/deribit/useDeribitAccount";

interface IMarginStatsProps {
  accountData?: IAccount;
  isMobile?: boolean;
}

export function MarginStats({ accountData, isMobile }: IMarginStatsProps) {
  const { data: deribitBTCData } = useDeribitAccount("BTC");
  const { data: deribitETHData } = useDeribitAccount("ETH");
  const { computeIM, computeMM } = useComputations();

  const aevoStats: IKeyValue[] = useMemo(
    () => [
      {
        title: "Aevo MM",
        value: computeMM(accountData?.equity, accountData?.maintenance_margin, true),
      },
      {
        title: "Aevo IM",
        value: computeIM(accountData?.equity, accountData?.initial_margin, accountData?.maintenance_margin, true),
      },
    ],
    [accountData?.equity, accountData?.initial_margin, accountData?.maintenance_margin, computeIM, computeMM]
  );

  const deribitBTCStats: IKeyValue[] = useMemo(() => {
    if (deribitBTCData) {
      return [
        {
          title: "Deribit MM (BTC)",
          value: computeMM(deribitBTCData?.equity?.toString(), deribitBTCData?.maintenance_margin?.toString()),
        },
        {
          title: "Deribit IM (BTC)",
          value: computeIM(
            deribitBTCData?.equity?.toString(),
            (Number(deribitBTCData?.initial_margin) - Number(deribitBTCData?.maintenance_margin)).toString(),
            deribitBTCData?.maintenance_margin?.toString()
          ),
        },
      ] as IKeyValue[];
    }

    return [];
  }, [computeIM, computeMM, deribitBTCData]);

  const deribitETHStats: IKeyValue[] = useMemo(() => {
    if (deribitETHData) {
      return [
        {
          title: "Deribit MM (ETH)",
          value: computeMM(deribitETHData?.equity?.toString(), deribitETHData?.maintenance_margin?.toString()),
        },
        {
          title: "Deribit IM (ETH)",
          value: computeIM(
            deribitETHData?.equity?.toString(),
            (Number(deribitETHData?.initial_margin) - Number(deribitETHData?.maintenance_margin)).toString(),
            deribitETHData?.maintenance_margin?.toString()
          ),
        },
      ] as IKeyValue[];
    }

    return [];
  }, [computeIM, computeMM, deribitETHData]);

  if (!accountData && !deribitBTCData) return null;
  return (
    <div>
      <StatTitle isMobile={isMobile}>Aevo Margin</StatTitle>
      <KeyValueContainer isMobile={isMobile}>
        {aevoStats.map((stat, i) => (
          <KeyValue key={i} title={stat.title} value={stat.value} />
        ))}
      </KeyValueContainer>

      {deribitBTCStats.length ? (
        <>
          <StatTitle isMobile={isMobile}>Deribit Margin (BTC)</StatTitle>
          <KeyValueContainer isMobile={isMobile}>
            {deribitBTCStats.map((stat, i) => (
              <KeyValue key={i} title={stat.title} value={stat.value} />
            ))}
          </KeyValueContainer>
        </>
      ) : null}

      {deribitETHStats.length ? (
        <>
          <StatTitle isMobile={isMobile}>Deribit Margin (ETH)</StatTitle>
          <KeyValueContainer isMobile={isMobile}>
            {deribitETHStats.map((stat, i) => (
              <KeyValue key={i} title={stat.title} value={stat.value} />
            ))}
          </KeyValueContainer>
        </>
      ) : null}
    </div>
  );
}
