import styled from "styled-components";
import { BACKGROUND_COLORS, COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";

// Action button used in a table row for cancelling an order, modifying a position, etc.
export const RowActionButtonWrapper = styled.button`
  width: ${SPACING.five}px;
  height: ${SPACING.five}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SPACING.two}px;
`;

export const ButtonWithBackground = styled(RowActionButtonWrapper)`
  background-color: ${BACKGROUND_COLORS.componentSolid};
  opacity: 0.64;
  &:hover {
    opacity: 1;
    svg {
      stroke: ${COLORS.blue.one};
    }
    background-color: ${COLORS.blue.four};
  }
`;
