import { useCallback, useState, useEffect } from "react";
import { StrategiesDropdown } from "../StrategiesDropdowns/StrategiesDropdown";
import { OptionsDropdown } from "../StrategiesDropdowns/OptionsDropdown";
import { useOptionsInstruments } from "../../hooks/api/mm/useOptionsInstruments";
import InstrumentChart from "../OptionsTrades/InstrumentChart";
import { useMMBybitAssets } from "../../utils/asset";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { FilterWrapper, TradesWrapper, ChartWrapper } from "./style";
import Checkbox from "../shared/Checkbox";
import { CheckboxContainer } from "../shared/Checkbox/style";
import StrategiesChart from "./StrategiesChart";
import { useMarkets } from "../../hooks/api/aevo/useMarkets";
import DatetimePicker from "../StrategiesDropdowns/DatetimePicker";
import { prelaunchAssets } from "./prelaunchAssets";

export function MMStrategies() {
  const [underlyingAsset, setAsset] = useState<string>();
  const [strategy, setStrategy] = useState<string>();
  const [fetchParams, setFetchParams] = useState<{
    strategy?: string;

  }>({});
  const [fetchKey, setFetchKey] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [shouldRender, setShouldRender] = useState(false); // Add this state variable
  const [showAllTrades, setShowAllTrades] = useState(false); // Add this state variable

  const handleFetch = useCallback(() => {
    setFetchParams({ strategy }); // Use property shorthand to set the strategy property
    setFetchKey((prevKey) => prevKey + 1);
    setShouldRender(true); // Set shouldRender to true when Fetch button is clicked
  }, [strategy]); // Add strategy as a dependency

  const handleDateChange = (startTimestamp: number, endTimestamp: number) => {
    const startDate = new Date(startTimestamp * 1000);
    const endDate = new Date(endTimestamp * 1000);
    setDateRange([startDate, endDate]);
  };

  const { data: fetchedData } = useOptionsInstruments(
    underlyingAsset ?? "",
    dateRange[0] ? String(Math.floor(dateRange[0].getTime() / 1000)) : "0",
    dateRange[1] ? String(Math.floor(dateRange[1].getTime() / 1000)) : String(Math.floor(Date.now() / 1000))
  );

  const data = underlyingAsset && dateRange[0] && dateRange[1] ? fetchedData ?? [] : [];

  const filteredData = data?.filter((item) => item.InstrumentName.slice(0, 3) === underlyingAsset?.slice(0, 3));
  const uniqueInstrumentNames = Array.from(new Set(filteredData?.map((item) => item.InstrumentName)));

  const markets = useMarkets();

  let assets;
  if (strategy === "PRELAUNCH") {
    assets = markets.data
    ?.filter((market) => market.pre_launch)
    .map((market) => market.underlying_asset) || [];
  } else {
    assets = strategy === "VERTEX" ? ["ETH", "BTC", "SOL"] : markets.data?.map((market) => market.underlying_asset);
  }

  const { data: bybitAssets } = useMMBybitAssets();
  return (
    <TradesWrapper>
      <FilterWrapper>
        <DatetimePicker onDateChange={(startTimestamp, endTimestamp) => handleDateChange(startTimestamp, endTimestamp)} overridePlaceholder="Select Date Range" />
        <StrategiesDropdown onClick={setStrategy} overridePlaceholder="Select Strategy" />
        {strategy === "OPTIONS" && <OptionsDropdown onClick={setAsset} overridePlaceholder="Select Asset" />}
        {strategy === "PRELAUNCH" && <CheckboxContainer>
          <Checkbox
            checked={showAllTrades}
            onToggle={() => setShowAllTrades(!showAllTrades)}
            title="All Trades"
          />
        </CheckboxContainer>}
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          disabled={!strategy}
          onClick={handleFetch}
          style={{ minHeight: 24, maxHeight: 24 }}
        >
          Fetch
        </Button>
      </FilterWrapper>
      {fetchParams.strategy && strategy !== "OPTIONS" && fetchKey > 0 && assets?.map((asset) => (
        <ChartWrapper key={`${fetchKey}-${asset}`}>
          <StrategiesChart
            key={`${fetchKey}-${asset}`}
            instrumentName={asset}
            startTime={dateRange[0] ? dateRange[0].getTime() / 1000 : 0}
            endTime={dateRange[1] ? dateRange[1].getTime() / 1000 : 0}
            strategy={strategy as string}
            bybitAssets={bybitAssets}
            showAllTrades={showAllTrades}
          /></ChartWrapper>
      ))}

      {fetchParams.strategy && underlyingAsset && strategy === "OPTIONS" && fetchKey > 0 && uniqueInstrumentNames.map((instrumentName) => (
        <ChartWrapper key={`${fetchKey}-${underlyingAsset}-${instrumentName}`}>
          <InstrumentChart
            key={`${fetchKey}-${underlyingAsset}-${instrumentName}`}
            instrumentName={instrumentName}
            startTime={dateRange[0] ? dateRange[0].getTime() / 1000 : 0}
            endTime={dateRange[1] ? dateRange[1].getTime() / 1000 : 0}
          />
        </ChartWrapper>
      ))}
    </TradesWrapper>
  );
}
