import { SpinnerBar, SpinnerWrapper } from "./style";

interface ISpinnerProps {
  theme?: "blue" | "green" | "red" | "orange";
  color?: string;
  isLoading?: boolean;
}

export function Spinner({ theme = "blue", color, isLoading = true }: ISpinnerProps) {
  return (
    <SpinnerWrapper theme={theme} color={color} isLoading={isLoading}>
      <SpinnerBar delay={0.3} />
      <SpinnerBar delay={0.5} />
      <SpinnerBar delay={0.7} />
    </SpinnerWrapper>
  );
}
