import { motion } from "framer-motion";
import styled from "styled-components";
import {
  TEXT_COLORS,
  BORDER_COLORS,
  MODAL_COLORS,
} from "../../../constants/design/colors";
import {
  SPACING,
  COMPONENTS,
  BORDER_RADIUS,
} from "../../../constants/design/spacing";
import { FONT_CSS } from "../../../constants/design/fontSize";

export const ModalWrapper = styled.div<{ show?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
`;

export const ModalBody = styled(motion.div)`
  position: relative;
`;

export const ModalContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  color: ${TEXT_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: ${BORDER_RADIUS}px;
  background: ${MODAL_COLORS.one};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const Content = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ noPadding }) => (noPadding ? 0 : `${SPACING.three}px`)};
`;

export const ModalHeader = styled.div`
  height: ${COMPONENTS.modalHeaderHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${BORDER_COLORS.one};
`;

export const CloseButton = styled.button`
  height: ${COMPONENTS.modalHeaderHeight}px;
  width: ${COMPONENTS.modalHeaderHeight}px;
  border-left: 1px solid ${BORDER_COLORS.one};
`;

export const Title = styled(motion.div)`
  ${FONT_CSS.header.six}
  padding-left: ${SPACING.three}px;
  text-transform: capitalize;
`;
