/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import currency from "currency.js";
import { IStrategiesPosition } from "../../../hooks/api/admin/useAccountStrategies";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { Align, MinifiedTableWrapper, TableLoader, TableHeaderCell } from "../../Table/style";
import { ITableColumn } from "../../Table/TableColumn";
import CollapseHeaderCount from "../../TableCollapse";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { STRATEGY_ADDRESSES_TO_NAME } from "../../../constants/strategies";

interface IStrategiesTableProps {
  strategy_positions?: IStrategiesPosition[] | undefined;
  isLoading?: boolean;
  name: string;
}

export function StrategiesTable({ strategy_positions, isLoading, name }: IStrategiesTableProps) {
  const memoizedStrategies: IStrategiesPosition[] = useMemo(() => strategy_positions || [], [strategy_positions]);

  const columns: ITableColumn<IStrategiesPosition>[] = useMemo(
    () => [
      {
        title: "Strategy",
        align: "left",
        accessor: "strategy_address",
        valueExtractor: (value) => STRATEGY_ADDRESSES_TO_NAME[value],
      },
      {
        title: "Collateral Asset",
        align: "left",
        accessor: "collateral_asset",
      },
      {
        title: "Shares",
        align: "right",
        accessor: "shares",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "PPS",
        align: "right",
        accessor: "pps",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "Value",
        align: "right",
        id: "usd_value",
        accessor: (row) => currency(Number(row.shares) * Number(row.pps)).format(),
      },
      {
        title: "ROI",
        align: "right",
        accessor: "roi",
        Cell: ({ value }) => {
          const roiValue = parseFloat(value);
          const formattedROI = `${roiValue.toFixed(4)}%`;
          return (
            <Align
              align="right"
              style={{
                color:
                  parseFloat(value) > 0 ? COLORS.green.one : parseFloat(value) < 0 ? COLORS.red.one : TEXT_COLORS.two,
              }}
            >
              {roiValue > 0 ? "+" : ""}
              {formattedROI}
            </Align>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedStrategies,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedStrategies.length} showBadge title={name} />,
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
