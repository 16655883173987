import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";

export const CSVLinkWrapper = styled.button`
  a {
    color: ${COLORS.blue.one} !important;
    text-decoration: none;
    font-size: ${FONT_SIZE.one};
    cursor: pointer;
    &:hover {
      color: ${COLORS.blue.one} !important;
    }
  }

  a[aria-disabled="true"] {
    &:hover {
      cursor: not-allowed;
      color: ${TEXT_COLORS.three} !important;
    }
  }
`;
