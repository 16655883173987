/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { IAggregatedPosition } from "../../interfaces/positions";

export const aggregatePositions = (
  positions: IAggregatedPosition[]
): IAggregatedPosition[] => {
  const mapper: { [key: string]: IAggregatedPosition } = {};
  positions.forEach((position) => {
    const { instrument_name, contracts } = position;
    if (mapper[instrument_name]) {
      if (position.side === "buy") {
        mapper[instrument_name].theta -= position.theta;
      } else {
        mapper[instrument_name].theta += position.theta;
      }

      mapper[instrument_name].contracts += contracts || 0;
      mapper[instrument_name].delta += position.delta;
      mapper[instrument_name].gamma += position.gamma;
      mapper[instrument_name].vega += position.vega;
      mapper[instrument_name].pnl += position.pnl || 0;
      mapper[instrument_name].side = contracts > 0 ? "buy" : "sell";
    } else {
      mapper[instrument_name] = { ...position };
    }
  });

  return Object.values(mapper);
};
