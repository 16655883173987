import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AevoLogo } from "../../assets/logo/logo_text_white.svg";
import { AuthContext } from "../../context/AuthContext";
import { BACKGROUND_COLORS } from "../../constants/design/colors";

const LoginWrapper = styled.div`
  margin: auto;
  height: 100vh;
  display: flex;
`;
const LoginBox = styled.div`
  width: 220px;
  margin: auto;
  text-align: center;
  border-radius: 6px;
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  iframe {
    margin: auto !important;
  }

  * {
    background-color: ${BACKGROUND_COLORS.one} !important;
  }
`;

// Implemented following this guide: https://blog.logrocket.com/guide-adding-google-login-react-app/

export function Login() {
  const { tokenId, setTokenId, authenticate, redirectURL, setRedirectURL } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tokenId) {
      navigate(redirectURL ?? "/");
    }
  }, [navigate, redirectURL, tokenId]);

  useEffect(() => {
    if (location.pathname.length > 1 && location.pathname !== "/") {
      setRedirectURL(`${location.pathname}${location.search ?? ""}`);
    }
  }, [location.pathname, location.search, setRedirectURL]);

  const onAuthenticate = useCallback(
    (response: CredentialResponse) => {
      authenticate(response.credential);
    },
    [authenticate]
  );

  return (
    <LoginWrapper>
      <LoginBox>
        <AevoLogo height={48} width={160} />
        <p>Admin Dashboard</p>
        <StyledGoogleLogin
          useOneTap
          theme="filled_black"
          onSuccess={(response) => onAuthenticate(response)}
          onError={() => setTokenId(undefined)}
          type="standard"
          width="220px"
        />
      </LoginBox>
    </LoginWrapper>
  );
}
