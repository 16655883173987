import { useLocation, useNavigate } from "react-router-dom";
import { PageEndpointEnum } from "../../../enums/endpoints";
import { MobileSelectWrapper } from "./style";
import { Select } from "../Select";

export function MobileDirectory() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <MobileSelectWrapper>
      <Select
        options={[
          {
            label: "Dashboard",
            isActive: pathname === "/",
            onClick: () => navigate("/"),
          },
          {
            label: "Withdrawals",
            isActive: pathname === PageEndpointEnum.WITHDRAW,
            onClick: () => navigate(PageEndpointEnum.WITHDRAW),
          },
          {
            label: "Transactions",
            isActive: pathname === PageEndpointEnum.TX,
            onClick: () => navigate(PageEndpointEnum.TX),
          },
        ]}
      />
    </MobileSelectWrapper>
  );
}
