import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { DeribitEndpointEnum } from "../../../enums/endpoints";
import { IDeribitPosition } from "../../../interfaces/deribit";
import { DERIBIT_API_URL } from "../../../utils/env";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";
import { deribitAddresses } from "../../../constants/admin";

interface IDeribitResponse {
  message: string;
  success: boolean;
  data: IDeribitPosition[];
  asset: string;
}

export const useDeribitPositions = (asset?: string, overrideAccount?: string) => {
  const { tokenId } = useContext(AuthContext);
  const { address } = useContext(DataContext);
  const account = overrideAccount || address;

  const swr = useSWR<IDeribitPosition[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account && asset && deribitAddresses.includes(account)
      ? [DERIBIT_API_URL(DeribitEndpointEnum.ACCOUNT_POSITIONS), tokenId, account, asset]
      : undefined,
    async () => {
      const accountSummary = await axios.get<IDeribitResponse>(DERIBIT_API_URL(DeribitEndpointEnum.ACCOUNT_POSITIONS), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${tokenId}`,
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          account,
          currency: asset,
        },
      });

      // TODO: This is a hack to fix the amount
      const positions = accountSummary.data.data as IDeribitPosition[];

      positions.map((data) => {
        if ((data.instrument_name ?? "").includes("PERPETUAL")) {
          // eslint-disable-next-line no-param-reassign
          data.size = data.size_currency;
        }
        return data;
      });

      return positions
        .sort((a, b) => Number(b.delta) - Number(a.delta))
        .filter((pos) => retrieveAssetByInstrumentName(pos.instrument_name ?? "") === asset);
    },
    {
      refreshInterval: pollingInterval[DeribitEndpointEnum.ACCOUNT_POSITIONS],
    }
  );

  return {
    ...swr,
  };
};
