import moment from "moment";
import { useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { ITPSLHistory } from "../../../interfaces/aevo";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { ActionWrapper, Align, MinifiedTableWrapper, TableHeaderCell, TableLoader } from "../../Table/style";
import { MarketDropdown } from "../../Dropdown/MarketDropdown";
import { useTPSLHistory } from "../../../hooks/api/admin/useTPSLHistory";
import CollapseHeaderCount from "../../TableCollapse";

export function TPSLHistoryTable() {
  const [selectedAsset, setAsset] = useState<string | undefined>(undefined);
  const { data, isValidating } = useTPSLHistory();

  const memoizedOpenOrders = useMemo(() => {
    if (!selectedAsset) return data || [];

    return (data || []).filter((order) => retrieveAssetByInstrumentName(order.instrument_name) === selectedAsset);
  }, [data, selectedAsset]);

  const columns: ITableColumn<ITPSLHistory>[] = useMemo(
    () => [
      {
        title: "Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Created On",
        align: "left",
        accessor: "created_timestamp",
        valueExtractor: (value) =>
          moment(value / 1e6)
            .format("DD MMM YYYY HH:mm:ss")
            .toString(),
      },
      {
        title: "Side",
        align: "left",
        accessor: "side",
        Cell: ({ value }) => (
          <Align
            align="left"
            style={
              value
                ? {
                    color: value === "buy" ? COLORS.green.one : COLORS.red.one,
                  }
                : undefined
            }
          >
            {value}
          </Align>
        ),
      },
      {
        title: "Stop Type",
        align: "left",
        accessor: "stop_type",
        // eslint-disable-next-line no-nested-ternary
        valueExtractor: (value) => (value ? (value === "TAKE_PROFIT" ? "Take Profit" : "Stop Loss") : "-"),
      },
      {
        title: "Trigger Price",
        align: "right",
        accessor: "trigger",
        valueExtractor: (value) => `$${value}`,
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "mark_price",
        valueExtractor: (value) => `$${value / 1e6}`,
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "Filled",
        align: "right",
        accessor: "filled",
        Cell: ({ row, value }) => (
          <Align
            align="right"
            style={{
              color: parseFloat(value) > 0 ? TEXT_COLORS.one : TEXT_COLORS.three,
            }}
          >
            {parseFloat(value).toFixed(2)} ({((parseFloat(value) / row.values.amount) * 100).toFixed(2)}%)
          </Align>
        ),
      },
      // {
      //   title: "IM",
      //   align: "right",
      //   accessor: "initial_margin",
      //   Cell: ({ value }) => (
      //     <Align
      //       align="right"
      //       style={{
      //         color: parseFloat(value) > 0 ? TEXT_COLORS.one : TEXT_COLORS.three,
      //       }}
      //     >
      //       {currency(value).format()}
      //     </Align>
      //   ),
      // },
      {
        title: "ID",
        align: "left",
        accessor: "order_id",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedOpenOrders,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <Collapse
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedOpenOrders.length} showBadge title="TPSL History" />,
          children: (
            <>
              <ActionWrapper>
                <MarketDropdown onClick={setAsset} overridePlaceholder="All TPSL Assets" />
              </ActionWrapper>
              <MinifiedTableWrapper>
                <TableLoader isLoading={!data && isValidating} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableHeaderCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{ width: column.width }}
                          >
                            {column.render("Header")}
                          </TableHeaderCell>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      const { ...rowProps } = row.getRowProps();
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...rowProps}>
                          {row.cells.map((cell) => (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </MinifiedTableWrapper>
            </>
          ),
        },
      ]}
    />
  );
}
