import { useMemo, useState } from "react";
import { Trade } from "./Trade";
import { ISelectOption, Select } from "../shared/Select";
import { Orderbook } from "./Orderbook";
import {
  ContentWrapper,
  DebugWrapper,
  OptionsWrapper,
  Padding,
  SelectWrapper,
} from "./style";
import { Sequence } from "./Sequence";
import { LiquidationPlans } from "./LiquidationPlans";

enum DebugEnum {
  TRADE,
  ORDERBOOK,
  SEQUENCE,
  LIQUIDATION_PLANS,
}

export function Debug() {
  const [debugOption, setDebugOption] = useState<DebugEnum>(DebugEnum.TRADE);

  const options: ISelectOption[] = useMemo(
    () => [
      {
        label: "Trades by ID",
        isActive: debugOption === DebugEnum.TRADE,
        onClick: () => setDebugOption(DebugEnum.TRADE),
      },
      {
        label: "Orderbook by Trade ID",
        isActive: debugOption === DebugEnum.ORDERBOOK,
        onClick: () => setDebugOption(DebugEnum.ORDERBOOK),
      },
      {
        label: "Orderbook by Seq. No.",
        isActive: debugOption === DebugEnum.SEQUENCE,
        onClick: () => setDebugOption(DebugEnum.SEQUENCE),
      },
      {
        label: "Liquidation Plans by Account",
        isActive: debugOption === DebugEnum.LIQUIDATION_PLANS,
        onClick: () => setDebugOption(DebugEnum.LIQUIDATION_PLANS),
      },
    ],
    [debugOption]
  );

  const pageContent = useMemo(() => {
    if (debugOption === DebugEnum.TRADE) {
      return <Trade />;
    }

    if (debugOption === DebugEnum.SEQUENCE) {
      return <Sequence />;
    }

    if (debugOption === DebugEnum.LIQUIDATION_PLANS) {
      return <LiquidationPlans />;
    }

    return <Orderbook />;
  }, [debugOption]);

  return (
    <DebugWrapper>
      <Padding>
        <OptionsWrapper>
          <SelectWrapper>
            <Select options={options} />
          </SelectWrapper>
        </OptionsWrapper>
        <ContentWrapper>{pageContent}</ContentWrapper>
      </Padding>
    </DebugWrapper>
  );
}
