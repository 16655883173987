import { CSSProperties } from "styled-components";
import { OptionWrapper, SelectButton, SelectWrapper, Tag } from "./style";

export interface ISelectOption {
  label: string;
  onClick?: () => void;
  isActive: boolean;
  // Highlighted text
  isHighlighted?: boolean;
  prefixes?: JSX.Element | JSX.Element[];
  css?: any;
  additionalTags?: string[];
}

export interface ISelectProps {
  options: ISelectOption[];
  wrapperStyle?: CSSProperties;
  isRound?: boolean;
  isDisabled?: boolean;
  buttonSpacing?: number;
  theme?: "blue" | "purple";
}

export function Select({
  options,
  wrapperStyle,
  isRound,
  isDisabled = false,
  theme = "blue",
  buttonSpacing,
}: ISelectProps) {
  return (
    <SelectWrapper style={wrapperStyle}>
      {options.map((option) => (
        <OptionWrapper key={option.label} spacing={buttonSpacing}>
          <SelectButton
            theme={theme}
            disabled={isDisabled}
            css={option.css}
            onClick={() => !isDisabled && option.onClick?.()}
            isActive={option.isActive}
            isHighlighted={option.isHighlighted}
            isRound={isRound}
          >
            {option.prefixes}
            {option.label}
            {option.additionalTags &&
              option.additionalTags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
          </SelectButton>
        </OptionWrapper>
      ))}
    </SelectWrapper>
  );
}
