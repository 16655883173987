import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { BinanceEndpointEnum } from "../../../enums/endpoints";
import { DataContext } from "../../../context/DataContext";
import { BINANCE_API_URL } from "../../../utils/env";
import { binanceAddresses } from "../../../constants/admin";
import { IBinancePosition } from "../../../interfaces/binance";

export const useBinancePositions = (overrideAccount?: string) => {
  const { tokenId } = useContext(AuthContext);
  const { address } = useContext(DataContext);
  const account = overrideAccount || address;

  const swr = useSWR<IBinancePosition[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account && binanceAddresses.includes(account) ?
      [
        BINANCE_API_URL(BinanceEndpointEnum.ACCOUNT_POSITIONS),
        tokenId,
        account,
      ] :
      undefined,
    async () => {
      try {
        const accountSummary = await axios.get<any>(
          BINANCE_API_URL(BinanceEndpointEnum.ACCOUNT_POSITIONS),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
            params: {
              account,
            },
          }
        );

        return accountSummary.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    {
      refreshInterval: pollingInterval[BinanceEndpointEnum.ACCOUNT_POSITIONS],
    }
  );

  return {
    ...swr,
  };
};
