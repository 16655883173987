import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { MMTradesEndpointEnum } from "../../../enums/endpoints";
import { MMTRADES_API_URL } from "../../../utils/env";

export interface IFormattedInstruments {

    InstrumentName: string;

}

export const useOptionsInstruments = (asset: string, start_time: string, end_time?: string) => {
  const instrument_name = `${asset}`;
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IFormattedInstruments[] | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData(undefined); // Reset data to undefined when instrument_name changes
    setIsLoading(true);

    const fetchMarkHistory = async () => {
      const params: { [key: string]: any } = { start_time };
      params.start_time = start_time;
      params.end_time = end_time;
      if (instrument_name) params.asset = instrument_name;
      try {
        const response = await axios.get<any>(
          MMTRADES_API_URL(MMTradesEndpointEnum.OPTIONS_INSTRUMENTS),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
            params,
          }
        );
        const tradeInstruments = response.data.data;

        const formattedRows = tradeInstruments.map((item: any) => ({

          InstrumentName: item.InstrumentName,

        }));

        setData(formattedRows as IFormattedInstruments[]);
      } catch (error) {
        // Handle error (optional)
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId && asset) {
      fetchMarkHistory();
    }
  }, [tokenId, start_time, asset]);

  return { data, isLoading };
};
