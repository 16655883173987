import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { AirtableEndpointEnum } from "../../../enums/endpoints";
import { AIRTABLE_API_URL } from "../../../utils/env";
import { INote } from "../../../interfaces/note";

export const useNotes = () => {
    const { tokenId } = useContext(AuthContext);

    const swr = useSWR<INote[], AxiosError>(
        // If tokenId & account are not available, the request will not fetch
        tokenId ?
            [AIRTABLE_API_URL(AirtableEndpointEnum.FLAGGED_ACCOUNT_NOTES), tokenId] :
            undefined,
        async () => {
            try {
                const notes = await axios.get<any>(
                    AIRTABLE_API_URL(AirtableEndpointEnum.FLAGGED_ACCOUNT_NOTES),
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${tokenId}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                );

                return notes.data.data;
            } catch (error) {
                console.error(error);
            }
        }
    );

    return {
        ...swr,
    };
};
