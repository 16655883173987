/* eslint-disable no-nested-ternary */
import currency from "currency.js";

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import Flex from "antd/es/flex";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { initialMarginUtilization, maintenanceMarginUtilization, utilizationColor } from "../../utils/math";
import { formatAmount, shortenAddress } from "../../utils/strings";
import ConfirmationModal from "../shared/ConfirmationModal";
import RowActionButton from "../shared/RowActionButton";
import { DefaultCellForColumn } from "../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../Table/DefaultHeaderForColumn";
import { Align, CopyButton, DashboardRow, TableHeaderCell, TableWrapper, TitleWrapper } from "../Table/style";
import { ITableColumn } from "../Table/TableColumn";
import {
  AccountButton,
  ActionWrapper,
  FilterCol,
  GroupedCells,
  LinkText,
  PaginationCol,
  HeaderRow,
  QuickAddressTag,
  CapacityWrapper,
  StatWrapper,
  StatTitleWrapper,
} from "./style";
import { AccountTypeEnum, IAccount } from "../../interfaces/aevo";
import { DataContext } from "../../context/DataContext";
import { useAccount } from "../../hooks/api/admin/useAccount";
import { Spinner } from "../shared/Spinner";
import { ReactComponent as Copy } from "../../assets/svg/copy.svg";
import { ToastEnum, ToastStatusEnum, useToast } from "../../hooks/toast";
import { AccountFilter } from "../Table/AccountFilter";
import arrow from "../../assets/svg/arrow-up-right.svg";
import {
  AEUSD_VAULT_ADDRESS,
  DISTRIBUTOR_ADDRESS,
  INSURANCE_FUND_ADDRESS,
  INTERNAL_OPTIONS_ADDRESS,
  INTERNAL_OPTIONS_DAILIES_ADDRESS,
  INTERNAL_PERPS_LONG_ADDRESS,
  INTERNAL_PERPS_SHORT_ADDRESS,
  INTERNAL_PRELAUNCH_2_ADDRESS,
  INTERNAL_PRELAUNCH_ADDRESS,
  LIQUIDATION_REFUNDER_ADDRESS,
  OBADJE_OPTIONS_ADDRESS,
  SELLER_ADDRESS,
  SPOT_COUNTERPARTY_ADDRESS,
} from "../../constants/admin";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { IconWrapper } from "../Intercom/style";
import { SPACING } from "../../constants/design/spacing";
import { generateSeedWithAddress } from "../../utils/icon";
import { useManualWithdrawals } from "../../hooks/api/admin/useManualWithdrawals";
import { FONT_SIZE } from "../../constants/design/fontSize";

// Needed because /accounts endpoint is returning "market_maker" but to edit the account, we need to pass in 0, 1, 2 or 3
export const accountTypeMapping: { [key: string]: AccountTypeEnum } = {
  STANDARD: AccountTypeEnum.STANDARD,
  MARKET_MAKER: AccountTypeEnum.MARKET_MAKER,
  INSURANCE_FUND: AccountTypeEnum.INSURANCE_FUND,
  MANAGED_ACCOUNT: AccountTypeEnum.MANAGED_ACCOUNT,
};

export enum QuickAddressEnum {
  INSURANCE_FUND = "Insurance Fund",
  SPOT = "Spot Counterparty",
  SELLER = "Seller",
  INTERNAL_PERPS_SHORT = "Perp MM 1",
  INTERNAL_PERPS_LONG = "Perp MM 2",
  INTERNAL_OPTIONS = "Internal Options",
  INTERNAL_OPTIONS_DAILIES = "Options (Dailies)",
  INTERNAL_PRELAUNCH = "Pre-Launch",
  INTERNAL_PRELAUNCH_2 = "Pre-Launch 2",
  OBADJE_OPTIONS = "Obadje Options",
  DISTRIBUTOR = "Distributor",
  LIQUIDATION_REFUNDER = "Liquidation Refunder",
}

export const quickAddresses: Record<QuickAddressEnum, string> = {
  [QuickAddressEnum.INSURANCE_FUND]: INSURANCE_FUND_ADDRESS,
  [QuickAddressEnum.SPOT]: SPOT_COUNTERPARTY_ADDRESS,
  [QuickAddressEnum.INTERNAL_OPTIONS]: INTERNAL_OPTIONS_ADDRESS,
  [QuickAddressEnum.INTERNAL_PERPS_SHORT]: INTERNAL_PERPS_SHORT_ADDRESS,
  [QuickAddressEnum.INTERNAL_PERPS_LONG]: INTERNAL_PERPS_LONG_ADDRESS,
  [QuickAddressEnum.INTERNAL_OPTIONS_DAILIES]: INTERNAL_OPTIONS_DAILIES_ADDRESS,
  [QuickAddressEnum.INTERNAL_PRELAUNCH]: INTERNAL_PRELAUNCH_ADDRESS,
  [QuickAddressEnum.INTERNAL_PRELAUNCH_2]: INTERNAL_PRELAUNCH_2_ADDRESS,
  [QuickAddressEnum.SELLER]: SELLER_ADDRESS,
  [QuickAddressEnum.OBADJE_OPTIONS]: OBADJE_OPTIONS_ADDRESS,
  [QuickAddressEnum.DISTRIBUTOR]: DISTRIBUTOR_ADDRESS,
  [QuickAddressEnum.LIQUIDATION_REFUNDER]: LIQUIDATION_REFUNDER_ADDRESS,
};

interface IVaultCapacity {
  usdc_balance: number;
  total_balance: number;
  percentage: number;
}

interface VaultCapacityProps {
  percentage: number;
  usdc_balance: number;
  total_balance: number;
}

function VaultCapacity({ percentage, usdc_balance, total_balance }: VaultCapacityProps) {
  const barColor =
    percentage === 0
      ? COLORS.purple.two
      : percentage < 5
      ? COLORS.red.one
      : percentage < 6
      ? COLORS.orange.one
      : COLORS.purple.two;

  return (
    <StatWrapper>
      <div>
        <TitleWrapper>
          <span>aeUSD Vault USDC Buffer</span>
        </TitleWrapper>
        <TitleWrapper>
          <span
            style={{
              marginLeft: SPACING.five,
              color: percentage >= 6 ? COLORS.highlight.one : barColor,
            }}
          >
            {percentage.toFixed(2)}%
          </span>
        </TitleWrapper>
      </div>
      <LoadingBar color={barColor} />
      <CapacityWrapper>
        <div>
          <span>USDC</span>
          <span>TVL</span>
        </div>
        <div>
          <span style={{ color: barColor === COLORS.purple.two ? COLORS.highlight.one : barColor }}>
            ${formatAmount(usdc_balance)}
          </span>
          <span style={{ color: TEXT_COLORS.two }}>${formatAmount(total_balance)}</span>
        </div>
      </CapacityWrapper>
    </StatWrapper>
  );
}

interface SpotBalanceProps {
  asset: string;
  balance: number;
  withdrawals: number;
}

function SpotBalance({ asset, balance, withdrawals }: SpotBalanceProps) {
  const percentage = balance > 0 ? (withdrawals / balance) * 100 : 0;
  const barColor = percentage >= 100 ? COLORS.red.one : percentage >= 80 ? COLORS.orange.one : COLORS.purple.two;

  return (
    <StatWrapper>
      <StatTitleWrapper>
        <TitleWrapper>Spot Ctpty. Balance</TitleWrapper>
        <span style={{ margin: "auto 0" }}>{asset}</span>
      </StatTitleWrapper>
      <LoadingBar color={barColor} />
      <CapacityWrapper>
        <div>
          <span>Balance</span>
          <span style={{ marginLeft: SPACING.five }}>Withdrawals</span>
        </div>
        <div>
          <span
            style={{
              color: barColor === COLORS.purple.two ? COLORS.highlight.one : barColor,
            }}
          >
            {currency(balance).format({ symbol: "" })} {asset}
          </span>
          <span style={{ color: withdrawals > 0 ? TEXT_COLORS.one : TEXT_COLORS.three }}>
            {withdrawals > 0 ? `${currency(withdrawals).format({ symbol: "" })} ${asset}` : "---"}
          </span>
        </div>
      </CapacityWrapper>
    </StatWrapper>
  );
}

function LoadingBar({ color }: { color: string }) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: COLORS.purple.one,
        height: 2,
        margin: `${SPACING.two}px 0`,
      }}
    >
      <div style={{ backgroundColor: color, height: "100%", borderRadius: 6 }} />
    </div>
  );
}

export function Dashboard() {
  const { accounts, pagination, setPagination, size, totalAccounts, isLoading, records } = useContext(DataContext);
  const [show, setShow] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccount>();
  const { updateAccount } = useAccount();
  const { setAddress, clearFilter } = useContext(DataContext);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data: aeUSDVaultData } = useAccount(AEUSD_VAULT_ADDRESS);
  const { data: counterpartyData } = useAccount(SPOT_COUNTERPARTY_ADDRESS);
  const { data: withdrawalData } = useManualWithdrawals();

  const vaultCapacity: IVaultCapacity = useMemo(() => {
    if (aeUSDVaultData) {
      const USDC =
        Number(
          aeUSDVaultData.collaterals.find((collateral) => collateral.collateral_asset === "USDC")?.withdrawable_balance
        ) || 0;

      const nonUSDC = aeUSDVaultData.collaterals
        .filter((collateral) => collateral.collateral_asset !== "USDC")
        .reduce((acc, curr) => acc + Number(curr.withdrawable_balance), 0);

      return {
        usdc_balance: USDC,
        total_balance: USDC + nonUSDC,
        percentage: (Number(USDC) / (Number(USDC) + nonUSDC)) * 100,
      };
    }

    return {
      usdc_balance: 0,
      total_balance: 0,
      percentage: 0,
    };
  }, [aeUSDVaultData]);

  const spotCounterpartyAssets = useCallback(
    (asset: string) => {
      if (counterpartyData) {
        return (
          Number(
            counterpartyData.collaterals.find((collateral) => collateral.collateral_asset === asset)
              ?.withdrawable_balance
          ) || 0
        );
      }

      return 0;
    },
    [counterpartyData]
  );

  const withdrawalTotal = useCallback(
    (asset: string) => {
      if (withdrawalData) {
        // Reduce total withdrawals of the asset
        return withdrawalData
          .filter((withdrawal) => withdrawal.collateral === asset)
          .reduce((acc, curr) => acc + Number(curr.amount), 0);
      }

      return 0;
    },
    [withdrawalData]
  );

  const tableRef = useRef<HTMLDivElement>(null);

  // When landing on the dashboard, the selected address from URL param should be cleared to disable Deribit queries
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setAddress("");
    clearFilter();
  }, [setAddress, clearFilter]);

  const memoizedAccounts = useMemo(() => accounts || [], [accounts]);

  const lastPage = useMemo(() => Math.ceil(totalAccounts / size), [totalAccounts, size]);

  const onPrevPage = useCallback(() => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  }, [pagination, setPagination]);

  const onNextPage = useCallback(() => {
    if (pagination < lastPage) {
      setPagination(pagination + 1);
    }
  }, [lastPage, pagination, setPagination]);

  // Calculate the start and end indices for the current page
  const startIndex = useMemo(() => (pagination - 1) * size + 1, [pagination, size]);

  const endIndex = useMemo(() => pagination * size, [pagination, size]);

  // Determine the format for displaying the range
  const displayRange = useMemo(
    () =>
      `${currency(startIndex, { precision: 0 }).format({ symbol: "" })} - ${currency(
        Math.min(endIndex, totalAccounts),
        { precision: 0 }
      ).format({ symbol: "" })}`,
    [endIndex, startIndex, totalAccounts]
  );

  const onCopy = useCallback(
    (address: string) => {
      navigator.clipboard.writeText(address);
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Address Copied",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    },
    [addToast]
  );

  const getAirtableTag = useCallback((address: string) => records[address] || undefined, [records]);

  const columns: ITableColumn<IAccount>[] = useMemo(
    () => [
      {
        id: "address",
        title: "Account Address",
        align: "left",
        accessor: "account",
        Cell: ({ row }: any) => (
          <div style={{ display: "flex" }}>
            <CopyButton
              onClick={(e) => {
                e.stopPropagation();
                onCopy(row.original.account);
              }}
            >
              <Copy />
            </CopyButton>
            <LinkText
              style={{
                color: records[row.original.account] ? COLORS.blue.one : TEXT_COLORS.one,
              }}
            >
              <div>{records[row.original.account] ? records[row.original.account] : row.original.account}</div>
              <div style={{ color: records[row.original.account] ? COLORS.highlight.two : COLORS.neutral.three }}>
                {row.original.username}
              </div>
            </LinkText>
          </div>
        ),
      },
      {
        title: "Account Type",
        align: "left",
        accessor: "account_type",
        valueExtractor: (value: string) => accountTypeMapping[value],
      },
      {
        title: "Equity",
        align: "right",
        accessor: "equity",
        valueExtractor: (value) => currency(value).format(),
      },
      {
        title: "IM%",
        align: "right",
        id: "IM%",
        Cell: ({ row }: any) => {
          const percentage = initialMarginUtilization(
            Number(row.original.equity),
            Number(row.original.initial_margin),
            Number(row.original.maintenance_margin)
          ).toFixed(1);
          return (
            <Align align="right" style={{ color: utilizationColor(Number(percentage)) }}>
              {percentage}%
            </Align>
          );
        },
        canSort: true,
        sortType: (a, b) => {
          const percentageA = initialMarginUtilization(
            Number(a.original.equity),
            Number(a.original.initial_margin),
            Number(a.original.maintenance_margin)
          );

          const percentageB = initialMarginUtilization(
            Number(b.original.equity),
            Number(b.original.initial_margin),
            Number(b.original.maintenance_margin)
          );

          if (percentageA > percentageB) return 1;
          if (percentageA < percentageB) return -1;
          return 0;
        },
      },
      {
        title: "IM",
        align: "right",
        accessor: "initial_margin",
        Cell: ({ row, value }: any) => {
          const percentage = initialMarginUtilization(
            Number(row.original.equity),
            Number(row.original.initial_margin),
            Number(row.original.maintenance_margin)
          ).toFixed(1);
          return (
            <Align
              align="right"
              style={{
                color: value < 0.01 ? TEXT_COLORS.three : utilizationColor(Number(percentage)),
              }}
            >
              {currency(value).format()}
            </Align>
          );
        },
      },
      {
        title: "MM%",
        align: "right",
        id: "MM%",
        Cell: ({ row }: any) => {
          const percentage = maintenanceMarginUtilization(
            Number(row.original.equity),
            Number(row.original.maintenance_margin)
          ).toFixed(1);
          return (
            <Align align="right" style={{ color: utilizationColor(Number(percentage)) }}>
              {percentage}%
            </Align>
          );
        },
        canSort: true,
        sortType: (a, b) => {
          const percentageA = maintenanceMarginUtilization(
            Number(a.original.equity),
            Number(a.original.maintenance_margin)
          );

          const percentageB = maintenanceMarginUtilization(
            Number(b.original.equity),
            Number(b.original.maintenance_margin)
          );

          if (percentageA > percentageB) return 1;
          if (percentageA < percentageB) return -1;
          return 0;
        },
      },
      {
        title: "MM",
        align: "right",
        accessor: "maintenance_margin",
        Cell: ({ row, value }: any) => {
          const percentage = maintenanceMarginUtilization(
            Number(row.original.equity),
            Number(row.original.maintenance_margin)
          ).toFixed(1);
          return (
            <Align
              align="right"
              style={{
                color: value < 0.01 ? TEXT_COLORS.three : utilizationColor(Number(percentage)),
              }}
            >
              {currency(value).format()}
            </Align>
          );
        },
      },
      {
        id: "rowActionsSideTypExpiry",
        title: "Actions",
        align: "center",
        Cell: ({ row }: any) => (
          <Align align="center">
            <GroupedCells>
              <ActionWrapper>
                <RowActionButton
                  variant={"edit"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedAccount(row.original as IAccount);
                    setShow(true);
                  }}
                />
              </ActionWrapper>
            </GroupedCells>
          </Align>
        ),
      },
    ],
    [onCopy, records]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedAccounts,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <ConfirmationModal
        icon={<EditIcon />}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Update Account Type"}
        subtitle={shortenAddress(selectedAccount?.account || "")}
        show={show}
        minWidth={"250px"}
        onHide={() => setShow(false)}
        extras={
          <>
            <AccountButton
              isActive={selectedAccount?.account_type === AccountTypeEnum.STANDARD}
              onClick={() =>
                setSelectedAccount({
                  ...selectedAccount,
                  account_type: AccountTypeEnum.STANDARD,
                } as IAccount)
              }
            >
              Standard
            </AccountButton>
            <AccountButton
              isActive={selectedAccount?.account_type === AccountTypeEnum.MARKET_MAKER}
              onClick={() =>
                setSelectedAccount({
                  ...selectedAccount,
                  account_type: AccountTypeEnum.MARKET_MAKER,
                } as IAccount)
              }
            >
              Market Maker
            </AccountButton>
            <AccountButton
              isActive={selectedAccount?.account_type === AccountTypeEnum.MANAGED_ACCOUNT}
              onClick={() =>
                setSelectedAccount({
                  ...selectedAccount,
                  account_type: AccountTypeEnum.MANAGED_ACCOUNT,
                } as IAccount)
              }
            >
              Managed Account
            </AccountButton>
          </>
        }
        confirmationButton={{
          title: "Update",
          disabled: !selectedAccount,
          onClick: () => updateAccount(selectedAccount as IAccount),
        }}
      />
      <Flex style={{ padding: `${SPACING.three}px` }} gap={SPACING.two} wrap>
        <VaultCapacity
          percentage={vaultCapacity.percentage}
          usdc_balance={vaultCapacity.usdc_balance}
          total_balance={vaultCapacity.total_balance}
        />
        <SpotBalance asset="BTC" balance={spotCounterpartyAssets("WBTC")} withdrawals={withdrawalTotal("WBTC")} />
        <SpotBalance asset="ETH" balance={spotCounterpartyAssets("WETH")} withdrawals={withdrawalTotal("WETH")} />
        <SpotBalance asset="USDC" balance={spotCounterpartyAssets("USDC")} withdrawals={withdrawalTotal("USDC")} />
        <SpotBalance asset="USDT" balance={spotCounterpartyAssets("USDT")} withdrawals={withdrawalTotal("USDT")} />
      </Flex>

      <Flex style={{ padding: `0 ${SPACING.three}px` }} gap={SPACING.two} wrap>
        {Object.entries(quickAddresses).map(([key, value]) => (
          <QuickAddressTag to={`/account/${value}`} key={String(key)}>
            <IconWrapper style={{ marginRight: SPACING.two, height: 15, width: 15 }}>
              <Jazzicon diameter={15} seed={generateSeedWithAddress(value)} />
            </IconWrapper>
            <span>{key}</span>
            <img src={arrow} alt="arrow" />
          </QuickAddressTag>
        ))}
      </Flex>

      <HeaderRow>
        <FilterCol>
          <AccountFilter />
        </FilterCol>

        <PaginationCol>
          <div>
            {!isLoading ? (
              <div style={{ color: TEXT_COLORS.two, fontSize: FONT_SIZE.two }}>
                Showing <span style={{ color: TEXT_COLORS.one }}>{displayRange}</span> of{" "}
                <span style={{ color: TEXT_COLORS.one }}>
                  {currency(totalAccounts, { precision: 0 }).format({ symbol: "" })}
                </span>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            onClick={onPrevPage}
            type="button"
            disabled={pagination === 1 || isLoading}
          >
            {"<"}
          </Button>
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            onClick={onNextPage}
            type="button"
            disabled={pagination === lastPage || isLoading}
          >
            {">"}
          </Button>
        </PaginationCol>
      </HeaderRow>

      <TableWrapper ref={tableRef}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <DashboardRow
                  {...rowProps}
                  onClick={() => navigate(`/account/${row.original.account}`)}
                  hasRecord={!!getAirtableTag(row.original.account)}
                >
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </DashboardRow>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </>
  );
}
