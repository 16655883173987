import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ICampaignStats } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";

export const useCampaignStats = () => {
  const { queryConfig, tokenId } = useContext(AuthContext);

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.CAMPAIGN_STATS);
  const swr = useSWR<ICampaignStats, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ? [endpoint, tokenId] : undefined,
    async () => {
      const response = await axios.get<ICampaignStats>(endpoint, {
        ...queryConfig(),
      });

      const { data } = response;
      return data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT],
    }
  );

  return {
    ...swr,
  };
};
