import { useContext, useState } from "react";
import { Select } from "../shared/Select";
import { BookWrapper, SelectWrapper, TableWrapper } from "./style";
import { SPACING } from "../../constants/design/spacing";
import { RiskPositionTable } from "../Account/tables/RiskPositionTable";
import { InstrumentType, usePositions } from "../../hooks/api/admin/usePositions";
import { MarketDropdown } from "../Dropdown/MarketDropdown";
import { DataContext } from "../../context/DataContext";

export function RiskPage() {
  const [selectedAsset, setAsset] = useState<string>("");
  const [selectedInstrumentType, setInstrumentType] = useState<InstrumentType>("NONE");

  const { data: positionsData } = usePositions(selectedInstrumentType, selectedAsset, 30);
  const { records } = useContext(DataContext);

  return (
    <BookWrapper>
      <SelectWrapper>
        <MarketDropdown onClick={setAsset} />
        <Select
          wrapperStyle={{
            width: `calc(100vw - ${SPACING.three * 2}px)`,
            overflowX: "scroll",
            marginTop: 20,
          }}
          isRound
          options={[
            {
              label: "All Instrument Types",
              isActive: selectedInstrumentType === "NONE",
              onClick: () => setInstrumentType("NONE"),
            },
            ...(["OPTION", "PERPETUAL"] as InstrumentType[]).map((instrumentType) => ({
              label: instrumentType,
              isActive: selectedInstrumentType === instrumentType,
              onClick: () => setInstrumentType(instrumentType),
            })),
          ]}
        />
      </SelectWrapper>

      <TableWrapper>
        <RiskPositionTable
          records={records}
          positions={positionsData?.positions ? positionsData?.positions : []}
          selectedAsset={selectedAsset || ""}
        />
      </TableWrapper>
    </BookWrapper>
  );
}
