import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Flex } from "antd";
import Jazzicon from "react-jazzicon";
import {
  INTERNAL_OPTIONS_ADDRESS,
  INTERNAL_OPTIONS_DAILIES_ADDRESS,
  INTERNAL_PERPS_LONG_ADDRESS,
  INTERNAL_PERPS_SHORT_ADDRESS,
  INTERNAL_PRELAUNCH_2_ADDRESS,
  INTERNAL_PRELAUNCH_ADDRESS,
  OBADJE_OPTIONS_ADDRESS,
  RISKY_ONCHAIN_MM_ADDRESS,
  SELLER_ADDRESS,
} from "../../constants/admin";
import { useAccount } from "../../hooks/api/admin/useAccount";
import { useCustomPositions } from "../../hooks/api/airtable/useCustomPositions";
import { useBinancePositions } from "../../hooks/api/binance/useBinancePositions";
import { useDeribitAccount } from "../../hooks/api/deribit/useDeribitAccount";
import { useDeribitPositions } from "../../hooks/api/deribit/useDeribitPositions";
import { useComputations } from "../../hooks/useComputations";
import { IAggregatedPosition } from "../../interfaces/positions";
import { aggregatePositions } from "../../utils/positions";
import { standardizeAevoPositions } from "../../utils/positions/aevo";
import { standardizeBinancePositions } from "../../utils/positions/binance";
import { standardizeDeribitPositions } from "../../utils/positions/deribit";
import { CustomGreekStats } from "../Account/stats/CustomGreekStats";
import { LinkButton } from "../Account/style";
import { AggregatedPositionTable } from "../Account/tables/AggregatedPositionTable";
import { CustomPositionTable } from "../Account/tables/CustomPositionTable";
import {
  AddressHeader,
  AddressTag,
  BookWrapper,
  LastUpdatedText,
  StickyHeader,
  TableWrapper,
} from "./style";
import { standardizeCustomPositions } from "../../utils/positions/custom";
import { usePricer } from "../../hooks/api/airtable/usePricer";
import { Spinner } from "../shared/Spinner";
import { MarketDropdown } from "../Dropdown/MarketDropdown";
import { SPACING } from "../../constants/design/spacing";
import { IconWrapper } from "../Intercom/style";
import { generateSeedWithAddress } from "../../utils/icon";

enum AddressEnum {
  CUSTOM = "Custom Positions",
  SELLER = "Seller",
  INTERNAL_PERPS_SHORT = "Perp MM 1",
  INTERNAL_PERPS_LONG = "Perp MM 2",
  INTERNAL_OPTIONS = "Internal Options",
  INTERNAL_OPTIONS_DAILIES = "Options (Dailies)",
  INTERNAL_PRELAUNCH = "Pre-Launch",
  INTERNAL_PRELAUNCH_2 = "Pre-Launch 2",
  OBADJE_OPTIONS = "Obadje Options",
  RISKY_ONCHAIN_MM = "Risky Onchain MM",
}

const ADDRESS_MAP: Record<AddressEnum, string> = {
  [AddressEnum.CUSTOM]: "1",
  [AddressEnum.INTERNAL_OPTIONS]: INTERNAL_OPTIONS_ADDRESS,
  [AddressEnum.INTERNAL_OPTIONS_DAILIES]: INTERNAL_OPTIONS_DAILIES_ADDRESS,
  [AddressEnum.INTERNAL_PERPS_LONG]: INTERNAL_PERPS_LONG_ADDRESS,
  [AddressEnum.INTERNAL_PERPS_SHORT]: INTERNAL_PERPS_SHORT_ADDRESS,
  [AddressEnum.INTERNAL_PRELAUNCH_2]: INTERNAL_PRELAUNCH_2_ADDRESS,
  [AddressEnum.INTERNAL_PRELAUNCH]: INTERNAL_PRELAUNCH_ADDRESS,
  [AddressEnum.OBADJE_OPTIONS]: OBADJE_OPTIONS_ADDRESS,
  [AddressEnum.SELLER]: SELLER_ADDRESS,
  [AddressEnum.RISKY_ONCHAIN_MM]: SELLER_ADDRESS,
};

const mergePositions = (
  positions: IAggregatedPosition[][]
): IAggregatedPosition[] => positions.flat();

export function Book() {
  const [isUpdatingPricer, setUpdatingPricer] = useState<boolean>(false);
  const { computeDeribitCashFlow, computeDeribitEquity } = useComputations();
  const {
    data: customPositions = [],
    isLoading: isCustomPositionLoading,
    isValidating: isCustomPositionValidating,
    mutate: mutateCustomPositions,
  } = useCustomPositions();
  const { data: pricerTimestamp, updatePricer } = usePricer();
  const [selectedAsset, setAsset] = useState<string | undefined>(undefined);
  const [selectedAddresses, setAddresses] = useState<Set<AddressEnum>>(
    new Set(Object.values(AddressEnum))
  );

  /* AEVO ACCOUNT HOOKS */
  const { data: optionsAccount } = useAccount(INTERNAL_OPTIONS_ADDRESS);
  const { data: perpsShortAccount } = useAccount(INTERNAL_PERPS_SHORT_ADDRESS);
  const { data: perpsLongAccount } = useAccount(INTERNAL_PERPS_LONG_ADDRESS);
  const { data: dailiesAccount } = useAccount(INTERNAL_OPTIONS_DAILIES_ADDRESS);
  const { data: preLaunchAccount } = useAccount(INTERNAL_PRELAUNCH_ADDRESS);
  const { data: preLaunch2Account } = useAccount(INTERNAL_PRELAUNCH_2_ADDRESS);
  const { data: sellerAccount } = useAccount(SELLER_ADDRESS);
  const { data: obadjeAccount } = useAccount(OBADJE_OPTIONS_ADDRESS);
  const { data: riskyOnchainMMAccount } = useAccount(RISKY_ONCHAIN_MM_ADDRESS);

  /* BINANCE POSITION HOOKS */
  const { data: perpsBinancePos = [] } = useBinancePositions(
    INTERNAL_PERPS_SHORT_ADDRESS
  );

  /* DERIBIT POSITION HOOKS */
  const { data: optionsDeribitPos = [] } = useDeribitPositions(
    selectedAsset,
    INTERNAL_OPTIONS_ADDRESS
  );

  /* DERIBIT ACCOUNT HOOKS */
  const { data: optionsDeribitAccount } = useDeribitAccount(
    selectedAsset,
    INTERNAL_OPTIONS_ADDRESS
  );

  const aggregatedAevoPositions: IAggregatedPosition[] = useMemo(() => {
    const positions: IAggregatedPosition[][] = [];

    if (selectedAddresses.has(AddressEnum.INTERNAL_OPTIONS)) {
      positions.push(
        standardizeAevoPositions(optionsAccount?.positions || [], selectedAsset)
      );
    }

    if (selectedAddresses.has(AddressEnum.INTERNAL_PERPS_SHORT)) {
      positions.push(
        standardizeAevoPositions(
          perpsShortAccount?.positions || [],
          selectedAsset
        )
      );
    }

    if (selectedAddresses.has(AddressEnum.INTERNAL_PERPS_LONG)) {
      positions.push(
        standardizeAevoPositions(
          perpsLongAccount?.positions || [],
          selectedAsset
        )
      );
    }

    if (selectedAddresses.has(AddressEnum.INTERNAL_OPTIONS_DAILIES)) {
      positions.push(
        standardizeAevoPositions(dailiesAccount?.positions || [], selectedAsset)
      );
    }

    if (selectedAddresses.has(AddressEnum.INTERNAL_PRELAUNCH)) {
      positions.push(
        standardizeAevoPositions(
          preLaunchAccount?.positions || [],
          selectedAsset
        )
      );
    }

    if (selectedAddresses.has(AddressEnum.INTERNAL_PRELAUNCH_2)) {
      positions.push(
        standardizeAevoPositions(
          preLaunch2Account?.positions || [],
          selectedAsset
        )
      );
    }

    if (selectedAddresses.has(AddressEnum.SELLER)) {
      positions.push(
        standardizeAevoPositions(sellerAccount?.positions || [], selectedAsset)
      );
    }

    if (selectedAddresses.has(AddressEnum.OBADJE_OPTIONS)) {
      positions.push(
        standardizeAevoPositions(obadjeAccount?.positions || [], selectedAsset)
      );
    }

    if (selectedAddresses.has(AddressEnum.RISKY_ONCHAIN_MM)) {
      positions.push(
        standardizeAevoPositions(
          riskyOnchainMMAccount?.positions || [],
          selectedAsset
        )
      );
    }

    return aggregatePositions(mergePositions(positions));
  }, [
    dailiesAccount?.positions,
    obadjeAccount?.positions,
    optionsAccount?.positions,
    perpsLongAccount?.positions,
    perpsShortAccount?.positions,
    preLaunch2Account?.positions,
    preLaunchAccount?.positions,
    riskyOnchainMMAccount?.positions,
    selectedAddresses,
    selectedAsset,
    sellerAccount?.positions,
  ]);

  const aggregatedDeribitPositions: IAggregatedPosition[] = useMemo(() => {
    // Only INSURANCE_FUND & INTERNAL_OPTIONS has Deribit credentials
    const positions: IAggregatedPosition[][] = [];

    if (selectedAddresses.has(AddressEnum.INTERNAL_OPTIONS)) {
      if (selectedAsset) {
        const optionsCashflow = computeDeribitCashFlow(
          optionsDeribitPos,
          selectedAsset
        );

        const optionsEquity = computeDeribitEquity(
          Number(optionsDeribitAccount?.equity),
          selectedAsset
        );

        if (optionsCashflow && optionsEquity)
          positions.push([optionsCashflow, optionsEquity]);
      }
      positions.push(standardizeDeribitPositions(optionsDeribitPos));
    }

    return aggregatePositions(mergePositions(positions));
  }, [
    computeDeribitCashFlow,
    computeDeribitEquity,
    optionsDeribitAccount?.equity,
    optionsDeribitPos,
    selectedAddresses,
    selectedAsset,
  ]);

  const aggregatedBinancePositions: IAggregatedPosition[] = useMemo(() => {
    // Only INSURANCE_FUND && INTERNAL_PERPS has Binance credentials
    const positions: IAggregatedPosition[][] = [];

    if (selectedAddresses.has(AddressEnum.INTERNAL_PERPS_SHORT)) {
      positions.push(standardizeBinancePositions(perpsBinancePos));
    }

    return aggregatePositions(mergePositions(positions));
  }, [perpsBinancePos, selectedAddresses]);

  const onSelectAddress = useCallback(
    (key: AddressEnum) => {
      const updatedAddresses = selectedAddresses;
      if (selectedAddresses.has(key)) {
        updatedAddresses.delete(key);
      } else {
        updatedAddresses.add(key);
      }
      setAddresses(new Set(updatedAddresses));
    },
    [selectedAddresses]
  );

  const onUpdatePricer = useCallback(async () => {
    setUpdatingPricer(true);

    try {
      updatePricer().finally(() => setUpdatingPricer(false));
    } catch (error) {
      console.error(error);
    }
  }, [updatePricer]);

  const isAllSelected = useMemo(
    () => selectedAddresses.size === Object.entries(AddressEnum).length,
    [selectedAddresses]
  );

  const onSelectAll = useCallback(() => {
    if (isAllSelected) {
      setAddresses(new Set());
    } else {
      setAddresses(new Set(Object.values(AddressEnum)));
    }
  }, [isAllSelected]);

  return (
    <BookWrapper>
      <StickyHeader>
        <AddressHeader>
          <Flex gap={SPACING.two} wrap>
            {Object.entries(ADDRESS_MAP).map(([key, value]) => (
              <AddressTag
                isActive={selectedAddresses.has(key as AddressEnum)}
                onClick={() => onSelectAddress(key as AddressEnum)}
                key={String(key)}
                style={{ display: "flex" }}
              >
                <IconWrapper
                  style={{ marginRight: SPACING.two, height: 15, width: 15 }}
                >
                  <Jazzicon
                    diameter={15}
                    seed={generateSeedWithAddress(value)}
                  />
                </IconWrapper>
                {key}
              </AddressTag>
            ))}
            <LinkButton onClick={onSelectAll}>
              {isAllSelected ? "Deselect" : "Select"} All
            </LinkButton>
          </Flex>
          <div>
            <LastUpdatedText>
              Model last updated on:{" "}
              <span>
                {pricerTimestamp
                  ? moment.unix(pricerTimestamp).format("DD MMM HH:mm:ss")
                  : "-"}
              </span>
            </LastUpdatedText>
            <LinkButton onClick={() => onUpdatePricer()}>
              {isUpdatingPricer ? <Spinner /> : "Update"}
            </LinkButton>
          </div>
        </AddressHeader>
        <MarketDropdown onClick={setAsset} />
        <CustomGreekStats
          selectedAsset={selectedAsset}
          aevoPositions={aggregatedAevoPositions}
          binancePositions={aggregatedBinancePositions}
          customPositions={
            selectedAddresses.has(AddressEnum.CUSTOM) ? customPositions : []
          }
          deribitPositions={aggregatedDeribitPositions}
        />
      </StickyHeader>
      <TableWrapper>
        {selectedAddresses.has(AddressEnum.CUSTOM) ? (
          <CustomPositionTable
            selectedAsset={selectedAsset}
            customPositions={customPositions}
            isLoading={
              isCustomPositionLoading ||
              (!isCustomPositionLoading && isCustomPositionValidating)
            }
            onRefresh={() => mutateCustomPositions(customPositions)}
          />
        ) : null}
        <AggregatedPositionTable
          selectedAsset={selectedAsset}
          aggregatedAevoPositions={aggregatedAevoPositions}
          aggregatedDeribitPositions={aggregatedDeribitPositions}
          aggregatedBinancePositions={aggregatedBinancePositions}
          aggregatedCustomPositions={
            selectedAddresses.has(AddressEnum.CUSTOM)
              ? standardizeCustomPositions(customPositions || [])
              : []
          }
        />
      </TableWrapper>
    </BookWrapper>
  );
}
