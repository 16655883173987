import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const CampaignPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${COMPONENTS.header.main}px);
  overflow: auto;
  padding: ${SPACING.three}px;
`;
