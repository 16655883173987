/* eslint-disable no-param-reassign */
import { Bar, XAxis, Tooltip, ReferenceLine, ResponsiveContainer, ComposedChart, Area } from "recharts";
import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import currency from "currency.js";
import { Collapse } from "antd";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IAevoTradeHistory } from "../../../interfaces/aevo";
import { TooltipWrapper } from "./style";
import { TitleWrapper } from "../../Table/style";

interface IData {
  buy: number;
  sell: number;
  date: string;
  cumulative: number;
}

interface ITradeHistoryProps {
  data: IAevoTradeHistory[];
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const dataKeyColor = {
  buy: COLORS.green.one,
  sell: COLORS.red.one,
  cumulative: COLORS.highlight.one,
};

function TradeHistoryChart({ data, startDate, endDate }: ITradeHistoryProps) {
  const [isShow, setShow] = useState<boolean>(window.localStorage.getItem("showTradeHistoryChart") === "true");

  const sanitizedData = useMemo(() => {
    const results: IData[] = [];

    // Populate the results with days from startDate to endDate
    const difference = endDate.diff(startDate, "days") + 1;

    for (let i = 0; i <= difference; i += 1) {
      const date = startDate.clone().add(i, "days").format("D/M");
      results.push({ buy: 0, sell: 0, date, cumulative: 0 });
    }

    if (data.length === 0) return results;

    data.forEach((item) => {
      const { side, price, amount, created_timestamp } = item;
      const date = moment(Number(created_timestamp) / 1e6).format("D/M");
      const index = results.findIndex((result) => result.date === date);

      if (results[index]) {
        if (side === "buy") {
          results[index].buy += Number(price) * Number(amount) || 0;
        } else {
          results[index].sell -= Number(price) * Number(amount) || 0;
        }
      }
    });

    // Update cumulative volume after processing all data
    results.reduce((acc, result) => {
      result.cumulative = acc + result.buy + result.sell;
      return result.cumulative;
    }, 0);

    return results;
  }, [data, endDate, startDate]);

  const onSet = useCallback(() => {
    window.localStorage.setItem("showTradeHistoryChart", JSON.stringify(!isShow));
    setShow(!isShow);
  }, [isShow]);

  return (
    <div>
      <Collapse
        expandIconPosition="end"
        destroyInactivePanel
        items={[
          {
            key: "1",
            label: <TitleWrapper>Cumulative Chart</TitleWrapper>,
            children: (
              <ResponsiveContainer
                style={{
                  background:
                    "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABlJREFUGFdjZGBg+M+ABhgJCcIVEFQJNxkAVpAGASEHn7kAAAAASUVORK5CYII=)",
                  backgroundColor: BACKGROUND_COLORS.two,
                }}
                width="100%"
                height={300}
              >
                <ComposedChart width={1200} height={300} data={sanitizedData} stackOffset="sign">
                  <XAxis dataKey="date" color={"red"} fontSize={10} />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        return (
                          <TooltipWrapper>
                            <div>{moment(label, "D/M").format("Do MMM")}</div>
                            {payload.map((item) => (
                              <div
                                key={item.name}
                                style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 8 }}
                              >
                                <span style={{ color: (dataKeyColor as any)[(item as any).dataKey] }}>{item.name}</span>
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: item.value === 0 ? TEXT_COLORS.two : TEXT_COLORS.one,
                                  }}
                                >
                                  {currency(Number(item.value)).format()}
                                </span>
                              </div>
                            ))}
                          </TooltipWrapper>
                        );
                      }

                      return null;
                    }}
                    formatter={(value, name) => [currency(Number(value || 0)).format(), name]}
                  />
                  <ReferenceLine y={0} stroke={BORDER_COLORS.one} />
                  <Bar barSize={4} stackId="trades" dataKey="buy" stroke={COLORS.green.one} />
                  <Bar barSize={4} stackId="trades" dataKey="sell" stroke={COLORS.red.one} />
                  <Area
                    type="monotone"
                    dataKey="cumulative"
                    startOffset={"silhouette"}
                    stroke={COLORS[sanitizedData[sanitizedData.length - 1]?.cumulative > 0 ? "green" : "red"].three}
                    fill={COLORS[sanitizedData[sanitizedData.length - 1]?.cumulative > 0 ? "green" : "red"].four}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            ),
          },
        ]}
      />
    </div>
  );
}

export default TradeHistoryChart;
