import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";

// A centered spinner wrapper with adjustable width + height
export const SpinnerContainerWrapper = styled.div<{
  height?: string;
  width?: string;
}>`
  display: flex;
  height: ${({ height }) => (height ? `${height}` : "100%")};
  width: ${({ height, width }) => (height ? `${width}` : "100%")};
  flex: 1;

  > * {
    margin: auto;
  }
`;

const flashing = () => keyframes`
 0%,
 100% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }
}`;

export const SpinnerBar = styled.span<{ delay: number }>`
  opacity: 0.1;
  animation: ${flashing} 0.8s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
  display: inline;
`;

export const SpinnerWrapper = styled.div<{
  theme: string;
  color?: string;
  isLoading: boolean;
}>`
  ${SpinnerBar} {
    display: inline-block;
    border-radius: 100px;
    padding: 0;

    &:not(:last-child) {
      margin-right: ${SPACING.one}px;
    }

    ${({ isLoading }) => !isLoading && "animation: none"};
    ${({ theme }) => theme &&
      `
        background-color: ${(COLORS as any)[theme].one};
        height: 8px;
        width: 2px;
    `}
    ${(props) => props.color &&
      `
        background-color: ${props.color};
    `}
  }
`;
