import currency from "currency.js";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IAccount, IAevoOption, IAevoPosition } from "../../../interfaces/aevo";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, TableHeaderCell, TableLoader, TitleWrapper } from "../../Table/style";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { useMarkets } from "../../../hooks/api/aevo/useMarkets";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";
import { Spinner } from "../../shared/Spinner";
import CollapseHeaderCount from "../../TableCollapse";

interface IPositionTableProps {
  data: IAccount;
  selectedAsset: string | undefined;
  isLoading?: boolean;
}

export function AevoPositionTable({ data, selectedAsset, isLoading }: IPositionTableProps) {
  const { isLoading: isMarketLoading, data: marketsData, isValidating: isMarketValidating } = useMarkets();

  const memoizedPositions = useMemo(() => {
    const positions = data?.positions || [];

    positions.forEach((pos) => {
      if (!pos.option) {
        const posSign = pos.side === "buy" ? 1 : -1;
        // eslint-disable-next-line no-param-reassign
        pos.option = {
          delta: String(posSign * Number(pos.amount)),
        } as IAevoOption;
      }
    });

    if (!selectedAsset) return positions;

    return positions.filter((pos) => pos.asset === selectedAsset);
  }, [data?.positions, selectedAsset]);

  const isInternalPerpAddress = useMemo(
    () => data?.account === "0x7B19b83dC868CDD41569A924550efE92B27cBF2e",
    [data?.account]
  );

  const getNotional = useCallback(
    (pos: IAevoPosition, returnValue: boolean = false) => {
      const asset = retrieveAssetByInstrumentName(pos.instrument_name!);
      const market = marketsData?.find((m) => m.underlying_asset === asset);
      if (market) {
        if (returnValue) {
          return Number(market.mark_price) * Number(pos.amount);
        }

        return currency(Number(market.mark_price) * Number(pos.amount)).format();
      }

      if (!isMarketLoading && isMarketValidating) {
        if (returnValue) {
          return 0;
        }

        return <Spinner />;
      }

      if (returnValue) {
        return 0;
      }

      return "-";
    },
    [isMarketLoading, isMarketValidating, marketsData]
  );

  const getLeverageByInstrumentId = useCallback(
    (instrumentId: string) => {
      const market = data?.leverages.find((m) => m.instrument_id === instrumentId);
      return market ? `${market.leverage}x` : "-";
    },
    [data?.leverages]
  );

  const greekColumns: ITableColumn<IAevoPosition>[] = useMemo(
    () => [
      {
        title: "Delta",
        align: "right",
        // @ts-ignore
        accessor: "option.delta",
        valueExtractor: (value) => (value ? `${parseFloat(value).toFixed(4)}` : "-"),
      },
      {
        title: "Gamma",
        align: "right",
        id: "gamma",
        // @ts-ignore
        accessor: "option.gamma",
        valueExtractor: (value) => (value ? `${parseFloat(value).toFixed(4)}` : "-"),
      },
      {
        title: "Gamma (USD)",
        align: "right",
        id: "gamma-notional",
        // @ts-ignore
        accessor: "option.gamma",
        Cell: ({ value, row }: any) => (
          <Align align="right">
            {value && row.original.mark_price
              ? currency(Number(value) * Number(row.original.mark_price) ** 2 * 0.01).format()
              : "-"}
          </Align>
        ),
      },
      {
        title: "Vega",
        align: "right",
        // @ts-ignore
        accessor: "option.vega",
        valueExtractor: (value) => (value ? `${parseFloat(value).toFixed(4)}` : "-"),
      },
      {
        title: "Theta",
        align: "right",
        // @ts-ignore
        accessor: "option.theta",
        valueExtractor: (value) => (value ? `${parseFloat(value).toFixed(4)}` : "-"),
      },
    ],
    []
  );

  const columns: ITableColumn<IAevoPosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Side",
        align: "left",
        accessor: "side",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "buy" ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {value === "buy" ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        id: "contracts",
        accessor: "amount",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
      {
        title: "Leverage",
        align: "right",
        id: "leverage",
        accessor: "instrument_id",
        valueExtractor: (value) => getLeverageByInstrumentId(value),
      },
      {
        title: "Notional",
        align: "right",
        id: "notional",
        accessor: "amount",
        Cell: ({ row }) => <Align align={"right"}>{getNotional(row.original)}</Align>,
        sortType: (a: any, b: any) =>
          Number(getNotional(a.original, true)) > Number(getNotional(b.original, true)) ? 1 : -1,
      },
      {
        title: "Entry Price",
        align: "right",
        accessor: "avg_entry_price",
        valueExtractor: (value) => `$${Number(value).toPrecision(6)}`,
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "mark_price",
        valueExtractor: (value) => `$${Number(value).toPrecision(6)}`,
      },
      ...(isInternalPerpAddress ? [] : greekColumns),
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "unrealized_pnl",
        Cell: ({ value }: any) => (
          <Align
            align="right"
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                parseFloat(value) > 0 ? COLORS.green.one : parseFloat(value) < 0 ? COLORS.red.one : TEXT_COLORS.one,
            }}
          >
            {currency(value).format()}
          </Align>
        ),
      },
      {
        title: "Maintenance Margin",
        align: "right",
        accessor: "maintenance_margin",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={{
              color: parseFloat(value) > 0 ? TEXT_COLORS.one : TEXT_COLORS.three,
            }}
          >
            {currency(Number(value)).format()}
          </Align>
        ),
      },
    ],
    [getLeverageByInstrumentId, getNotional, greekColumns, isInternalPerpAddress]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedPositions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
      expandSubRows: true,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (memoizedPositions && memoizedPositions.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedPositions.map(
          (v: IAevoPosition) => [
            v.instrument_name,
            v.side,
            v.amount,
            v.avg_entry_price,
            v.mark_price,
            v?.option?.delta ? `${parseFloat(String(v?.option?.delta)).toFixed(4)}` : "-",
            v?.option?.gamma ? `${parseFloat(String(v?.option?.gamma)).toFixed(4)}` : "-",
            v?.option?.vega ? `${parseFloat(String(v?.option?.vega)).toFixed(4)}` : "-",
            v?.option?.theta ? `${parseFloat(String(v?.option?.theta)).toFixed(4)}` : "-",
            currency(Number(v.unrealized_pnl)).format(),
            currency(Number(v.maintenance_margin)).format(),
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedPositions]);

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={memoizedPositions.length} showBadge title="Aevo Positions" />,
          extra: (
            <DownloadCSVButton data={csvData} filename={`Aevo Positions ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
          ),
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
