import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { AevoEndpointEnum } from "../../../enums/endpoints";
import { AEVO_API_URL } from "../../../utils/env";
import { pollingInterval } from "../../../constants/pollingInterval";

export interface IMarket {
  instrument_id: string;
  instrument_name: string;
  instrument_type: string;
  underlying_asset: string;
  quote_asset: string;
  price_step: string;
  amount_step: string;
  min_order_value: string;
  mark_price: string;
  is_active: boolean;
  pre_launch: boolean;
}

export const useMarkets = () => {
  const endpoint = AEVO_API_URL(AevoEndpointEnum.MARKETS);

  const swr = useSWR<IMarket[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    {},
    async () => {
      try {
        const markets = await axios.get<any>(
          `${endpoint}?instrument_type=PERPETUAL`
        );

        // Sort data by underlying_asset alphabetically, with BTC and ETH at the top
        return markets.data.sort((a: IMarket, b: IMarket) => a.underlying_asset.localeCompare(b.underlying_asset));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refreshInterval: pollingInterval[AevoEndpointEnum.MARKETS],
    }
  );

  return {
    ...swr,
  };
};
