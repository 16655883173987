import currency from "currency.js";
import { COLORS, TEXT_COLORS } from "../constants/design/colors";

export const shortenString = (
  str: string,
  startLen: number,
  endLen: number
) => {
  if (str.length > startLen + endLen) {
    return `${str.slice(0, startLen)}...${str.slice(-endLen)}`;
  }
  return str;
};

export const shortenAddress = (address: string) => shortenString(address, 6, 4);

/**
 * Given a string, convert the first letter of every word to uppercase
 * and returns the new string
 * @param str Any string
 */
export const startCase = (str: string) => str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const copyStringToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  if (window.navigator.platform === "iPhone") {
    textField.setSelectionRange(0, 99999);
  } else {
    textField.select();
  }
  document.execCommand("copy");
  textField.remove();
};

export const getProfitTextColor = (value: number) => {
  if (value > 0) {
    return COLORS.green.one;
  }
  if (value < 0) {
    return COLORS.red.one;
  }
  return TEXT_COLORS.three;
};

export const retrieveAssetByInstrumentName = (name: string) => name.split("-")[0];

export const formatAmount = (
  n: number,
  alwaysShowDecimals?: boolean
): string => {
  if (n < 1e3) {
    return `${currency(n, { separator: ",", symbol: "" }).format()}`;
  }
  if (n >= 1e3 && n < 1e6) {
    const v = alwaysShowDecimals
      ? (n / 1e3).toFixed(2)
      : parseFloat((n / 1e3).toFixed(2));
    return `${v}K`;
  }
  if (n >= 1e6 && n < 1e9) {
    const v = alwaysShowDecimals
      ? (n / 1e6).toFixed(2)
      : parseFloat((n / 1e6).toFixed(2));
    return `${v}M`;
  }
  if (n >= 1e9 && n < 1e12) {
    const v = alwaysShowDecimals
      ? (n / 1e9).toFixed(2)
      : parseFloat((n / 1e9).toFixed(2));
    return `${v}B`;
  }
  if (n >= 1e12) {
    const v = alwaysShowDecimals
      ? (n / 1e12).toFixed(2)
      : parseFloat((n / 1e12).toFixed(2));
    return `${v}T`;
  }

  return "";
};
