import styled, { css } from "styled-components";
import { COLORS, LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING, COMPONENTS } from "../../../constants/design/spacing";

/**
 * CSS for components that glows on hover
 * @param color Color, hex or rgba()
 * @returns css
 */
export const glow = (color: string) => css`
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${color} inset;
  }
`;

export enum ButtonThemeEnum {
  PRIMARY = "PRIMARY",
  NEUTRAL1 = "NEUTRAL1",
  NEUTRAL2 = "NEUTRAL2",
  NEUTRAL3 = "NEUTRAL3",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  HIGHLIGHT = "HIGHLIGHT",
  SYSTEM = "SYSTEM",
}

type ButtonTypeToColor = {
  [key in ButtonThemeEnum]: string;
};

export const buttonBgColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: COLORS.purple.two,
  [ButtonThemeEnum.NEUTRAL1]: LAYER_COLORS.one,
  [ButtonThemeEnum.NEUTRAL2]: LAYER_COLORS.two,
  [ButtonThemeEnum.NEUTRAL3]: LAYER_COLORS.three,
  [ButtonThemeEnum.POSITIVE]: COLORS.green.five,
  [ButtonThemeEnum.NEGATIVE]: COLORS.red.five,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.five,
  [ButtonThemeEnum.SYSTEM]: COLORS.orange.five,
};

const buttonHoverBorderColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: COLORS.purple.one,
  [ButtonThemeEnum.NEUTRAL1]: COLORS.neutral.one,
  [ButtonThemeEnum.NEUTRAL2]: COLORS.neutral.one,
  [ButtonThemeEnum.NEUTRAL3]: COLORS.neutral.one,
  [ButtonThemeEnum.POSITIVE]: COLORS.green.one,
  [ButtonThemeEnum.NEGATIVE]: COLORS.red.one,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.one,
  [ButtonThemeEnum.SYSTEM]: COLORS.orange.one,
};

export const buttonTextColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL1]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL2]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL3]: TEXT_COLORS.one,
  [ButtonThemeEnum.POSITIVE]: COLORS.green.one,
  [ButtonThemeEnum.NEGATIVE]: COLORS.red.one,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.one,
  [ButtonThemeEnum.SYSTEM]: COLORS.orange.one,
};

const buttonTextDisabledColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL1]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL2]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL3]: TEXT_COLORS.two,
  [ButtonThemeEnum.POSITIVE]: COLORS.green.two,
  [ButtonThemeEnum.NEGATIVE]: COLORS.red.two,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.two,
  [ButtonThemeEnum.SYSTEM]: COLORS.orange.two,
};

export interface IButtonComponentProps {
  buttonTheme: ButtonThemeEnum;
  fullWidth?: boolean;
}

export const Button = styled.button<IButtonComponentProps>`
  ${FONT_CSS.body.four}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ buttonTheme }) => buttonBgColor[buttonTheme]};
  color: ${({ buttonTheme }) => buttonTextColor[buttonTheme]};
  border-radius: 6px;
  padding: ${SPACING.two}px ${SPACING.three}px;
  min-width: ${COMPONENTS.modalButtonWidth}px;
  min-height: ${COMPONENTS.modalButtonHeight}px;
  transition: 0.2s ease-in-out all;
  border: 1px solid transparent;

  &:hover {
    border: ${({ buttonTheme }) => `1px solid ${buttonHoverBorderColor[buttonTheme]}`};
  }

  &:disabled {
    color: ${({ buttonTheme }) => buttonTextDisabledColor[buttonTheme]};
    opacity: 0.64;
    border: none;

    &:hover {
      box-shadow: none;
    }
  }
`;
