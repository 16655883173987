import { Data, Layout } from "plotly.js";
import { useCallback, useMemo } from "react";
import Plot from "react-plotly.js";
import { BACKGROUND_COLORS } from "../../constants/design/colors";
import { useMMTrades } from "../../hooks/api/mm/useMMTrades";
import { useMarkHistory } from "../../hooks/api/mm/useMarkHistory";
import { useMMBybitAssets, IS_VALID_ASSET } from "../../utils/asset";
import { timestamp24HAgoMs, timestamp24HAgoSec } from "../../utils/math";
import { ChartContainer } from "./style";

function InstrumentChart({
  asset,
  showAllTrades,
  account,
}: {
  asset: string;
  showAllTrades: boolean;
  account: string | undefined;
}) {
  const legendMarkerSize = 10;
  const minMarkerSize = 5; // Minimum marker size
  const maxMarkerSize = 15; // Maximum marker size
  const instrumentName = `${asset}-PERP`;

  const bybitAssets = useMMBybitAssets().data;
  const secondaryExchange = !IS_VALID_ASSET(asset, bybitAssets ?? []) ? "BINANCE" : "BYBIT";

  const { data: markHistory } = useMarkHistory(timestamp24HAgoSec, asset);

  const aevoTrades = useMMTrades(
    timestamp24HAgoMs,
    instrumentName,
    "AEVO",
    showAllTrades,
    account
  ).data;
  const { data: secondaryExchangeTrades } = useMMTrades(
    timestamp24HAgoMs,
    instrumentName,
    secondaryExchange,
    showAllTrades,
    account
  );

  const normalizeSize = useCallback(
    (amount: number, minAmount: number, maxAmount: number) => ((amount - minAmount) / (maxAmount - minAmount)) *
        (maxMarkerSize - minMarkerSize) +
      minMarkerSize,
    []
  );

  const layout = useMemo(
    () => ({
        title: `${instrumentName}`,
        xaxis: {
          autorange: true,
          type: "date",
        },
        yaxis: {
          autorange: true,
        },
        plot_bgcolor: BACKGROUND_COLORS.five,
        paper_bgcolor: "transparent", // Set overall background color to black
        font: {
          color: "white", // Optional: Change font color for better contrast
        },
      } as Layout),
    [instrumentName]
  );

  const plotData = useMemo(() => {
    if (!aevoTrades || !secondaryExchangeTrades || !markHistory) {
      return [] as Data[];
    }
    const allTrades = [...aevoTrades, ...secondaryExchangeTrades];
    const maxAmount = Math.max(
      ...allTrades.map((trade) => parseFloat(trade.amount))
    );
    const minAmount = Math.min(
      ...allTrades.map((trade) => parseFloat(trade.amount))
    );

    const aevoBuys = aevoTrades.filter((trade) => trade.side === "buy");
    const aevoSells = aevoTrades.filter((trade) => trade.side === "sell");
    const secondaryExchangeBuys = secondaryExchangeTrades.filter(
      (trade) => trade.side === "buy"
    );
    const secondaryExchangeSells = secondaryExchangeTrades.filter(
      (trade) => trade.side === "sell"
    );
    return [
      {
        x: markHistory.map((item) => new Date(parseInt(item.timestamp, 10)).toISOString()),
        y: markHistory.map((item) => parseFloat(item.price)),
        type: "scatter",
        mode: "lines",
        line: {
          color: "white",
          width: 0.5, // Adjust this value to make the line thinner
        },
        name: "Price",
      },
      {
        x: aevoBuys.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: aevoBuys.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "green",
          symbol: "triangle-up",
          line: { width: 0 },
          size: aevoBuys.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: aevoBuys.map(
          (trade) => `Price: ${trade.price}, Amount: ${
              trade.amount
            }, Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: "AEVO Buys",
        showlegend: false,
      },
      {
        x: aevoSells.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: aevoSells.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "red",
          symbol: "triangle-down",
          line: { width: 0 },
          size: aevoSells.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: aevoSells.map(
          (trade) => `Price: ${trade.price}, Amount: ${
              trade.amount
            }, Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: "AEVO Sells",
        showlegend: false,
      },
      {
        x: secondaryExchangeBuys.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: secondaryExchangeBuys.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "blue",
          symbol: "triangle-up",
          line: { width: 0 },
          size: secondaryExchangeBuys.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: secondaryExchangeBuys.map(
          (trade) => `Price: ${trade.price}, Amount: ${
              trade.amount
            }, Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${secondaryExchange} Buys`,
        showlegend: false,
      },
      {
        x: secondaryExchangeSells.map((trade) => new Date(parseInt(trade.timestamp, 10)).toISOString()),
        y: secondaryExchangeSells.map((trade) => parseFloat(trade.price)),
        type: "scatter",
        mode: "markers",
        marker: {
          color: "purple",
          symbol: "triangle-down",
          line: { width: 0 },
          size: secondaryExchangeSells.map((trade) => normalizeSize(parseFloat(trade.amount), minAmount, maxAmount)),
        },
        text: secondaryExchangeSells.map(
          (trade) => `Price: ${trade.price}, Amount: ${
              trade.amount
            }, Timestamp: ${new Date(
              parseInt(trade.timestamp, 10)
            ).toISOString()}`
        ),
        hoverinfo: "text",
        name: `${secondaryExchange} Sells`,
        showlegend: false,
      },
      {
        x: [null], // Invisible in the plot
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "green",
          symbol: "triangle-up",
          size: legendMarkerSize,
        },
        name: "AEVO Buys",
        showlegend: true,
        hoverinfo: "none", // No hover info for this series
      },
      {
        x: [null],
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "red",
          symbol: "triangle-down",
          size: legendMarkerSize,
        },
        name: "AEVO Sells",
        showlegend: true,
        hoverinfo: "none",
      },
      {
        x: [null], // Invisible in the plot
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "blue",
          symbol: "triangle-up",
          size: legendMarkerSize,
        },
        name: `${secondaryExchange} Buys`,
        showlegend: true,
        hoverinfo: "none", // No hover info for this series
      },
      {
        x: [null],
        y: [null],
        type: "scatter",
        mode: "markers",
        marker: {
          color: "purple",
          symbol: "triangle-down",
          size: legendMarkerSize,
        },
        name: `${secondaryExchange} Sells`,
        showlegend: true,
        hoverinfo: "none",
      },
    ] as Data[];
  }, [
    aevoTrades,
    markHistory,
    normalizeSize,
    secondaryExchange,
    secondaryExchangeTrades,
  ]);

  return (
    <ChartContainer>
      {!aevoTrades || !secondaryExchangeTrades || !markHistory ? (
        <div>... Loading {asset}-PERP</div>
      ) : (
        <Plot
          data={plotData}
          layout={layout}
          useResizeHandler
          style={{ width: "calc(100% - 64px)", height: "calc(100% - 64px)" }}
        />
      )}
    </ChartContainer>
  );
}

export default InstrumentChart;
