import { IBinancePosition } from "../../interfaces/binance";
import { IAggregatedPosition } from "../../interfaces/positions";

export const retrieveBinanceAssetBySymbol = (symbol: string) => symbol.split("USDT")[0];

export const standardizeBinancePositions = (
  positions: Partial<IBinancePosition>[]
): IAggregatedPosition[] => {
  const standardizedPositions: Record<string, IAggregatedPosition> = {};

  positions.forEach((pos) => {
    if (pos.symbol) {
      const binanceInstrumentName = `${retrieveBinanceAssetBySymbol(
        pos.symbol
      )}-PERPETUAL`;

      standardizedPositions[binanceInstrumentName] = {
        instrument_name: binanceInstrumentName,
        asset: retrieveBinanceAssetBySymbol(pos.symbol),
        side: Number(pos.positionAmt) > 0 ? "buy" : "sell",
        delta: Number(pos.positionAmt),
        contracts: Number(pos.positionAmt),
        gamma: 0,
        vega: 0,
        theta: 0,
        pnl: Number(pos.unRealizedProfit),
        isPerpetual: true,
        source: "Binance",
      };
    }
  });

  return Object.values(standardizedPositions);
};
