import styled, { css } from "styled-components";
import { TEXT_COLORS, BACKGROUND_COLORS, COLORS, BORDER_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { FONT_CSS } from "../../../constants/design/fontSize";

export const Title = styled.div`
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.data.four};
  margin-top: ${SPACING.two}px;
`;

export const Value = styled.div<{ value: number }>`
  color: ${TEXT_COLORS.one};
  transition: 0.1s all ease-in-out;
  ${FONT_CSS.data.three};
`;

export const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: 0.1s all ease-in-out;
  padding: ${SPACING.two}px;
  background-color: transparent;
  justify-content: space-between;

  > * {
    margin: auto 0;
  }

  &:hover {
    transition: 0.1s all ease-in-out;
    background-color: ${BACKGROUND_COLORS.four};

    *:not(${Title}) {
      color: ${COLORS.blue.one} !important;
    }
  }
`;

export const KeyValueContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          > * {
            border-radius: 6px;
            border: 1px solid ${BORDER_COLORS.three};
            background-color: ${BACKGROUND_COLORS.four};
          }
        `
      : css`
          margin: ${SPACING.two}px;

          > * {
            border-radius: 6px;
            border: 1px solid ${BORDER_COLORS.three};
            background-color: ${BACKGROUND_COLORS.four};
          }
        `}
  display: grid;
  grid-gap: ${SPACING.two}px;
  grid-template-columns: repeat(1, 1fr);

  &:not(:last-child) {
    margin-top: ${SPACING.two}px;
  }
`;

export const GreekWrapper = styled.div`
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  padding: ${SPACING.three}px;
  grid-column: span 1;
  display: grid;
  grid-template-columns: calc(32px + ${SPACING.three * 2}px) 1fr;
`;

export const GreekLogo = styled.div`
  display: grid;
  align-items: center;
  text-align: center;
  padding: ${SPACING.two}px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: ${BACKGROUND_COLORS.component};
  margin: auto 0;
  margin-right: ${SPACING.three}px;
  border: 1px solid ${BORDER_COLORS.two};
`;

export const GreekStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-gap: ${SPACING.four}px;
  overflow-x: scroll;
  margin: ${SPACING.three}px 0;
`;

export const GreekStat = styled.div<{ value?: number }>`
  display: flex;
  justify-content: space-between;
  padding: 2px 0;

  span:first-of-type {
    ${FONT_CSS.header.six};
    color: ${TEXT_COLORS.two};
  }

  span:last-of-type {
    ${FONT_CSS.header.six};

    color: ${({ value }) => (value === 0 ? TEXT_COLORS.three : TEXT_COLORS.one)};
  }

  &:last-of-type {
    border-top: 1px solid ${BORDER_COLORS.one};
    margin-top: ${SPACING.one}px;
    padding-top: ${SPACING.one}px;

    span:last-of-type {
      color: ${COLORS.blue.one};
    }
  }

  &:hover {
    > * {
      transition: 0.2s all ease-in-out;
      color: ${COLORS.blue.one} !important;
    }
  }
`;

export const StatTitle = styled.div<{ isMobile?: boolean }>`
  ${FONT_CSS.label.three};
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: ${SPACING.two}px;
        `
      : css`
          margin: ${SPACING.three}px;
        `}
  margin-bottom: ${SPACING.two}px;
  color: ${TEXT_COLORS.two};
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin-top: ${SPACING.four}px;
  }
`;
