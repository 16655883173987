import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Flex from "antd/es/flex";
import { useAccountSettings } from "../../hooks/api/admin/useAccountSettings";
import { Spinner } from "../shared/Spinner";
import { DataContext } from "../../context/DataContext";
import { AccountSettingsWrapper, SettingsGrid, SettingsWrapper } from "./style";
import { SettingsTable } from "./components/SettingsTable";
import { Select } from "../shared/Select";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { IAssetConfigs } from "../../interfaces/settings";
import ConfirmationModal from "../shared/ConfirmationModal";
import { MarketDropdown } from "../Dropdown/MarketDropdown";
import { FilterWrapper } from "../Table/style";

export function AccountSettings() {
  const { account } = useParams<{ account: string }>();
  const { setAddress } = useContext(DataContext);
  const { data: settingsData, isValidating, updateSettings, mutate } = useAccountSettings();

  const [selectedInstrumentType, setInstrumentType] = useState<"OPTION" | "PERPETUAL" | "SPOT" | undefined>(undefined);
  const [selectedAsset, setAsset] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showModal, setShow] = useState<boolean>(false);
  const [payload, setPayload] = useState<Partial<IAssetConfigs>>();

  useEffect(() => {
    if (account) setAddress(account);
  }, [account, setAddress]);

  const filteredSettings = useMemo(() => {
    if (settingsData) {
      let filteredData = settingsData;

      if (selectedAsset) {
        filteredData = filteredData.filter((setting) => setting.asset === selectedAsset);
      }

      if (selectedInstrumentType) {
        filteredData = filteredData.filter((setting) => setting.instrument_type === selectedInstrumentType);
      }

      return filteredData;
    }
  }, [selectedAsset, selectedInstrumentType, settingsData]);

  const assetFilter = useMemo(() => {
    // Loop through configs to retrieve filterable assets
    if (settingsData) {
      const assets = new Set<string>();

      settingsData.forEach((config) => {
        if (!assets.has(config.asset)) {
          assets.add(config.asset);
        }
      });

      const options = [
        {
          label: "All Assets",
          value: "",
        },
      ];

      Array.from(assets).forEach((asset) =>
        options.push({
          label: asset,
          value: asset,
        }));

      return <MarketDropdown onClick={setAsset} />;
    }
  }, [settingsData]);

  const onUpdate = useCallback(async () => {
    if (payload) {
      setLoading(true);
      await updateSettings(payload).finally(() => {
        mutate();
        setLoading(false);
      });
    }
  }, [mutate, payload, updateSettings]);

  return (
    <>
      <ConfirmationModal
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Edit Asset Configurations"}
        show={showModal}
        minWidth={"250px"}
        onHide={() => setShow(false)}
        stats={
          payload
            ? [
                {
                  title: "",
                  data: (
                    <div style={{ marginBottom: SPACING.three }}>
                      Here are the changes you are making for {payload.asset} {payload.instrument_type}:
                    </div>
                  ),
                },
                ...Object.keys(payload)
                  .filter((key) => !["account", "asset", "instrument_type"].includes(key))
                  .map((key) => ({
                    title: <div style={{ color: TEXT_COLORS.one }}>{key}</div>,
                    data: payload[key as keyof IAssetConfigs]!,
                  })),
              ]
            : []
        }
        confirmationButton={{
          title: "Update Settings",
          onClick: onUpdate,
          disabled: isLoading,
        }}
      />
      <AccountSettingsWrapper>
        <SettingsWrapper isAccount>
          <Flex gap={SPACING.three} wrap align="center" style={{ marginBottom: SPACING.two }}>
            <div>
              <FilterWrapper>Assets</FilterWrapper>
              {assetFilter}
            </div>
            <div>
              <FilterWrapper>Market Type</FilterWrapper>
              <Select
                theme="blue"
                options={[
                  {
                    label: "All Markets",
                    isActive: !selectedInstrumentType,
                    onClick: () => setInstrumentType(undefined),
                  },
                  {
                    label: "Options",
                    isActive: selectedInstrumentType === "OPTION",
                    onClick: () => setInstrumentType("OPTION"),
                  },
                  {
                    label: "Perpetuals",
                    isActive: selectedInstrumentType === "PERPETUAL",
                    onClick: () => setInstrumentType("PERPETUAL"),
                  },
                  {
                    label: "Spot",
                    isActive: selectedInstrumentType === "SPOT",
                    onClick: () => setInstrumentType("SPOT"),
                  },
                ]}
              />
            </div>
          </Flex>
          {
            // eslint-disable-next-line no-nested-ternary
            !filteredSettings ? (
              isValidating ? (
                <Spinner />
              ) : (
                <div>No settings data for {account}</div>
              )
            ) : (
              <SettingsGrid>
                <AnimatePresence>
                  {filteredSettings.map((setting, i) => (
                    <SettingsTable
                      key={i}
                      setting={setting}
                      updateSettings={(p) => {
                        setShow(true);
                        setPayload(p);
                      }}
                    />
                  ))}
                </AnimatePresence>
              </SettingsGrid>
            )
          }
        </SettingsWrapper>
      </AccountSettingsWrapper>
    </>
  );
}
