/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { FilledTradesEndpointEnum } from "../../../enums/endpoints";
import { TRADES_API_URL } from "../../../utils/env";
import { IAevoTradeHistory } from "../../../interfaces/aevo";

export interface IFormattedMarkHistory {
  timestamp: string;
  price: string;
}

export const useFilledTrades = (trade_id?: string) => {
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IAevoTradeHistory[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData(undefined); // Reset data to undefined when instrument_name changes
    setIsLoading(true);

    const fetchFilledTrades = async () => {
      const params = {
        trade_id,
      };
      try {
        const response = await axios.get<any>(TRADES_API_URL(FilledTradesEndpointEnum.FILLED_TRADES), {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenId}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        });

        const trades = response.data.data.data;
        const formattedRows: IAevoTradeHistory[] = trades.map((item: any) => ({
          amount: item.TotalMatchedAmount,
          created_timestamp: item._CreatedTimestamp,
          price: String(item.AvgPrice),
          trade_id: item.TradeId,
          instrument_name: item.InstrumentName,
          side: item.Side,
        }));

        setData(formattedRows as IAevoTradeHistory[]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId && trade_id) {
      fetchFilledTrades();
    }
  }, [tokenId, trade_id]);

  return { data, isLoading };
};
