import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IAccount } from "../../../interfaces/aevo";
import { shortenAddress } from "../../../utils/strings";
import { AccountHeader, QueryText } from "../../Withdrawals/style";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { LeftHeaderStatsWrapper } from "./style";

interface IHeaderProps {
  isMobile?: boolean;
  records: Record<string, string>;
  accountData: IAccount | undefined;
  setAccount: (account: string | undefined) => void;
  onFlag: (account: string) => void;
}

function HeaderStats({
  isMobile,
  records,
  accountData,
  setAccount,
  onFlag,
}: IHeaderProps) {
  if (accountData?.account) {
    const record = records[accountData?.account];
    return (
      <AccountHeader isMobile={isMobile}>
        <LeftHeaderStatsWrapper>
          <div
            style={{
              color: record ? COLORS.blue.one : TEXT_COLORS.one,
            }}
          >
            <div style={{ display: "flex" }}>
              {record ?? shortenAddress(accountData.account)}
            </div>
            <div
              style={{
                textTransform: "uppercase",
                fontSize: 10,
                color: TEXT_COLORS.two,
              }}
            >
              {accountData?.username}
            </div>
          </div>
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            onClick={() => onFlag(accountData.account)}
          >
            Flag
          </Button>
        </LeftHeaderStatsWrapper>
        {isMobile ? (
          <button
            type="button"
            onClick={() => setAccount(undefined)}
            style={{ color: TEXT_COLORS.two }}
          >
            <Cross height={32} width={32} />
          </button>
        ) : undefined}
      </AccountHeader>
    );
  }

  return (
    <AccountHeader isMobile={isMobile}>
      <QueryText isMobile={isMobile}>
        {isMobile
          ? "Click on an account to view flow data"
          : "Select an account to query flow data"}
      </QueryText>
    </AccountHeader>
  );
}

export default HeaderStats;
