import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import currency from "currency.js";
import moment from "moment";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import {
  TableHeaderCell,
  MinifiedTableWrapper,
  Align,
  LeftTitleWrapper,
  RightTitleWrapper,
  TableLoader,
  TitleWrapper,
} from "../../Table/style";
import { ITableColumn } from "../../Table/TableColumn";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { IBinancePosition } from "../../../interfaces/binance";

interface IPositionTableProps {
  data: IBinancePosition[];
  selectedAsset: string | undefined;
  isLoading?: boolean;
}

export function BinancePositionTable({ data, selectedAsset, isLoading }: IPositionTableProps) {
  const memoizedPositions = useMemo(() => {
    if (!selectedAsset) return data || [];

    return (data || []).filter((pos) => pos.symbol.includes(selectedAsset));
  }, [data, selectedAsset]);

  const columns: ITableColumn<IBinancePosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "symbol",
      },
      {
        title: "Side",
        align: "left",
        accessor: "positionAmt",
        id: "side",
        Cell: ({ value }) => (
          <div
            style={{
              color: Number(value) > 0 ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {Number(value) > 0 ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        accessor: "positionAmt",
        id: "contracts",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
      {
        title: "Entry Price",
        align: "right",
        accessor: "entryPrice",
        valueExtractor: (value) => `$${parseFloat(value).toFixed(2)}`,
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "markPrice",
        valueExtractor: (value) => `$${parseFloat(value).toFixed(2)}`,
      },
      {
        title: "Liquidation Price",
        align: "right",
        accessor: "liquidationPrice",
        valueExtractor: (value) => `$${parseFloat(value).toFixed(2)}`,
      },
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "unRealizedProfit",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                parseFloat(value) > 0 ? COLORS.green.one : parseFloat(value) < 0 ? COLORS.red.one : TEXT_COLORS.one,
            }}
          >
            {currency(value).format()}
          </Align>
        ),
      },
      {
        title: "Isolated Margin",
        align: "right",
        accessor: "isolatedMargin",
        Cell: ({ value }) => (
          <Align
            align="right"
            style={{
              color: parseFloat(value) > 0 ? TEXT_COLORS.one : TEXT_COLORS.three,
            }}
          >
            {currency(Number(value)).format()}
          </Align>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedPositions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (memoizedPositions && memoizedPositions.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedPositions.map(
          (v: IBinancePosition) => [
            v.symbol,
            v.positionSide,
            parseFloat(String(v.positionAmt)).toFixed(2),
            parseFloat(String(v.entryPrice)).toFixed(2),
            parseFloat(String(v.markPrice)).toFixed(2),
            currency(Number(v.unRealizedProfit)).format(),
            currency(Number(v.isolatedMargin)).format(),
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedPositions]);

  return (
    <div>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>Binance Positions {memoizedPositions.length ? `(${memoizedPositions.length})` : "(None)"}</h3>
        </LeftTitleWrapper>
        <RightTitleWrapper>
          <DownloadCSVButton data={csvData} filename={`Binance Positions ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
        </RightTitleWrapper>
      </TitleWrapper>

      <MinifiedTableWrapper>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </MinifiedTableWrapper>
    </div>
  );
}
