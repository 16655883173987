import currency from "currency.js";
import { useCallback } from "react";
import { IDeribitPosition } from "../interfaces/deribit";
import { IAggregatedPosition } from "../interfaces/positions";
import { initialMarginUtilization, maintenanceMarginUtilization, utilizationColor } from "../utils/math";

export interface IGreeks {
  delta: number;
  gamma: number;
  vega: number;
  theta: number;
}

export const useComputations = () => {
  const computeMM = useCallback((equity?: string, maintenance_margin?: string, isUsd?: boolean) => {
    if (equity && maintenance_margin) {
      const mm = parseFloat(maintenance_margin);
      const mmPercent = maintenanceMarginUtilization(parseFloat(equity), mm);
      return (
        <div style={{ color: utilizationColor(Number(mmPercent)) }}>
          {isUsd ? currency(mm).format() : mm.toFixed(3)} ({mmPercent.toFixed(2)}%)
        </div>
      );
    }

    return isUsd ? (
      <div style={{ color: utilizationColor(0) }}>$0.00 (0.00%)</div>
    ) : (
      <div style={{ color: utilizationColor(0) }}>0 (0.00%)</div>
    );
  }, []);

  const computeIM = useCallback(
    (equity?: string, initial_margin?: string, maintenance_margin?: string, isUsd?: boolean) => {
      if (equity && initial_margin && maintenance_margin) {
        const im = parseFloat(initial_margin);
        const imPercent = initialMarginUtilization(parseFloat(equity), im, parseFloat(maintenance_margin));
        return (
          <div style={{ color: utilizationColor(Number(imPercent)) }}>
            {isUsd ? currency(im).format() : im.toFixed(3)} ({imPercent.toFixed(2)}%)
          </div>
        );
      }

      return <div style={{ color: utilizationColor(0) }}>{isUsd ? "$" : ""}0.00 (0.00%)</div>;
    },
    []
  );

  const computeDeribitCashFlow = useCallback(
    (deribitData: IDeribitPosition[], selectedAsset: string): IAggregatedPosition | undefined => {
      const cashflowPosition: IAggregatedPosition = {
        instrument_name: "",
        isPerpetual: false,
        asset: selectedAsset,
        delta: 0,
        gamma: 0,
        vega: 0,
        theta: 0,
        contracts: 0,
        pnl: 0,
        side: "buy",
        source: "",
        isPriority: true,
      };

      if ((deribitData || []).length > 0) {
        (deribitData as IDeribitPosition[]).forEach((position) => {
          const { average_price, size, kind } = position;
          if (kind === "option") {
            cashflowPosition.delta += -1 * Number(average_price) * Number(size);
          }
        });

        cashflowPosition.instrument_name = `${selectedAsset}-CASHFLOW`;
        return cashflowPosition;
      }

      return undefined;
    },
    []
  );

  const computeDeribitEquity = useCallback((equity: number, selectedAsset: string): IAggregatedPosition | undefined => {
    if (Number.isNaN(equity)) {
      return undefined;
    }
    return {
      instrument_name: `${selectedAsset}-EQUITY`,
      isPerpetual: false,
      asset: selectedAsset,
      delta: equity,
      gamma: 0,
      vega: 0,
      theta: 0,
      contracts: 0,
      pnl: 0,
      side: "buy",
      source: "",
      isPriority: true,
    };
  }, []);

  return {
    computeIM,
    computeMM,
    computeDeribitCashFlow,
    computeDeribitEquity,
  };
};
