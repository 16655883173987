export enum ChainIdEnum {
  NONE = -1,
  ETH_MAINNET = 1,
  SEPOLIA_TESTNET = 11155111,
  LOCAL_TESTNET = 31337,
  OPTIMISM = 10,
  OPTIMISM_TESTNET = 11155420, // Optimism sepolia
  ARBITRUM = 42161,
  ARBITRUM_TESTNET = 421614, // Arbitrum sepolia
  AEVO = 299,
  AEVO_TESTNET = 11155112,
}

export const chainIdToName = (chainId: ChainIdEnum) => {
  switch (chainId) {
    case ChainIdEnum.ETH_MAINNET:
    case ChainIdEnum.SEPOLIA_TESTNET:
    case ChainIdEnum.LOCAL_TESTNET:
      return "Ethereum";
    case ChainIdEnum.OPTIMISM:
    case ChainIdEnum.OPTIMISM_TESTNET:
      return "Optimism";
    case ChainIdEnum.ARBITRUM:
    case ChainIdEnum.ARBITRUM_TESTNET:
      return "Arbitrum";
    case ChainIdEnum.AEVO:
    case ChainIdEnum.AEVO_TESTNET:
      return "Aevo";
    default:
      return "No Chain";
  }
};

export const chainIdToExplorer = (chainId: ChainIdEnum) => {
  switch (chainId) {
    case ChainIdEnum.ETH_MAINNET:
      return "https://etherscan.io/";
    case ChainIdEnum.SEPOLIA_TESTNET:
      return "https://sepolia.etherscan.io/";
    case ChainIdEnum.OPTIMISM:
      return "https://optimistic.etherscan.io/";
    case ChainIdEnum.OPTIMISM_TESTNET:
      return "https://sepolia-optimism.etherscan.io/";
    case ChainIdEnum.ARBITRUM:
      return "https://arbiscan.io/";
    case ChainIdEnum.ARBITRUM_TESTNET:
      return "https://sepolia.arbiscan.io/";
    case ChainIdEnum.AEVO:
      return "https://explorer.aevo.xyz/";
    case ChainIdEnum.AEVO_TESTNET:
      return "https://explorer-testnet.aevo.xyz/";
    default:
      return "https://etherscan.io/";
  }
};

export const environmentToAevoExplorer = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "testnet") {
    return "https://explorer-testnet.aevo.xyz/";
  }
    return "https://explorer.aevo.xyz/";
};
