/* eslint-disable no-nested-ternary */
import { useCallback, useState } from "react";
import currency from "currency.js";
import { IVolume, useAccountVolume } from "../../hooks/api/admin/useAccountVolume";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import { Header, InputWrapper, Stat, Username, VolumeGrid, VolumeWrapper } from "./style";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FormInput } from "../Settings/style";

export function VolumeStat({
  volume,
  title,
  showUsername = false,
}: {
  volume: IVolume;
  title: string;
  showUsername?: boolean;
}) {
  return (
    <VolumeWrapper>
      {showUsername && volume.username ? <Username>{volume.username}</Username> : undefined}
      <VolumeGrid>
        <Stat>
          <Header>{title}</Header>
        </Stat>
        <Stat>
          <div>Options</div>
          <div
            style={{
              color: Number(volume.options_volume) === 0 ? TEXT_COLORS.three : TEXT_COLORS.one,
            }}
          >
            {volume.options_volume !== undefined ? currency(volume.options_volume).format() : "---"}
          </div>
        </Stat>

        <Stat>
          <div>Perpetuals</div>
          <div
            style={{
              color: Number(volume.perp_volume) === 0 ? TEXT_COLORS.three : TEXT_COLORS.one,
            }}
          >
            {volume.perp_volume !== undefined ? currency(volume.perp_volume).format() : "---"}
          </div>
        </Stat>

        <Stat>
          <div>Total Volume</div>
          <div
            style={{
              color:
                Number(volume.options_volume) + Number(volume.perp_volume) === 0 ? TEXT_COLORS.three : TEXT_COLORS.one,
            }}
          >
            {volume.options_volume !== undefined && volume.perp_volume !== undefined
              ? currency(Number(volume.options_volume) + Number(volume.perp_volume)).format()
              : "---"}
          </div>
        </Stat>

        <Stat>
          <div>PnL</div>
          <div
            style={{
              color:
                Number(volume.pnl) === 0
                  ? TEXT_COLORS.three
                  : Number(volume.pnl) > 0
                  ? COLORS.green.one
                  : COLORS.red.one,
            }}
          >
            {volume.pnl !== undefined ? currency(volume.pnl).format() : "---"}
          </div>
        </Stat>
      </VolumeGrid>
    </VolumeWrapper>
  );
}

export function Volume() {
  const [address, setAddress] = useState<string>();
  const [queryAddress, setQueryAddress] = useState<string>();
  const { mutate, isValidating, isLoading, data } = useAccountVolume(queryAddress!);

  const onSearch = useCallback(() => {
    setQueryAddress(address);
    mutate();
  }, [address, mutate]);

  return (
    <div>
      <InputWrapper>
        <FormInput
          style={{ height: COMPONENTS.selectHeight, width: 340 }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
        />
        <Button
          style={{ minHeight: COMPONENTS.selectHeight }}
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={onSearch}
          disabled={!address}
        >
          {!isLoading && isValidating ? <Spinner /> : "Query address"}
        </Button>
      </InputWrapper>
      {data ? (
        <>
          <VolumeStat volume={data.account_statistics.daily} title="Daily" showUsername />
          <VolumeStat volume={data.account_statistics.weekly} title="Weekly" />
          <VolumeStat volume={data.account_statistics.monthly} title="Monthly" />
          <VolumeStat volume={data.account_statistics.all_time} title="All Time" />
        </>
      ) : undefined}
    </div>
  );
}
