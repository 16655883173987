import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";

export const FilterContainer = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
  align-items: center;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = styled.div``;

export const Title = styled.div`
  ${FONT_CSS.header.six}
  color: ${TEXT_COLORS.three};
  margin-bottom: ${SPACING.two}px;
  padding: ${SPACING.three}px;
  padding-bottom: 0px;
`;

export const BidsAsksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: max-content 1fr;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DebugWrapper = styled.div`
  min-height: calc(100% - ${COMPONENTS.header.main}px);
  max-height: calc(100% - ${COMPONENTS.header.main}px);
  overflow: auto;
`;

export const Padding = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.three}px;
  gap: ${SPACING.three}px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  overflow: auto;
`;

export const Label = styled.span`
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.data.four};
  text-transform: uppercase;
  display: block;
  margin: ${SPACING.three}px 0 ${SPACING.one}px 0;
`;

export const MarginWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-gap: ${SPACING.two}px;

  > div {
    background: ${BACKGROUND_COLORS.four};
    display: flex;
    flex-direction: column;
    border: 1px solid ${BORDER_COLORS.two};
    border-radius: 6px;
    justify-content: space-between;
    padding: ${SPACING.two}px;
  }
`;

export const MarginStat = styled.div`
  ${FONT_CSS.data.three}
  div:first-child {
    color: ${TEXT_COLORS.two};
  }
`;
