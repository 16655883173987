import ReactDropdown from "react-dropdown";
import "./style.css";

interface IDashboardFilterDropdownProps {
  onClick: (selectedOption: QueryTypeEnum) => void;
}

export enum QueryTypeEnum {
  ADDRESS = "account",
  USERNAME = "username",
  AIRTABLE_TAG = "airtable_tag",
}

export const QUERY_TYPE_TITLES: Record<QueryTypeEnum, string> = {
  [QueryTypeEnum.ADDRESS]: "Address",
  [QueryTypeEnum.USERNAME]: "Username",
  [QueryTypeEnum.AIRTABLE_TAG]: "Airtable Tag",
};

export function DashboardFilterDropdown({ onClick }: IDashboardFilterDropdownProps) {
  return (
    <ReactDropdown
      className="dropdown"
      menuClassName="dropdown-menu"
      placeholderClassName="dropdown-placeholder"
      value={Object.values(QueryTypeEnum)[0]}
      options={[
        ...(Object.values(QueryTypeEnum) || []).map((query) => ({
          label: QUERY_TYPE_TITLES[query],
          value: query,
        })),
      ]}
      onChange={(selectedOption) => onClick((selectedOption as any)?.value)}
    />
  );
}
