import { useCallback, useState } from "react";
import { MarketDropdown } from "../Dropdown/MarketDropdown";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import Checkbox from "../shared/Checkbox";
import { CheckboxContainer } from "../shared/Checkbox/style";
import { AddressInputWrapper, FilterWrapper, TradesWrapper } from "./style";
import InstrumentChart from "./InstrumentChart";

export function MMTrades() {
  const [asset, setAsset] = useState<string>();
  const [account, setAccount] = useState<string>("");
  const [showAllTrades, setShowAllTrades] = useState(false);
  const [fetchParams, setFetchParams] = useState<{
    asset?: string;
    account?: string;
    showAllTrades: boolean;
  }>({ showAllTrades: false });
  const [fetchKey, setFetchKey] = useState<number>(0);

  const handleAccountChange = useCallback((e: any) => {
    e.preventDefault();
    setAccount(e.target.value);
  }, []);

  const handleFetch = useCallback(() => {
    setFetchParams({
      asset,
      account,
      showAllTrades,
    });
    setFetchKey((prevKey) => prevKey + 1);
  }, [asset, account, showAllTrades]);

  return (
    <TradesWrapper>
      <FilterWrapper>
        <MarketDropdown onClick={setAsset} overridePlaceholder="Select Asset" />
        <CheckboxContainer>
          <Checkbox
            checked={showAllTrades}
            onToggle={() => setShowAllTrades(!showAllTrades)}
            title="All Trades"
          />
        </CheckboxContainer>
        <AddressInputWrapper>
          <input
            name="addressInput"
            defaultValue=""
            placeholder="Enter Address"
            onChange={handleAccountChange}
          />
        </AddressInputWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          disabled={!asset}
          onClick={handleFetch}
          style={{ minHeight: 24, maxHeight: 24 }}
        >
          Fetch
        </Button>
      </FilterWrapper>
      {fetchParams.asset && (
        <InstrumentChart
          key={`${fetchKey}-${fetchParams.asset}-${fetchParams.account}-${fetchParams.showAllTrades}`}
          asset={fetchParams.asset}
          showAllTrades={fetchParams.showAllTrades}
          account={fetchParams.account}
        />
      )}
    </TradesWrapper>
  );
}
