import DatePicker from "react-datepicker";
import { useState } from "react";
import { SPACING } from "../../constants/design/spacing";
import "./style.css"; // Import your custom CSS file

interface IDatetimePickerProps {
  onDateChange: (startTimestamp: number, endTimestamp: number) => void;
  overridePlaceholder?: string;
}

function DatetimePicker({ onDateChange, overridePlaceholder }: IDatetimePickerProps) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const handleDateChange = (update: [Date | null, Date | null]) => {
    setDateRange(update);
    const [startDate, endDate] = update;
    if (startDate && endDate) {
      const startUnixTimestamp = Math.floor(startDate.getTime() / 1000);
      const endUnixTimestamp = Math.floor(endDate.getTime() / 1000);
      onDateChange(startUnixTimestamp, endUnixTimestamp);
    } else {
      onDateChange(0, 0); // or default values
    }
  };

  return (
    <div style={{ display: "flex", marginLeft: SPACING.two }}>
      <DatePicker
        selectsRange
        placeholderText={overridePlaceholder || "Select Date Range"}
        dateFormat={"dd MMM yyyy"}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        maxDate={new Date()}
        onChange={handleDateChange}
        isClearable
      />
    </div>
  );
}

export default DatetimePicker;
