import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { ITxServiceState } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export const useTxQueue = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const endpoint = `${ADMIN_API_URL("snapshot/current/service/transactions")}`;

  const swr = useSWR<ITxServiceState, AxiosError>(
    tokenId ? [endpoint, tokenId] : undefined,
    async () => {
      const txQueue = await axios.get<ITxServiceState>(
        endpoint,
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        }
      );

      return txQueue.data;
    }
  );

  const clearTxQueue = useCallback(
    async (txQueue: ITxServiceState) => {
      try {
        if (tokenId) {
          await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.RESET_TX_QUEUE),
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(txQueue));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "TX Queue Cleared",
            subheader: "TX queue is cleared successfully",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Clear TX Queue Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  const clearOnchainQueue = useCallback(
    async (txQueue: ITxServiceState) => {
      try {
        if (tokenId) {
          await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.RESET_ONCHAIN_QUEUE),
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(txQueue));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "TX Queue Cleared",
            subheader: "TX queue is cleared successfully",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Clear Events Queue Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  const skipTransaction = useCallback(
    async (txQueue: ITxServiceState, seq: string) => {
      try {
        if (tokenId) {
          await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.SKIP_TRANSACTION),
              {
                seq,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(txQueue));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Event skipped",
            subheader: `Event ${seq} is skipped successfully`,
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Skip event failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  return {
    ...swr,
    clearTxQueue,
    clearOnchainQueue,
    skipTransaction,
  };
};
