import styled from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const StatsWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  margin: ${SPACING.three}px;
  max-height: ${COMPONENTS.header.stats + SPACING.three * 2}px;
  gap: ${SPACING.five}px;
`;

export const StatWrapper = styled.div`
  &:first-of-type {
    padding-left: 0;
  }
  max-width: fit-content;
  flex: 1;
`;

export const Label = styled.label<{ color?: string; size?: string }>`
  color: ${(props) => props.color ?? TEXT_COLORS.three};
  font-size: ${(props) => props.size ?? FONT_SIZE.xs};
`;

export const Value = styled.p<{ color?: string; size?: string }>`
  color: ${(props) => props.color ?? TEXT_COLORS.one};
  font-size: ${(props) => props.size ?? FONT_SIZE.two};
  font-weight: ${FONT_WEIGHT.medium};
  margin: 0;
`;

export const LeftHeaderStatsWrapper = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
`;
