import styled from "styled-components";
import { FONT_CSS } from "../../constants/design/fontSize";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${SPACING.three}px;
  margin-bottom: ${SPACING.three}px;
`;

export const Username = styled.div`
  ${FONT_CSS.label.one};
  color: ${TEXT_COLORS.one};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${SPACING.five}px;
`;

export const Header = styled.div`
  ${FONT_CSS.label.two};
  color: ${COLORS.highlight.one};
  text-transform: uppercase;
  text-align: left;
  align-self: center;
`;

export const VolumeWrapper = styled.div`
  padding: ${SPACING.three}px;
  width: 100%;
  max-width: 800px;
  margin: auto;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${BORDER_COLORS.one};
  }
`;

export const VolumeGrid = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(4, 180px);
  width: auto;
`;

export const Stat = styled.div`
  text-align: right;
  align-content: center;

  > div:first-of-type:not(${Header}) {
    color: ${TEXT_COLORS.two};
    ${FONT_CSS.label.one};
    margin-bottom: ${SPACING.one}px;
  }
`;
