import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const Badge = styled.div`
    color: ${COLORS.blue.one} !important;
    border-radius: 12px;
    padding: ${SPACING.one}px;
    border: 1px solid ${COLORS.blue.two};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: transparent;
  ${FONT_STYLE.label.three};
  text-transform: uppercase;
  color: ${TEXT_COLORS.three};

  > *:not(:last-child) {
   margin-right :${SPACING.one}px;
}
`;
