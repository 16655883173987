import { PropsWithChildren } from "react";
import styled, { css, CSSProperties, keyframes } from "styled-components";
import { BACKGROUND_COLORS, TEXT_COLORS, COLORS, BORDER_COLORS } from "../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { Button } from "../shared/Buttons/styles";

export const TableHeaderWrapper = styled.div<{ minWidth?: string }>`
  line-height: 1;
  z-index: 1;
  font-size: ${FONT_SIZE.two};
  display: flex;
  justify-content: space-around;
  min-width: ${({ minWidth }) => minWidth};

  > div {
    background-color: ${BACKGROUND_COLORS.componentSolid};
    height: ${COMPONENTS.tableHeader}px;
    text-align: center;
    width: 100%;
    display: flex;
  }

  span {
    text-align: center;
    align-self: center;
    color: ${TEXT_COLORS.three};
    padding: ${SPACING.two}px;
  }
`;

export const TableContentWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => minWidth};
  span {
    font-size: ${FONT_SIZE.two};
  }
`;

export const StrikeColumn = styled.div<{
  width: number;
  isActivePut?: boolean;
  isActiveCall?: boolean;
}>`
  width: ${(props) => props.width}px;
  background-color: ${BACKGROUND_COLORS.componentSolid};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.two};
  transition: 0.2s;

  ${({ isActiveCall, isActivePut }) => {
    if (isActiveCall) {
      return `
        border: 1px solid ${COLORS.blue.one};
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid transparent;
      `;
    }

    if (isActivePut) {
      return `
        border: 1px solid ${COLORS.blue.one};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: 1px solid transparent;
      `;
    }

    return "border: 1px solid transparent";
  }};
`;

export const StrikeHeader = styled(StrikeColumn)`
  background-color: transparent;
`;

const RowBackground = styled.div<{
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
}>`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  pointer-events: none;

  // Background
  ${({ fillColor, fillPercent, fillTo }) => {
    if (fillColor && fillPercent) {
      return css`
        background: linear-gradient(
          to ${fillTo || "right"},
          ${fillColor} ${fillPercent || 100}%,
          transparent ${fillPercent || 100}%,
          transparent 100%
        );
      `;
    }
    return "";
  }}
`;

export const Row = styled.div<{
  width?: string;
  highlightOnHover?: boolean;
  isActive?: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width || "100%"};

  ${({ isActive }) => isActive && `border: 1px solid ${COLORS.blue.one} !important;`}

  ${({ highlightOnHover }) => {
    if (highlightOnHover) {
      return css`
        &:hover {
          background-color: ${BACKGROUND_COLORS.four};
        }
      `;
    }
    return "";
  }}

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const flashAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const RowFlashAnimation = styled.div<{
  fillColor?: string;
  needsAnimation?: boolean;
}>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ fillColor, needsAnimation }) => {
    if (fillColor) {
      if (needsAnimation) {
        return css`
          background: ${fillColor};
          &:before {
            content: " ";
            display: block;
            background: ${fillColor};
            height: 100%;
            width: 100%;
          }
          opacity: 0;
          animation: ${flashAnimation} 0.6s ease-in-out forwards;
        `;
      }
    }
    return "";
  }}
`;

/**
 * A wrapper around Row.
 */
interface IRowWithSizeIndicatorProps {
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
  width?: string;
  highlightOnHover?: boolean;
  needsAnimation?: boolean;
  style?: CSSProperties;
  onRowHover?: (hover: boolean) => void;
}

export function RowWithSizeIndicator({
  fillColor,
  fillPercent,
  fillTo,
  width,
  highlightOnHover,
  children,
  needsAnimation,
  onRowHover,
  ...others
}: PropsWithChildren<IRowWithSizeIndicatorProps>) {
  return (
    <Row
      width={width}
      highlightOnHover={highlightOnHover}
      onMouseEnter={() => onRowHover?.(true)}
      onMouseLeave={() => onRowHover?.(false)}
      {...others}
    >
      <RowBackground fillColor={fillColor} fillPercent={fillPercent} fillTo={fillTo} />
      {children}
      <RowFlashAnimation fillColor={fillColor} needsAnimation={needsAnimation} />
    </Row>
  );
}

export const TableHeaderCell = styled.th<{
  onClick?: () => void;
}>`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`;

// Used for react table library
export const TableWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  max-height: calc(100vh - ${COMPONENTS.header.main}px - ${COMPONENTS.header.stats}px);
  border-radius: 4px;
  border: 1px solid ${BORDER_COLORS.two};

  table {
    border-spacing: 0.1px;
    min-width: 100%;
    font-size: ${FONT_SIZE.two};
    color: ${TEXT_COLORS.two};

    tbody {
      tr > td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
      tr:hover {
        transition: 0.05s ease-in all;

        > td {
          border-top: 1px solid ${COLORS.highlight.two};
          border-bottom: 1px solid ${COLORS.highlight.two};
        }
      }

      tr:nth-of-type(even) {
        background-color: ${BACKGROUND_COLORS.four};
      }

      tr:nth-of-type(odd) {
        background-color: ${BACKGROUND_COLORS.nine};
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 2;
      color: ${TEXT_COLORS.three};

      tr {
        height: ${COMPONENTS.tableHeader}px;

        th > * {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.xs};
        }
      }
    }

    tr {
      height: ${COMPONENTS.tableRow}px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        td {
          transition: 0.2s ease-in-out all;
          background-color: ${BACKGROUND_COLORS.componentSolid} !important;

          *:not(a):not(button) {
            color: ${TEXT_COLORS.one};
          }
        }
      }
    }

    th {
      font-weight: normal;
      font-size: ${FONT_SIZE.two};
      background-color: ${BACKGROUND_COLORS.componentSolid};
    }

    td {
    }

    th,
    td {
      margin: 0;
      padding: 0 ${SPACING.three}px;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
`;

export const MinifiedTableWrapper = styled(TableWrapper)<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          border: none;
          border-top: 1px solid ${BORDER_COLORS.two};
        `}
`;

export const Align = styled.div<{
  align: "left" | "right" | "center";
  onClick?: () => void;
}>`
  font-size: ${FONT_SIZE.two};
  text-align: ${({ align }) => align};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

const PREFIX_BUTTON = 120;
const MOBILE_PREFIX_BUTTON = 100;

export const PrefixWrapper = styled.div`
  position: relative;

  .dropdown {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: ${PREFIX_BUTTON}px;
    padding: 4px;
  }

  button {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    padding: 4px;
    min-height: auto !important;
    border-radius: 4px;
    width: ${PREFIX_BUTTON}px;
  }
`;

export const GlobalFilterWrapper = styled.div`
  input {
    height: 16px;
    background: white;
    border: 1px solid ${BORDER_COLORS.one};
    color: ${TEXT_COLORS.one};
    background-color: ${BACKGROUND_COLORS.component};
    padding: ${SPACING.two}px;
    border-radius: 6px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.three};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const InputWrapper = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          width: 100%;
          grid-gap: ${SPACING.two}px;

          input {
            padding-left: ${MOBILE_PREFIX_BUTTON + SPACING.two}px !important;
            width: 100%;
          }

          button {
            width: ${MOBILE_PREFIX_BUTTON}px;
          }

          > * {
            &:first-child {
              display: flex;
              grid-column: 1 / span 2;
              grid-row: 1 / span 1;
            }

            &:nth-child(2) {
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
              width: 100%;
            }

            &:last-child {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
              width: 100%;
            }
          }
        `
      : css`
          display: inline-flex;
          align-items: center;

          input {
            width: ${PREFIX_BUTTON + SPACING.four + 200}px;
            padding-left: ${PREFIX_BUTTON + SPACING.four}px !important;
          }
        `}

  input {
    height: 16px;
    background: white;
    border: 1px solid ${BORDER_COLORS.one};
    color: ${TEXT_COLORS.one};
    background-color: ${BACKGROUND_COLORS.component};
    padding: ${SPACING.two}px;
    border-radius: 6px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.three};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  > ${Button} {
    min-height: 16px;
    height: 100%;
  }
`;

export const DashboardRow = styled.tr<{ hasRecord?: boolean }>`
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:nth-of-type(odd) {
    background-color: ${BACKGROUND_COLORS.seven};
  }

  &:nth-of-type(even) {
    background-color: ${BACKGROUND_COLORS.four};
  }

  ${({ hasRecord }) =>
    hasRecord
      ? css`
          background-color: ${COLORS.blue.six} !important;

          &:hover {
            > td:first-of-type {
              *:first-child {
                color: ${COLORS.blue.one} !important;
              }
            }
          }
        `
      : undefined};
`;

export const SearchButton = styled(Button)`
  width: 75px;
`;

export const CompleteLoader = keyframes`
  0% { width: 0%; opacity: 1 }
  90% { width: 100% }
  100% { opacity 0 }
`;

export const TableLoader = styled.div<{ isLoading?: boolean }>`
  background-color: ${({ isLoading }) => (isLoading ? COLORS.purple.one : "none")};
  height: 2px;
  transition: 1s all ease-in-out;
  animation: ${({ isLoading }) =>
    isLoading
      ? css`
          ${CompleteLoader} 2s cubic-bezier(0.22, 0.61, 0.36, 1) infinite forwards
        `
      : "none"};
`;

export const TitleWrapper = styled.div`
  display: flex;
  text-align: left;
  background-color: transparent;
  ${FONT_STYLE.label.three};
  text-transform: uppercase;
  color: ${TEXT_COLORS.three};
  line-height: 1.5;
`;

export const FilterWrapper = styled(TitleWrapper)`
  margin-bottom: ${SPACING.one}px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.two}px;
`;

export const LeftTitleWrapper = styled.div``;

export const RightTitleWrapper = styled.div`
  display: flex;

  > * {
    margin: auto 0 !important;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px !important;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > * {
    margin: auto 0;
  }

  svg {
    margin-left: ${SPACING.two}px;
    cursor: pointer;
    background: ${BACKGROUND_COLORS.component};
    padding: ${SPACING.one}px;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: 0.2s all ease-in-out;
    stroke: ${COLORS.blue.one};

    &:hover {
      border: 1px solid ${COLORS.blue.one};
    }
  }
`;

export const CopyButton = styled.button`
  padding-left: 0;
  display: inline-flex;

  > svg {
    margin: auto 0;

    &:hover {
      transition: 0.2s ease-in-out all;
      stroke: ${COLORS.blue.one};
    }
  }
`;

export const PageGrid = styled.div`
  display: grid;
  gap: ${SPACING.two}px;
  padding: ${SPACING.two}px;
`;
