import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import useSWR from "swr";
import { pollingInterval } from "../../../constants/pollingInterval";
import { AuthContext } from "../../../context/AuthContext";
import { DataContext } from "../../../context/DataContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IAccount } from "../../../interfaces/aevo";
import { ADMIN_API_URL } from "../../../utils/env";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export enum TradeTypeEnum {
  TRADE = "trade",
  LIQUIDATION = "liquidation",
  SETTLEMENT = "settlement",
  FUNDING = "funding",
}

export const useAccount = (overrideAccount?: string, tradeTypes?: TradeTypeEnum[]) => {
  const { address } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const { addToast } = useToast();
  const account = overrideAccount || address;

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT);
  const swr = useSWR<IAccount, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountDetails = await axios.get<IAccount>(endpoint, {
        ...queryConfig(),
        params: {
          account,
          start_time: 0,
          end_time: 0,
          limit: 50,
          trade_types: Object.values(tradeTypes ?? TradeTypeEnum).join(","),
        },
      });

      const sortedAccountDetails = accountDetails.data;
      // Sort by delta
      sortedAccountDetails.positions.sort((a, b) => Number(b.option?.delta || 0) - Number(a.option?.delta || 0));

      return sortedAccountDetails;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT],
    }
  );

  const getAccounts = useCallback(
    async (addresses: string[]) =>
      Promise.all(
        addresses.map(async (add) => {
          const accountDetails = await axios.get<IAccount>(endpoint, {
            ...queryConfig(),
            params: {
              account: add,
              start_time: 0,
              end_time: 0,
              limit: 50,
              trade_types: Object.values(tradeTypes ?? TradeTypeEnum).join(","),
            },
          });

          if (!accountDetails.data) {
            return undefined;
          }

          const sortedAccountDetails = accountDetails.data;
          // Sort by delta
          sortedAccountDetails?.positions?.sort((a, b) => Number(b.option?.delta || 0) - Number(a.option?.delta || 0));

          return sortedAccountDetails;
        })
      ),
    [endpoint, queryConfig, tradeTypes]
  );

  const updateAccount = useCallback(
    async (selectedAccount: IAccount) => {
      try {
        if (tokenId) {
          await await axios
            .post(
              ADMIN_API_URL(AdminEndpointEnum.ACCOUNT),
              {
                account: selectedAccount.account,
                account_type: selectedAccount.account_type,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokenId}`,
                },
              }
            )
            .then(() => swr.mutate(selectedAccount));
        }

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Type Updated",
            subheader: "Account type is successfully updated",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Type Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  const updateUserGroup = useCallback(
    async (group: string) => {
      try {
        return await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.SET_GROUP),
            {
              account,
              group,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Group Updated",
                subheader: "Account's group is successfully updated",
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Group Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const updateUsername = useCallback(
    async (username: string) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.SET_USERNAME),
            {
              account,
              username,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Username Updated",
                subheader: "Account's username is successfully updated",
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Username Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const creditAccount = useCallback(
    async (amount_float: string) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.CREDIT),
            {
              account,
              amount_float,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Credited",
                subheader: `Account has been credited $${amount_float}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Credit Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const refundAccount = useCallback(
    async (amount_float: string) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.TRANSFER),
            {
              account,
              amount_float,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Refunded",
                subheader: `Account has been refunded $${amount_float}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Refund Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const recoverAccount = useCallback(
    async (amount_float: string) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.RECOVER),
            {
              account,
              amount_float,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Negative Balance Recovered",
                subheader: `Account has been refunded $${amount_float}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Negative Balance Recovery Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const updatePortfolioMargin = useCallback(
    async (isEnabled: boolean) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.PORTFOLIO_MARGIN),
            {
              account,
              enabled: isEnabled,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: `Portfolio Margin is ${isEnabled ? "enabled" : "disabled"}`,
                subheader: "Account's margin type is successfully updated",
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Margin Type Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const updateReferralBonus = useCallback(
    async (referral_bonus: number) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_REFERRAL_BONUS),
            {
              account,
              referral_bonus,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Referral Bonus Updated",
                subheader: `Referral Bonus updated to $${referral_bonus}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Referral Bonus Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  const updateRefereeDiscount = useCallback(
    async (referee_discount: number) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_REFEREE_DISCOUNT),
            {
              account,
              referee_discount,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Referee Discount Updated",
                subheader: `Referee Discount updated to $${referee_discount}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Referee Discount Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [account, addToast, swr, tokenId]
  );

  return {
    ...swr,
    getAccounts,
    updateAccount,
    updateUsername,
    creditAccount,
    refundAccount,
    recoverAccount,
    updatePortfolioMargin,
    updateReferralBonus,
    updateRefereeDiscount,
    updateUserGroup,
  };
};
