import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";

export interface IAccountFlow {
  total: string;
  inflows: {
    total: string;
    tx_types: {
      [key: string]: string;
    };
    labels: {
      [key: string]: string;
    };
  };
  outflows: {
    total: string;
    tx_types: {
      [key: string]: string;
    };
    labels: {
      [key: string]: string;
    };
  };
}
export const useAccountFlow = (account: string) => {
  const { tokenId } = useContext(AuthContext);

  const endpoint = `${ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_FLOWS)}`;

  const swr = useSWR<IAccountFlow, AxiosError>(
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const accountFlow = await axios.get<IAccountFlow>(endpoint, {
        headers: { Authorization: `Bearer ${tokenId}` },
        params: {
          account,
        },
      });

      return accountFlow.data;
    }
  );

  return swr;
};
