/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { MMTradesEndpointEnum } from "../../../enums/endpoints";
import { MMTRADES_API_URL } from "../../../utils/env";
import { IMMTrade, IFormattedMMTrade } from "./useMMTrades";

interface IMMTradesResponse {
  message: string;
  success: boolean;
  data: IMMTrade[];
}

export const usePrelaunchTrades = (
  start_time: string,
  end_time?: string,
  instrument_name?: string,
  exchange?: "AEVO" | "BINANCE" | "BYBIT" | "Vertex" | "Aevo",
) => {
  const { tokenId } = useContext(AuthContext);
  const [data, setData] = useState<IFormattedMMTrade[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset data to undefined when instrument_name changes
    setData(undefined);
    setIsLoading(true);
    // dont query if querying a specific aevo account
    const fetchTrades = async () => {
      const params: { [key: string]: any } = { start_time };
      if (instrument_name) params.instrument_name = instrument_name;
      if (end_time) params.end_time = end_time;

      try {
        const response = await axios.get<IMMTradesResponse>(
            MMTRADES_API_URL(MMTradesEndpointEnum.PRELAUNCH_TRADES),
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${tokenId}`,
                "Access-Control-Allow-Origin": "*",
              },
              params,
            }
          );

        const positions = response.data.data;
        const formattedRows = positions.map((item: any) => ({
          date_time: item._DateTime ?? "",
          exchange: item.Exchange ?? "AEVO",
          instrument_name: item.InstrumentName,
          side: item.Side,
          price: (item.Price - ((item.Fees * (item.Side === "buy" ? 1 : -1)) / item.Amount)).toFixed(6),
          amount: item.Amount,
          is_taker: item.IsTaker,
          trade_id: item.TradeId,
          order_id: item.OrderId,
          fees: item.Fees,
          timestamp: (Number(item._Timestamp) / 1e6).toFixed(0),
        }));
        setData(formattedRows);
      } catch (error) {
        // Handle error (optional)
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenId) {
      fetchTrades();
    }
  }, [tokenId, start_time, end_time, instrument_name, exchange]);

  return { data, isLoading };
};
