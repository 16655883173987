import ETHLogo from "../assets/svg/eth.svg";
import BTCLogo from "../assets/svg/btc.svg";
import USDCLogo from "../assets/svg/usdc.svg";
import USDTLogo from "../assets/svg/usdt.svg";
import AEUSDLogo from "../assets/svg/aeusd.svg";
import { useBybitAssets } from "../hooks/api/mm/useBybitAssets";

// Custom hook to fetch and return Bybit assets
export const useMMBybitAssets = () => {
    const { data, isLoading } = useBybitAssets();
    return { data, isLoading };
};

// Function to check if an asset is a Binance asset
export const IS_VALID_ASSET = (asset: string, assets: any[]) => assets && assets.some((item) => item.InstrumentName === asset);
export const getCollateralLogo = (collateral: string) => {
    switch (collateral.toUpperCase()) {
        case "ETH":
        case "WETH":
            return ETHLogo;
        case "BTC":
        case "WBTC":
            return BTCLogo;
        case "USDC":
            return USDCLogo;
        case "USDT":
            return USDTLogo;
        case "AEUSD":
            return AEUSDLogo;

        default: return undefined;
    }
};
