/* eslint-disable react-hooks/exhaustive-deps */
import currency from "currency.js";
import { useCallback, useContext, useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import moment from "moment";
import Collapse from "antd/es/collapse/Collapse";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IAevoPosition } from "../../../interfaces/aevo";
import { IDeribitPosition } from "../../../interfaces/deribit";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, TableHeaderCell, TableLoader } from "../../Table/style";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { IBinancePosition } from "../../../interfaces/binance";
import { DataContext } from "../../../context/DataContext";
import { IAggregatedPosition } from "../../../interfaces/positions";
import {
  INTERNAL_OPTIONS_ADDRESS,
  INTERNAL_PERPS_LONG_ADDRESS,
  INTERNAL_PERPS_SHORT_ADDRESS,
} from "../../../constants/admin";
import { standardizeAevoPositions } from "../../../utils/positions/aevo";
import { standardizeDeribitPositions } from "../../../utils/positions/deribit";
import { standardizeBinancePositions } from "../../../utils/positions/binance";
import { aggregatePositions } from "../../../utils/positions";
import { sortByContracts } from "../../../utils/math";
import { useMarkets } from "../../../hooks/api/aevo/useMarkets";
import { retrieveAssetByInstrumentName } from "../../../utils/strings";
import { Spinner } from "../../shared/Spinner";
import CollapseHeaderCount from "../../TableCollapse";

interface IAggregatedPositionTableProps {
  selectedAsset?: string;
  isLoading?: boolean;

  // Used in Positions.tsx
  aevoPositions?: IAevoPosition[];
  deribitPositions?: IDeribitPosition[];
  binancePositions?: IBinancePosition[];

  // Used in Books.tsx
  aggregatedAevoPositions?: IAggregatedPosition[];
  aggregatedDeribitPositions?: IAggregatedPosition[];
  aggregatedBinancePositions?: IAggregatedPosition[];
  aggregatedCustomPositions?: IAggregatedPosition[];
}

export function AggregatedPositionTable({
  aevoPositions = [],
  deribitPositions = [],
  binancePositions = [],
  aggregatedAevoPositions,
  aggregatedDeribitPositions,
  aggregatedBinancePositions,
  aggregatedCustomPositions,
  selectedAsset,
  isLoading,
}: IAggregatedPositionTableProps) {
  const { address: account } = useContext(DataContext);
  const { data: marketsData, isLoading: isMarketLoading, isValidating: isMarketValidating } = useMarkets();
  const aevo =
    aevoPositions.length > 0 ? standardizeAevoPositions(aevoPositions, selectedAsset) : aggregatedAevoPositions || [];
  const deribit =
    deribitPositions.length > 0 ? standardizeDeribitPositions(deribitPositions) : aggregatedDeribitPositions || [];
  const binance =
    binancePositions.length > 0 ? standardizeBinancePositions(binancePositions) : aggregatedBinancePositions || [];
  const custom = aggregatedCustomPositions || [];

  const memoizedPositions = useMemo(() => {
    let positions = [...aevo, ...deribit, ...binance, ...custom];
    if (account === INTERNAL_OPTIONS_ADDRESS) positions = [...aevo, ...deribit];
    if (account === INTERNAL_PERPS_SHORT_ADDRESS || account === INTERNAL_PERPS_LONG_ADDRESS) {
      positions = [...aevo, ...binance];
    }

    const aggregatedPositions = aggregatePositions(positions);

    if (selectedAsset) {
      return sortByContracts(aggregatedPositions.filter((pos) => pos.asset === selectedAsset));
    }

    return sortByContracts(aggregatedPositions);
  }, [account, aevo, binance, deribit, selectedAsset, sortByContracts]);

  const getNotional = useCallback(
    (pos: IAggregatedPosition, returnValue: boolean = false) => {
      const asset = retrieveAssetByInstrumentName(pos.instrument_name!);
      const market = marketsData?.find((m) => m.underlying_asset === asset);
      if (market) {
        if (returnValue) {
          return Number(market.mark_price) * Number(pos.contracts);
        }

        return currency(Number(market.mark_price) * pos.contracts).format();
      }

      if (!isMarketLoading && isMarketValidating) {
        if (returnValue) {
          return 0;
        }

        return <Spinner />;
      }

      if (returnValue) {
        return 0;
      }

      return "-";
    },
    [marketsData, isMarketLoading, isMarketValidating]
  );

  const greekColumns: ITableColumn<IAggregatedPosition>[] = useMemo(
    () => [
      {
        title: "Delta",
        align: "right",
        accessor: "delta",
        Cell: ({ value }) => (
          <Align align={"right"}>
            {value ? (
              <div>{currency(value).format({ symbol: "", precision: 4 })}</div>
            ) : (
              <span style={{ color: TEXT_COLORS.three }}>-</span>
            )}
          </Align>
        ),
      },
      {
        title: "Gamma",
        align: "right",
        accessor: "gamma",
        Cell: ({ value }) => (
          <Align align={"right"}>
            {value ? (
              <div>{currency(value).format({ symbol: "", precision: 4 })}</div>
            ) : (
              <span style={{ color: TEXT_COLORS.three }}>-</span>
            )}
          </Align>
        ),
      },
      {
        title: "Gamma (USD)",
        align: "right",
        id: "gamma-notional",
        // @ts-ignore
        accessor: "gamma",
        Cell: ({ value, row }: any) => {
          const market = marketsData?.find((m) => m.underlying_asset === row.original.asset);
          return (
            <Align align={"right"}>
              {value && market?.mark_price
                ? currency(Number(value) * Number(market?.mark_price) ** 2 * 0.01).format()
                : "-"}
            </Align>
          );
        },
      },
      {
        title: "Vega",
        align: "right",
        accessor: "vega",
        Cell: ({ value }) => (
          <Align align={"right"}>
            {value ? (
              <div>{currency(value).format({ symbol: "", precision: 4 })}</div>
            ) : (
              <span style={{ color: TEXT_COLORS.three }}>-</span>
            )}
          </Align>
        ),
      },
      {
        title: "Theta",
        align: "right",
        accessor: "theta",
        Cell: ({ value }) => (
          <Align align={"right"}>
            {value ? (
              <div>{currency(value).format({ symbol: "", precision: 4 })}</div>
            ) : (
              <span style={{ color: TEXT_COLORS.three }}>-</span>
            )}
          </Align>
        ),
      },
    ],
    [marketsData]
  );

  const columns: ITableColumn<IAggregatedPosition>[] = useMemo(
    () => [
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
        Cell: ({ value, row }) => (
          <div style={{ position: "relative" }}>
            {row.original.isPriority ? (
              <div
                style={{
                  position: "absolute",
                  borderLeft: `2px solid ${COLORS.purple.one}`,
                  top: -16,
                  bottom: -16,
                  left: -16,
                  width: 2,
                }}
              />
            ) : null}
            <div>{value}</div>
          </div>
        ),
      },
      {
        title: "Net Side",
        align: "left",
        accessor: "contracts",
        id: "net_side",
        Cell: ({ value }) => (
          <div
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                value === 0 ? TEXT_COLORS.three : value > 0 ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {value === 0 ? <span style={{ color: TEXT_COLORS.three }}>-</span> : value > 0 ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        id: "contracts",
        accessor: "contracts",
        Cell: ({ value, row }) => (
          <Align align={"right"}>
            {row.original.isPriority ? (
              <span style={{ color: TEXT_COLORS.three }}>-</span>
            ) : (
              currency(value).format({ symbol: "" })
            )}
          </Align>
        ),
      },
      {
        title: "Notional",
        align: "right",
        id: "notional",
        accessor: "contracts",
        Cell: ({ row }) => <Align align={"right"}>{getNotional(row.original)}</Align>,
        sortType: (a: any, b: any) =>
          Number(getNotional(a.original, true)) > Number(getNotional(b.original, true)) ? 1 : -1,
      },
      ...(account !== INTERNAL_PERPS_SHORT_ADDRESS ? greekColumns : []),
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "pnl",
        Cell: ({ value, row }) => (
          <Align
            align="right"
            style={
              row.original.isPriority
                ? { color: TEXT_COLORS.three }
                : {
                    color:
                      // eslint-disable-next-line no-nested-ternary
                      Number(value || 0) > 0
                        ? COLORS.green.one
                        : Number(value || 0) < 0
                        ? COLORS.red.one
                        : TEXT_COLORS.one,
                  }
            }
          >
            {currency(Number(value)).format()}
          </Align>
        ),
      },
    ],
    [account, greekColumns, getNotional]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedPositions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (memoizedPositions && memoizedPositions.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedPositions.map(
          (v: IAggregatedPosition) => [
            v.instrument_name,
            v.side,
            parseFloat(String(v.contracts)).toFixed(2),
            v.delta ? `${parseFloat(String(v.delta)).toFixed(4)}` : "-",
            v.gamma ? `${parseFloat(String(v.gamma)).toFixed(4)}` : "-",
            v.vega ? `${parseFloat(String(v.vega)).toFixed(4)}` : "-",
            v.theta ? `${parseFloat(String(v.theta)).toFixed(4)}` : "-",
            currency(Number(v.pnl)).format(),
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedPositions]);

  const positionsWithContracts = useMemo(
    () => memoizedPositions.filter((pos) => pos.contracts !== 0),
    [memoizedPositions]
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <CollapseHeaderCount count={positionsWithContracts.length} showBadge title="Aggregated Positions" />,
          extra: (
            <DownloadCSVButton
              data={csvData}
              filename={`Aggregated Positions ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`}
            />
          ),
          children: (
            <MinifiedTableWrapper>
              <TableLoader isLoading={isLoading} />
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{ width: column.width }}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...rowProps}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MinifiedTableWrapper>
          ),
        },
      ]}
    />
  );
}
