/* eslint-disable no-param-reassign */
import { useCallback, useContext } from "react";
import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";
import { pollingInterval } from "../../../constants/pollingInterval";
import { DataContext } from "../../../context/DataContext";
import { IAssetConfigs } from "../../../interfaces/settings";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useAccountSettings = () => {
  const { address: account } = useContext(DataContext);
  const { queryConfig, tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_SETTINGS);
  const swr = useSWR<IAssetConfigs[], AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId && account ? [endpoint, tokenId, account] : undefined,
    async () => {
      const settings = await axios.get<IAssetConfigs[]>(endpoint, {
        ...queryConfig(),
        params: {
          account,
        },
      });

      settings.data
        .sort((a, b) => a.instrument_type.localeCompare(b.instrument_type))
        .sort((a, b) => a.asset.localeCompare(b.asset));

      return settings.data;
    },
    {
      refreshInterval: pollingInterval[AdminEndpointEnum.ACCOUNT_SETTINGS],
    }
  );

  const updateSettings = useCallback(
    async (payload: Partial<IAssetConfigs>) => {
      try {
        return await await axios
          .post(ADMIN_API_URL(AdminEndpointEnum.ACCOUNT_SETTINGS), payload, {
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          })
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Account Settings Updated",
                subheader: "Account's settings are successfully updated",
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );

            swr.mutate();
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Settings Update Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, swr, tokenId]
  );

  return {
    ...swr,
    updateSettings,
  };
};
