import moment from "moment";

export const getTimeAgo = (pastDate: moment.MomentInput) => {
  const compareToDate = moment(pastDate);
  const now = moment();
  const diff = now.diff(compareToDate);

  // Date must be in the past, else is "Just now"
  if (diff >= 0) {
    const duration = moment.duration(diff);
    const oneMonthAgo = now.clone().subtract(1, "month");
    if (compareToDate <= oneMonthAgo) {
      const value = Math.round(duration.asMonths());
      return `${value}M`;
    }

    const oneWeekAgo = now.clone().subtract(1, "week");
    if (compareToDate <= oneWeekAgo) {
      const value = Math.round(duration.asWeeks());
      return `${value}W`;
    }

    const oneDayAgo = now.clone().subtract(1, "day");
    if (compareToDate <= oneDayAgo) {
      const value = Math.round(duration.asDays());
      return `${value}D`;
    }

    const oneHourAgo = now.clone().subtract(1, "hour");
    if (compareToDate <= oneHourAgo) {
      const value = Math.round(duration.asHours());
      return `${value}H`;
    }

    const oneMinuteAgo = now.clone().subtract(1, "minute");
    if (compareToDate <= oneMinuteAgo) {
      const value = Math.round(duration.asMinutes());
      return `${value}m`;
    }
  }

  return "Just now";
};

export const nanosToSeconds = (nanos: string | number) => Number(String(nanos).slice(0, -9));

export const secondsToNanos = (seconds: string | number) => Number(seconds) * 1e9;
