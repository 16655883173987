import axios, { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { dataHelper } from "../../../components/JSONViewer/helpers";

export const useJSON = (url: string, parameters: string) => {
  const { queryConfig, tokenId } = useContext(AuthContext);

  const swr = useSWRImmutable<any, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ? [url, parameters, tokenId] : undefined,
    async () => {
      const response = await (
        await axios.get<any>(`${ADMIN_API_URL(url)}${parameters}`, {
          ...queryConfig(),
        })
      ).data;

      return dataHelper(response, url);
    }
  );

  return {
    ...swr,
  };
};
