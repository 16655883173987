import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { PricerEndpointEnum } from "../../../enums/endpoints";
import { PRICER_API_URL } from "../../../utils/env";

export const usePricer = () => {
  const { tokenId } = useContext(AuthContext);

  const swr = useSWR<number, AxiosError>(
    // If tokenId & account are not available, the request will not fetch
    tokenId ?
      [PRICER_API_URL(PricerEndpointEnum.GET_PRICER_TIMESTAMP), tokenId] :
      undefined,
    async () => {
      try {
        const pricer = await axios.get<any>(
          PRICER_API_URL(PricerEndpointEnum.GET_PRICER_TIMESTAMP),
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${tokenId}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        return pricer.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    {
      refreshInterval: 5000,
    }
  );

  const updatePricer = async () => {
    try {
      await axios.get(
        PRICER_API_URL(PricerEndpointEnum.UPDATE_PRICER),
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenId}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).finally(() => swr.mutate());
    } catch (error) {
      console.error(error);
    }
  };

  return {
    ...swr,
    updatePricer,
  };
};
