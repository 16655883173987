import { useEffect, useRef, useState } from "react";
import { PortalWrapper } from "./style";

export enum ModalKeyEnum {
  CONNECT_WALLET = "connectWallet",
  OFAC = "ofac",
  INVALID_REF = "invalidRef",
}

// Higher value = highest priority
// (Only shows modal with highest value)
const MODAL_PRIORITY: Record<Partial<ModalKeyEnum>, number> = {
  [ModalKeyEnum.OFAC]: 3,
  [ModalKeyEnum.INVALID_REF]: 2,
  [ModalKeyEnum.CONNECT_WALLET]: 1,
};

export function ModalPortal() {
  const portalRef = useRef<HTMLDivElement>(null);
  const [showOverlay, setOverlay] = useState<boolean>(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      // All child nodes are assumed to be a modal because
      // there can ONLY be modals mounted on this component
      const modals = Array.from(
        portalRef.current?.childNodes || []
      ) as HTMLDivElement[];

      setOverlay(Boolean(modals.length > 0));

      modals.sort((m1, m2) => {
        const priority1 = MODAL_PRIORITY[m1.id as ModalKeyEnum];
        const priority2 = MODAL_PRIORITY[m2.id as ModalKeyEnum];
        return priority2 - priority1;
      });

      modals.forEach((modal, index) => {
        // Only show first modal
        if (index === 0) {
          modal.style.setProperty("opacity", "1");
          modal.style.setProperty("pointer-events", "auto");
        } else {
          modal.style.setProperty("opacity", "0");
          modal.style.setProperty("pointer-events", "none");
        }
      });
    });

    if (portalRef.current) {
      observer.observe(portalRef.current, { childList: true });
    }

    // Unsubscribe from observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <PortalWrapper
      showOverlay={showOverlay}
      id="modal-portal"
      ref={portalRef}
    />
  );
}
