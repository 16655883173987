import Select from "antd/es/select";
import { useMarkets } from "../../hooks/api/aevo/useMarkets";
import "./style.css";

interface IMarketDropdownProps {
  onClick: (selectedOption: string) => void;
  overridePlaceholder?: string;
}

export function MarketDropdown({ onClick, overridePlaceholder }: IMarketDropdownProps) {
  const { data: marketsData } = useMarkets();

  return (
    <Select
      // mode="multiple"
      allowClear
      showSearch
      placeholder={overridePlaceholder ?? "All Assets"}
      onChange={(value) => onClick(value)}
      style={{
        width: "200px",
      }}
      options={[
        {
          label: overridePlaceholder ?? "All Assets",
          value: "",
        },
        // default to having ETH, BTC on top
        {
          label: "ETH",
          value: "ETH",
        },
        {
          label: "BTC",
          value: "BTC",
        },
        ...(marketsData || [])
          .filter((asset) => asset.underlying_asset !== "ETH" && asset.underlying_asset !== "BTC")
          .map((asset) => ({
            label: asset.underlying_asset,
            value: asset.underlying_asset,
          })),
      ]}
    />
  );
}
