import { useCampaignStats } from "../../hooks/api/admin/useCampaignStats";
import { CampaignsTable } from "./CampaignsTable";
import { CampaignPageWrapper } from "./style";

export function Campaigns() {
  const { data: campaignStats, isLoading } = useCampaignStats();
  return (
    <CampaignPageWrapper>
      <CampaignsTable
        campaigns={campaignStats?.campaign_stat || []}
        isLoading={isLoading}
      />
    </CampaignPageWrapper>
  );
}
